/***
**Module Name: About Us
 **File Name :  AboutUS.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains About us details.
 ***/
import React, { useState, useEffect, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../api/tmdbApi";
import InsideHeader from "../components/InnerHeader/Header";
import Footer from "../components/footer/Footer";
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery';
import axios from "axios";

import * as Config from "../constants/Config";
import { useHistory } from "react-router";
import { contentContext } from "../context/contentContext";
import EnquiryForm from "./EnquiryForm"
import SessionPopup from "./SessionPopup"
import Select from 'react-select';
import moment from "moment";
import CurrencyInput from 'react-currency-input-field';

import InactivePopup from "./inactivePopup";
const Cart = () => {
    const history = useHistory();
    // const [menus, setMenus] = useState([]);
    const [show, setShow] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isLoadCart, setIsLoadCart] = useState(false);
    const [config, setConfig] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [enquire, setEnquire] = useState(false);
    // const [enqFormValues, SetEnqFormValues] = useState([]);
    const [isEnquireSuccess, setIsEnquireSuccess] = useState(false);
    const [isLoad1, setIsLoad1] = useState(false);
    const [progressBarActive, SetProgressBarActive] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [perPageConst, setPerPageConst] = useState(10);
    const [perpage, setPerpage] = useState(10);
    const [assetTotal, setAssetTotal] = useState(0);
    const [deleteType, setDeleteType] = useState("single");
    const [activeCheckId, setActiveCheckId] = useState("");
    const [rightsData, setRightsData] = useState({});

    // const [progress, setProgress] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [totalPagesArray, setTotalPagesArray] = useState(0);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeLoad, setActiveLoad] = useState("");


    const [Regions, setRegions] = useState([]);
    const [rightsAvailableError, setRightsAvailableError] = useState("");
    const [dateError, setDateError] = useState("");
    const [videoformatData, setVideoformatData] = useState({});


    const [lookup, setLookUp] = useState({});

    const [languagesData, setlanguagesData] = useState({});
    const [countryData, setCountryData] = useState({});
    const [multiSelectFields, setMultiSelectFields] = useState({});
    const [enquiryFormValues, setEnquiryFormValues] = useState({});



    let { lambda, appname } = window.app;
    const { clientData, inactiveFlag, setInactiveFlag, enqFormValues, setShowCategoryData, SetEnqFormValues, wishListData, progress, setProgress, setWishListData, wishlistCount, prevRoute, route, setwishlistCount, menus, setContentSearchFlag, contentSearchFlag, setRoute } = useContext(contentContext)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        // appMenus();
        getWishlistFun();
        clientActivity();

    }, [perpage, pageNumber]);

    useEffect(() => {
        if (window.site) {
            setConfig(window.site);

        }

    }, [window.site]);

    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }
    //  console.log('enquiryFormValues',enquiryFormValues)
    const AddNotify = () => toast.success('Your enquiry has been sent', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)
    const ShowError = () => toast.error('Please select to add additional comments', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)

    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        // const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": 'CART',
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }

    const getWishlistFun = async () => {
        try {
            setIsLoadCart(true)
            // const clientid = localStorage.getItem("clientid")
            // console.log('clientidclientid',clientid)
            // let pageNumber = params.pageNumber || 1;
            // let assetcount = params.assetcount || 18;

            let payload = { perpage, pageNumber }
            const response = await tmdbApi.cartGetWishlist(payload);
            console.log('responseeee--------------->', response)
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response.result == "Client inactived") {
                    setInactiveFlag(true)

                } else {
                    let data = response.result && response.result && response.result.result ? response.result.result : []
                    //  setInitialCategoriesData(data)
                    if (data.length > 0) {
                        setWishListData(data)
                        // console.log('getWishlist result', response.result)

                        let assetTotal = response.result.count || 0
                        setwishlistCount(assetTotal)
                        localStorage.setItem('wishlistCount', assetTotal)
                        setAssetTotal(assetTotal)

                        if (assetTotal > 0) {
                            let totalPages = assetTotal / perpage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                            SetEnqFormValues([])
                        }
                    }
                    else {
                        setwishlistCount(0)
                        localStorage.setItem('wishlistCount', 0)
                        setWishListData([])
                        setIsLoadCart(false)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setIsLoadCart(false)
            setActiveCheckId("")
            console.log("error");
        }

    }
    console.log('contentSearchFlag', contentSearchFlag)

    const handleChangeDelete = (item, check) => {
        setIsDelete(true)
        setDeleteType("single")
        setSelectedId(item.contentid)

        // console.log('forrrmmvaluess', enqFormValues)
    }
    const handleDeleteAll = (deleteid) => {
        setIsDelete(true)

        setDeleteType("multiple")

        //    const payload = enqFormValues.map((eachItem)=>{
        //     return eachItem.contentid
        //    })
        //    console.log("payload deleteeee",payload)
        //    deleteWishlistFun(payload)
    }

    const deleteWishlistFun = async (payload, isSubmit) => {
        try {
            console.log('payload delete wishlist', payload)
            setIsLoadCart(true)

            const clientid = localStorage.getItem("clientid")

            axios({
                method: 'DELETE',
                url: lambda + "/wishlist?appname=" + appname + "&clientid=" + clientid,
                data: { "contentid": payload },
            })
                .then(function (response) {
                    console.log('response result------------------->', response)
                    if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                        setIsLoadCart(false)
                        SetProgressBarActive(false)
                    } else if (response.data.result == "Client inactived") {
                        setInactiveFlag(true)
                        setIsLoadCart(false)
                        SetProgressBarActive(false)
                    } else {
                        //  getWishlistFun()

                        if (isSubmit != "submit") {
                            RemoveNotify()
                        }
                        setIsLoadCart(false)
                        SetProgressBarActive(false)
                        SetEnqFormValues([])
                        if (pageNumber !== 1) {
                            setPageNumber(1)
                            setPerpage(10)
                        }
                        else {
                            getWishlistFun()
                        }
                    }
                });



        } catch {
            setIsLoadCart(false)
            console.log("error");
        }

    }
    const RemoveNotify = () => toast.success('Removed from Cart', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)

    function onConfirm() {
        if (deleteType == "single") {
            setActiveCheckId(selectedId)
            let payload = [selectedId]

            deleteWishlistFun(payload)
        } else if (deleteType == "multiple") {
            setActiveCheckId("multiple")
            const payload = enqFormValues.map((eachItem) => {
                return eachItem.contentid
            })
            console.log("payload deleteeee", payload)
            deleteWishlistFun(payload)
        }

        setIsDelete(false)
        SetProgressBarActive(true)
    };
    function onCancel() {
        setIsDelete(false)
    }

    const handleCloseEnq = () => {
        console.log(enquire)
        setEnquiryFormValues([])
        setMultiSelectFields([])
        setDateError("")
        setShow(false)
    };

    const handleChangeCheckItem = (e, item) => {

        //console.log('checked', e.target.checked)
        const clientid = localStorage.getItem("clientid")

        if (e.target.checked == true) {

            let arr = [...enqFormValues]
            let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

            let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
                item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
                    : "defaultImg"

            let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
                item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
                    : defaultImg

            const newObj = {
                clientid: clientid,
                appname: item.appname,
                title: item.title,
                contentid: item.contentid,
                comments: "",
                // thumbnail: item.thumbnail,
                [imgKey]: imgValue,

                category: item.category,
                companyid: item.companyid
            }
            arr.push(newObj)
            SetEnqFormValues(arr)
        }
        else {
            let newArr = [...enqFormValues]
            let resultArray = newArr.filter((eachitem) => eachitem.contentid !== item.contentid)
            SetEnqFormValues(resultArray)
        }
        //console.log('forrrmmvaluess', enqFormValues)
    }
    const handleSelectAll = (e) => {

        //console.log('checked', e.target.checked)
        const clientid = localStorage.getItem("clientid")

        if (e.target.checked == true) {

            let arr = []
            let tempWishListData = wishListData

            tempWishListData && tempWishListData.length > 0 && tempWishListData.map((item) => {
                let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

                let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
                    item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
                        : "defaultImg"

                let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
                    item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
                        : defaultImg

                const newObj = {
                    clientid: clientid,
                    appname: item.appname,
                    title: item.title,
                    contentid: item.contentid,
                    // thumbnail: item.thumbnail,
                    [imgKey]: imgValue,

                    category: item.category,
                    companyid: item.companyid
                }
                arr.push(newObj)
                SetEnqFormValues(arr)


            })


        }
        else {

            SetEnqFormValues([])
        }
        //console.log('forrrmmvaluess', enqFormValues)
    }
    // console.log('forrrmmvaluess--------------->', enqFormValues)





    const handleChangePages = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'perPage') {
            setPageNumber(1)
            setPerpage(value)
        }
        if (name == 'pageNo') {
            setPageNumber(value)
        }
    }
    const previousClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)

        setPageNumber(k - 1)
    }
    const nextClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)
        setPageNumber(k + 1)
    }

    const handleItem = (checkItem) => {
        if (checkItem === false) {
            // ShowError()
        }
    }
    console.log('enqFormValues', enqFormValues)

    const handleAddComments = (e, item) => {

        let value = e.target.value
        // console.log('item.contenid', item.contentid)
        // console.log('value', e.target.value)
        // console.log('form values incomments ', enqFormValues)
        let updatedArr = enqFormValues.map((eachItemm) => {
            if (eachItemm.contentid === item.contentid) {
                return { ...eachItemm, "comments": value }
            }
            return eachItemm
        })

        // console.log('updatedArr ', updatedArr)
        SetEnqFormValues(updatedArr)
        //         updatedArr
        //     });
        //    SetEnqFormValues({...updatedArr,["comments"]:value});
        //    return {...each ,"comments": value}
    }

    const handleBack = () => {
        history.push('/content')
    }

    const handleExplore = () => {
        setShowCategoryData(true)
        history.push('/content')
    }


    const submitForm = (e) => {
        // console.log('submit clicked')
        const isValid = formvalidation();
        if (isValid) {
            if (activeLoad == "") {
                submitEnquiryFun();
            }
        }
        // }

    }
    const handleRightsavailableError = () => {
        setRightsAvailableError("")
    }
    console.log('enquiryFormValues', enquiryFormValues)
    function formvalidation() {
        let formIsValid = true;

        // if (!enquiryFormValues['rightsAvailable']) {
        //     setRightsAvailableError("Please enter rights available");
        //   formIsValid = false;
        // }
        if (enquiryFormValues?.rightsAvailableToDate < enquiryFormValues?.rightsAvailableFromDate) {
            setDateError('To date cannot be earlier than From date');
            // error["date"] ='To date cannot be earlier than From date';
            formIsValid = false;
        } else {
            setDateError("")
            formIsValid = true;
        }
        return formIsValid;
    }
    const submitEnquiryFun = async () => {
        try {
            setActiveLoad("enquire")
            const clientid = localStorage.getItem("clientid")

            const response = await tmdbApi.submitEnquiry(

                {
                    "clientid": clientid ?? "",
                    "companyid": clientData?.companyid ?? "",
                    "rightsAvailable": multiSelectFields?.rightsAvaliable ?? "",
                    "comments": enquiryFormValues?.additionalCom ?? "",
                    "request": enquiryFormValues?.request ?? "",
                    "requiredtodate": enquiryFormValues?.rightsAvailableToDate ?? "",
                    "requiredfromdate": enquiryFormValues?.rightsAvailableFromDate ?? "",
                    "videoformatsrequired": multiSelectFields?.videoformat ?? "",
                    "dubbinglanguagesrequired": multiSelectFields?.dubbinglanguages ?? "",
                    "exclusive": enquiryFormValues.exclusivity ?? "",
                    "totalbudget": enquiryFormValues?.totalbudget ?? "",
                    "queries": enquiryFormValues?.queries ?? "",
                    "regionsrequired": multiSelectFields?.regions ?? "",
                    "content": enqFormValues,

                }
            );

            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response.result == "Client inactived") {
                    setInactiveFlag(true)

                } else {

                    // const set1 = new Set(enqFormValues.map((eachone)=>eachone.contentid))
                    // console.log('filterd set1',set1)

                    // const filterdarray = wishListData.filter((el)=> !set1.has(el.contentid))
                    // console.log('filterd arrrraa',filterdarray)
                    const payload = enqFormValues.map((eachItem) => {
                        return eachItem.contentid
                    })
                    //console.log("payload deleteeee", payload)
                    deleteWishlistFun(payload, "submit")
                    setEnquiryFormValues([])
                    SetEnqFormValues([])
                    setMultiSelectFields([])
                    // setWishListData(...wishListData,filterdarray)
                    //console.log(response);
                    AddNotify()
                    handleCloseEnq()
                }
                setActiveLoad("")
            }
        } catch {
            setActiveLoad("")
            console.log("error");
        }
    };
    useEffect(() => {
        GetLookUp();
        setContentSearchFlag(false)
        setRoute("notcontent")

    }, []);
    // console.log('multiselect fielsd',multiSelectFields)
    const GetLookUp = async (e) => {
        try {
            let arrayType = ["videoformat", "language", "territories", "rights"];

            const response = await tmdbApi.getLookUp({
                type: arrayType,
                "sortBy": "name"

            });
            console.log(response);
            if (response.statusCode == 200) {
                let arrayType = ["videoformat", "language", "territories", "rights"];
                let lookupsData = response.result || []
                arrayType?.map((eachItem) => {
                    let arr1 = []
                    lookupsData?.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                console.log('loookup', lookup)
                const territoriesObj = prepareObject(lookup?.territories)
                setCountryData(territoriesObj)
                const languagesObj = prepareObject(lookup?.language)
                setlanguagesData(languagesObj)
                const videoformatObj = prepareObject(lookup?.videoformat)
                setVideoformatData(videoformatObj)
                const rightsObj = prepareObject(lookup?.rights)
                setRightsData(rightsObj)

            }

        } catch {
            console.log("error");
        }
    }
    const prepareObject = (item) => {
        const resultArr = item?.map((eachItem) => { return { value: eachItem, label: eachItem }; })
        return resultArr
    }

    const handleMultiSelect = (selected, key) => {
        let selectedArray = returnArray(selected)
        setMultiSelectFields({ ...multiSelectFields, [key]: selectedArray })
    };
    const returnArray = (arr) => {
        let newArr = []
        arr.map((eachItem) => {
            newArr.push(eachItem?.value)
        })

        return newArr
    }
    const handleEnquireForm = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.name == "rightsavailabletodate" || e.target.name == "rightsavailablefromdate") {
            let date = moment(new Date(value)).toISOString();
            setEnquiryFormValues({ ...enquiryFormValues, [name]: date });
        }
        setDateError("")
        setEnquiryFormValues({ ...enquiryFormValues, [name]: value })
    }

    //   console.log('enqFormValues',multiSelectFields)
    //   console.log('rights',rightsData)

    return (
        <>
            {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

            {/* {showSessionPopupup && <SessionPopup />} */}
            <Modal className="access-denied" show={isDelete}>

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">delete_outline</span>
                        <h3>Remove</h3>
                        <p>This action cannot be undone.</p>
                        <p>Are you sure you want to remove{`${deleteType == 'multiple' ? ' selected items' : ''}`}?</p>

                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>Yes, Remove</button>

                    </div>
                </div>

            </Modal>


            <Modal show={show}>
                {/* <EnquiryForm img={img} handleCloseEnq={handleCloseEnq} AddNotify={AddNotify} /> */}


                <div className="modal-header">
                    <img src={img + Config.imgmiddle + "logo.png"} alt="" className="modal-logo" />
                    <h4 className="modal-title pos-center">Enquiry Form</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseEnq}></button>
                </div>
                <div className="modal-body enquiry-form">
                    <div className="container">
                        <div className="row">
                            <h6>{enqFormValues && enqFormValues?.length > 0 ? enqFormValues?.length : 0} {enqFormValues?.length == 1 ? "Title" : "Titles"} Selected</h6>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20"  value={additionalCom} onChange={(e) => setAdditionalCom(e.target.value)}  className="form-control">Tell us more about your requirement</textarea>
                                     <label htmlFor="floatingInput">Additional Comments</label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Comments</label>
                                    <textarea id="email" name="additionalCom" placeholder="Enter Comments" type="text" className="form-control" spellcheck="false" value={enquiryFormValues.additionalCom}

                                        onChange={(e) => handleEnquireForm(e)} ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20" className="form-control" value={rightsAvailable} onChange={(e) => setRightsAvailable(e.target.value)} onFocus={(e)=> handleRightsavailableError(e)}>Select Rights</textarea>
                                     <label htmlFor="floatingInput">Rights Available<span>*</span></label>
                                     <span className="errormsg" style={{  fontWeight: 'bold',color: 'red' }}>{rightsAvailableError}</span> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required Rights</label>
                                    {/* <textarea id="email" name="rightsAvailable"  type="text" className="form-control" spellcheck="false"  value={enquiryFormValues.rightsAvailable} onChange={(e) => handleEnquireForm(e)} onFocus={(e)=> handleRightsavailableError(e)}></textarea> */}

                                    <Select isMulti={true}
                                        placeholder='Required Rights'
                                        onChange={(e) => handleMultiSelect(e, 'rightsAvaliable')}
                                        options={rightsData}
                                        value={multiSelectFields && multiSelectFields?.rightsAvaliable && multiSelectFields?.rightsAvaliable?.length > 0 ? multiSelectFields?.rightsAvaliable?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                        isClearable={true}
                                    />

                                    <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{rightsAvailableError}</span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input id="email" name="releasedate" placeholder="Release from Date" type="date" value={rightsAvailableFromDate} onChange={(e) => setRightsAvailableFromDate(e.target.value)}className="form-control" />
                                     <label htmlFor="floatingInput">Rights Available from Date
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required rights available from </label>
                                    <input id="email" name="rightsAvailableFromDate" placeholder="dd-mm-yyyy" type="date" className="form-control" value={enquiryFormValues.rightsAvailableFromDate} onChange={(e) => handleEnquireForm(e)} />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input id="email" name="releasedate" placeholder="Release Date" type="date" value={rightsAvailableToDate} onChange={(e) => setRightsAvailableToDate(e.target.value)}className="form-control" />
                                     <label htmlFor="floatingInput">Rights Available to Date
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required rights available to </label>
                                    <input id="email" name="rightsAvailableToDate" placeholder="dd-mm-yyyy" type="date"

                                        className="form-control" value={enquiryFormValues?.rightsAvailableToDate} onChange={(e) => handleEnquireForm(e)} />
                                    {dateError !== "" && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{dateError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"  value={videoFormat} onChange={(e) => setVideoFormat(e.target.value)} id="floatingInput" placeholder="Eg: MP4, MOV, AVI etc" />
                                     <label htmlFor="floatingInput">Video Format
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Exclusivity</label>

                                    <select className="form-control custom-select form-control-sm form-select form-select-sm" name="exclusivity" placeholder="Exclusivity" value={enquiryFormValues.exclusivity}
                                        onChange={(e) => handleEnquireForm(e)} >
                                        <option value="">Select</option>
                                        <option value="Exclusive">Exclusive</option>
                                        <option value="Non-Exclusive">Non-Exclusive</option>

                                    </select>
                                    {/* <input type="text" className="form-control for-clear" id="example-email-input" name="exclusivity" placeholder="Exclusivity" value={enquiryFormValues.exclusivity} onChange={(e) => handleEnquireForm(e)} /> */}

                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"  value={Regions} onChange={(e) => setRegions(e.target.value)} id="floatingInput" placeholder="Eg: MP4, MOV, AVI etc" />
                                     <label htmlFor="floatingInput">Regions
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Regions</label>

                                    <Select isMulti={true}
                                        placeholder='Regions'
                                        onChange={(e) => handleMultiSelect(e, 'regions')}
                                        options={countryData}
                                        value={multiSelectFields && multiSelectFields.regions && multiSelectFields?.regions?.length > 0 ? multiSelectFields?.regions?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                    />
                                </div>
                            </div>


                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="Your Name" className="form-control" id="floatingInput" placeholder="Enter Languages"  value={dubbingLanguages} onChange={(e) => setDubbingLanguages(e.target.value)} />
                                     <label htmlFor="floatingInput">Dubbing Languages
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Dubbing Languages</label>
                                    <Select isMulti={true}
                                        placeholder='Dubbing Languages'
                                        onChange={(e) => handleMultiSelect(e, 'dubbinglanguages')}
                                        options={languagesData}
                                        value={multiSelectFields && multiSelectFields.dubbinglanguages && multiSelectFields?.dubbinglanguages?.length > 0 ? multiSelectFields?.dubbinglanguages?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"   value={exclusivity} onChange={(e) => setExclusivity(e.target.value)}  id="floatingInput" placeholder="name@example.com" />
                                     <label htmlFor="floatingInput">Exclusivity
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Video Quality</label>
                                    <Select isMulti={true}
                                        placeholder='Video quality'
                                        onChange={(e) => handleMultiSelect(e, 'videoformat')}
                                        options={videoformatData}
                                        value={multiSelectFields && multiSelectFields?.videoformat && multiSelectFields?.videoformat?.length > 0 ? multiSelectFields?.videoformat?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                        isClearable={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20"  value={request} onChange={(e) => setRequest(e.target.value)}  className="form-control ">Enter Request</textarea>
                                     <label htmlFor="floatingInput">Special Request
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Special Request</label>
                                    <textarea id="email" name="request" placeholder="Enter Special Request" type="text" className="form-control" spellcheck="false" value={enquiryFormValues.request} onChange={(e) => handleEnquireForm(e)} ></textarea>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20" className="form-control " value={queries} onChange={(e) => setQueries(e.target.value)}   >Query</textarea>
                                     <label htmlFor="floatingInput">Enter Query
                                     <span>*</span>
                                     </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Enter Query</label>
                                    <textarea id="email" name="queries" placeholder="Enter Query" type="text" className="form-control" spellcheck="false" value={enquiryFormValues.queries} onChange={(e) => handleEnquireForm(e)}   ></textarea>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">

                                    <label htmlFor="example-text-input" className="col-form-label">Total Budget (USD)</label>

                                    <CurrencyInput
                                        id="total-budget"
                                        name="totalbudget"
                                        className="form-control"
                                        label="Total Budget (USD)"
                                        placeholder="Enter Total Budget"
                                        defaultValue={enquiryFormValues?.totalbudget} // Optional initial value
                                        decimalsLimit={2}  // Limit to 2 decimal places
                                        prefix="$"          // Add a dollar sign prefix
                                        onValueChange={(value, name, values) => {
                                            // Handle value changes here
                                            console.log(value); // This will be the parsed number without formatting
                                            console.log(values.formatted); // This will be the formatted value as a string
                                            setEnquiryFormValues({ ...enquiryFormValues, [name]: values?.value })
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="fill_btn yellow-gradient float-end" onClick={(e) => submitForm(e)}>{activeLoad === "enquire" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Submit</button>
                    <div className="clearfix"></div>
                </div>

            </Modal>
            <InsideHeader activeClass={"0"} />

            <div className="inner-body cart">
                <div className="body-header">
                    <div className="md-container">
                        <div className="page-header">
                            <div className="title-wrapper">
                                <div className="block-title">
                                    <h2>Cart</h2>
                                </div>
                                <div className="title-action">
                                    {enqFormValues && enqFormValues?.length > 1 ? (
                                        <button className="plain-btn color-btn" onClick={handleDeleteAll} disabled={enqFormValues && enqFormValues.length <= 0}>
                                            {activeCheckId === "multiple" ? (
                                                <img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                (<span className="material-icons-outlined plus">delete</span>)}
                                            Remove Selected
                                        </button>
                                    ) : null}

                                </div>

                            </div>
                            <button className="plain-btn" onClick={handleBack}><span className="material-icons-outlined">chevron_left
                            </span>Back</button>

                        </div>
                    </div>
                </div>
                {/* {   progressBarActive ?  ( 
                <>
                <div className="progress-wrapper">
                     <div className="progress">
                         <div className="progress-bar progress-bar-striped progress-bar-animated green-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}></div>
                     </div>
                 </div></>
           ) : null} */}
                {/* <div className="progress-bar">
                     <div className="progress" style={{ width: `${progress}%` }}></div>
                 </div> */}
                {wishListData && wishListData.length > 0 ? (<>


                    <div className="body-content mt-3">
                        <div className="md-container">
                            <div className="cart-block mb-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                {/* <input type="checkbox" /> */}
                                                <th><input type="checkbox" checked={enqFormValues.length === wishListData.length} onChange={(e) => handleSelectAll(e)} /></th>

                                            </th>
                                            <th>Title</th>
                                            <th>Additional Comments</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {wishListData && wishListData?.length > 0 ? wishListData?.map((item, i) => {
                                            //  clientid:clientid,
                                            //  appname: item.appname,
                                            //  title: item.title,
                                            //  contentid: item.contentid,
                                            //  thumbnail: item.thumbnail,
                                            //  // portraitimage: item.portraitimage,
                                            //  // landscapeimage: item.landscapeimage,
                                            //  category: item.category,
                                            //  companyid: item.companyid
                                            let picName = item?.mediaType === 'video' ? 'videoclip-defaulr' : item?.mediaType === 'audio' ? 'musicfile-default' : item?.mediaType === 'pdf' ? 'pdf-default' : item?.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
                                            //  let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/${picName}.jpg`;
                                            // let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/${picName}.jpg`;
                                            let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;
                                            //   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                                            //     console.log('portraitimage',item.portraitimage)
                                            //     console.log('landscapeimage',item.landscapeimage)
                                            //     console.log('thumbnail',item.thumbnail)
                                            //     console.log('defaultImg',defaultImg)
                                            //     let imgUrl = (item.portraitimage != "" || item.portraitimage != undefined) ? img + item.portraitimage :
                                            //    ( item.landscapeimage != "" || item.landscapeimage != undefined) ? img + item.landscapeimage :
                                            //    ( item.thumbnail != "" || item.thumbnail != undefined)? img + item.thumbnail : defaultImg
                                            //   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                                            // let imgUrl = item.thumbnail != "" ? img + item.thumbnail : defaultImg
                                            let imgUrl =
                                                item?.portraitimage !== "" && item?.portraitimage !== undefined
                                                    ? (item?.portraitimage.startsWith("http")
                                                        ? item?.portraitimage
                                                        : img + item?.portraitimage)
                                                    : item?.landscapeimage !== "" && item?.landscapeimage !== undefined
                                                        ? (item?.landscapeimage.startsWith("http")
                                                            ? item?.landscapeimage
                                                            : img + item?.landscapeimage)
                                                        : defaultImg;


                                            // let imgUrl = item.thumbnail != "" ? img + item.thumbnail : defaultImg
                                            let filterList = enqFormValues.filter((eachitem) => eachitem?.contentid === item?.contentid)
                                            let isCheck = filterList && filterList[0] && filterList[0]?.title ? true : false
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input type="checkbox" id={item?.contentid} checked={isCheck} onChange={(e) => handleChangeCheckItem(e, item)} /> </td>
                                                    <td>
                                                        <div className="cart-title-block">
                                                            <div className="cart-thumb me-3">
                                                                <img src={imgUrl + "?auto=compress,format"} />
                                                            </div>
                                                            <div className="cart-title">
                                                                <h6>{item?.title}</h6>
                                                                {/* <p>MOVIES</p> */}
                                                            </div>
                                                        </div>

                                                    </td>

                                                    <td onClick={(e) => handleItem(isCheck)}>
                                                        <textarea
                                                            value={enqFormValues?.[i]?.comments ? enqFormValues?.comments : ''}
                                                            className="comments_area" placeholder="Add additional comments" disabled={!isCheck}
                                                            onChange={(e) => handleAddComments(e, item)} type="text"
                                                        ></textarea>
                                                    </td>
                                                    <td>
                                                        <div className="table-actions-group">
                                                            <button className="table-actions-btn delete-btn" onClick={(e) => handleChangeDelete(item, false)} >
                                                                {activeCheckId === item?.contentid ? (
                                                                    <img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                                    (<span className="material-symbols-outlined"> delete </span>)}
                                                                {/* <span className="material-symbols-outlined"> delete </span> */}
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : null}



                                    </tbody>
                                </table>
                                <div className="cart-footer d-flex justify-content-between">
                                    <button className={`fill_btn yellow-gradient ${enqFormValues && enqFormValues?.length <= 0 ? 'disable' : ''}`} data-bs-toggle="modal" data-bs-target="#recommendModal" disabled={enqFormValues && enqFormValues?.length <= 0} onClick={handleShow}>Enquire</button>

                                    {/* {enqFormValues && enqFormValues.length > 1 ? (
                                         <button className={`fill_btn yellow-gradient ${enqFormValues && enqFormValues.length <= 0 ? 'disable' : ''}`} data-bs-toggle="modal" data-bs-target="#recommendModal" disabled={enqFormValues && enqFormValues.length <= 0} onClick={handleDeleteAll}>Delete All</button>
                                     ) : null} */}
                                </div>

                            </div>


                            {/* {assetTotal > perPageConst ?
                           <div className="row pagenation pgn ">
                                      <div className="pagenation_prv"><button disabled={pageNumber == 1} onClick={e => previousClick(e)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                      <div className="pagenation_page"> <label>Page :</label>
                                          <span className="material-icons-outlined">arrow_drop_down</span>
                                          <select className="form-control custom-select u-w60" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                              {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                  return (
                                                      <option key={i} value={item}>{item}</option>
                                                  )
                                              })}
                                          </select>
                                          <label><strong> of {totalPages}</strong></label>
                                      </div>
                                      <div className="pagenation_count">
                                          <label>{(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                      </div>
                                      <div className="pagenation_perpage">
                                          <label>Per Page:</label>
                                          <span className="material-icons-outlined">arrow_drop_down</span>
                                          <select className="form-control custom-select u-w60" name="perPage" value={perpage} onChange={e => handleChangePages(e)} >
                                             
                                              <option value={10}>10</option>
                                              <option value={20}>20</option>
                                              <option value={30}>30</option>
                                              <option value={40}>40</option>
                                              <option value={50}>50</option>
                                          </select>
                                      </div>
                                      <div className="pagenation_next"><button disabled={pageNumber == totalPages} onClick={e => nextClick(e)} className="jr-btn btn-white btn btn-default btn-block">Next</button></div>
                                  </div> 
 :null} */}
                            {assetTotal > perPageConst ?
                                <div className="row pagenation pgn">
                                    <div className="pagenation-left d-flex align-items-center">
                                        <label className="me-5">Showing {(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                        <div className="pagenation_page"> <label>Page :</label>
                                            {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                            <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                {totalPagesArray && totalPagesArray?.map(function (item, i) {
                                                    return (
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })}
                                            </select>
                                            <label><strong> of {totalPages}</strong></label>
                                        </div>
                                    </div>
                                    <div className="pagenation-right d-flex align-items-center">
                                        <div className="pagenation_perpage">
                                            <label>Per Page:</label>
                                            {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                            <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={perpage} onChange={e => handleChangePages(e)} >

                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={75}>75</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                        <div className="pagenation-buttons d-flex align-items-center">
                                            <button className="jr-btn btn-white btn btn-default btn-block" disabled={pageNumber == 1} onClick={e => previousClick(e)}><span className="material-icons-outlined">chevron_left</span></button>
                                            <button className="jr-btn btn-white btn btn-default btn-block" disabled={pageNumber == totalPages} onClick={e => nextClick(e)} ><span className="material-icons-outlined">navigate_next</span></button>
                                        </div>
                                    </div>
                                </div>

                                : null}
                        </div>
                    </div>

                </>) : isLoadCart ? (<>
                    <div className="orasi-preloader">
                        <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                    </div>
                </>) : (<>
                    <div className="comingsoon-wrapper">
                        <div className="comingsoon-block">
                            <img src="https://orasi-dev.imgix.net/orasi/common/images/shopping-list.png" />
                            <h6>Your Cart is empty!</h6>
                            <p>Looks like you have not added anything to your cart.<br />Go ahead and explore top categories.</p>
                            <button className="fill_btn yellow-gradient mt-2" onClick={handleExplore}>
                                Explore Now
                            </button>
                        </div>
                    </div>
                </>)}

            </div>
            <Footer menus={menus} />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

        </>
    );
};

export default Cart;