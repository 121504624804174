/***
**Module Name: privacy
 **File Name :  Privacy.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains Privacy page details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router-dom";

//  import "../../src/assets/css/style.css"
import InsideHeader from "../components/InnerHeader/Header";
import * as Config from "./../constants/Config";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ReactHtmlParser from 'react-html-parser';
import { contentContext } from "../context/contentContext";
import axios from "axios";
import moment from "moment";
let { lambda, appname } = window.app;


const Privacy = () => {
  const history = useHistory();
  // const [menus, setMenus] = useState([]);
  const [config, setConfig] = useState({});

  const { menus ,setContentSearchFlag,contentSearchFlag, setRoute} = useContext(contentContext)

  // useEffect(() => {
  //   appMenus();
  // }, []);
  useEffect(() => {
    if (localStorage.getItem("token")){
      clientActivity()
    }
    if (window.site) {
      setConfig(window.site);

    }
    setContentSearchFlag(false)
    setRoute("notcontent")
  }, [window.site]);

  if (config.common && config.common.resourcesUrl) {
    var img = config.common.resourcesUrl;
  }

  // const appMenus = async () => {
  //   try {
  //     const response = await tmdbApi.appMenusCode(
  //     );
  //     setMenus(response.result)
  //     console.log(response);
  //   } catch {
  //     console.log("error");
  //   }
  // };
  function handleClose() {
    if (localStorage.getItem("token")) {
      history.go(-1)
    }
    else {
      history.push("/");
    }
  }
  const clientActivity = () => {
    let path = window.location.pathname.split("/");
    // const pageName = path[path.length - 1];
    var presentTime = moment();
    let payload;
    
        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": 'PRIVACY',
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };

    
    let urlLink;
    if(localStorage.getItem("previousid")){
        urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
    }else {
        urlLink= lambda + '/activity?appname=' + appname
    }

    axios({
        method: 'POST',
        url: urlLink,
        data: payload
    })
        .then(function (response) {
            if(response.data.statusCode === 200){
                localStorage.setItem("previousid",response.data.result)
            }
        });
}


  console.log("menus", menus);

  return (
    <>
      {localStorage.getItem("token") ? <InsideHeader /> : <Header menus={menus} />}

      <div className="terms-page">
        <div className="terms-header">
          {menus && menus.length > 0 && menus.map(function (item, i) {
            return (
              item.menuid === 100007 ? <div className="privacy-cls-wrapper" key={i}>{ReactHtmlParser(item.content)}</div> : ""
            )
          })}
        </div>
      </div>


      <Footer menus={menus} />


    </>
  );
};

export default Privacy;
