/***
**Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
import Footer from "../components/footer/Footer";
import Header from "../components/InnerHeader/Header";
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Config from "../constants/Config"
import SessionPopup from "./SessionPopup"
import { contentContext } from "../context/contentContext"
import axios from "axios";
import moment from "moment";
import InactivePopup from "./inactivePopup";
let { lambda, appname } = window.app;



const Profile = () => {
    const history = useHistory();

    //  const [menus, setMenus] = useState([]);
    const [name, setName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [companyName, setCompany] = useState('');
    const [companyContenttype, setCompanyContenttype] = useState('');
    const [companyMail, setCompanyMail] = useState('');
    const [companyMobile, setCompanyMobile] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [config, setConfig] = useState({});
    const [clientData, setClientData] = useState([]);
    const [valid, setValid] = useState(false);
    const [phoneerror, setPhoneError] = useState('');
    const [nameerror, setNameError] = useState('');
    const [success, setSuccess] = useState(false);
    const [activeLoad, setActiveLoad] = useState(""); 

    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: '',
        oldPassword: ''
    })
    const [passwordError, setPasswordErr] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [number, setNumber] = useState(false);
    const [upper, setUpper] = useState(false);
    const [limit, setLimit] = useState(false);
    const [lower, setLower] = useState(false);
    const [special, setSpecial] = useState(false);
    const [error, setError] = useState('');
    const [compMailErr, setCompMailErr] = useState('');
    const [compPhnErr, setCompPhnErr] = useState('');
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [countries, setCountries] = useState('');
    const [idc, setIdc] = useState('');
    const [IdcError, setIdcError] = useState('');

    const { menus,setContentSearchFlag,contentSearchFlag ,inactiveFlag, setInactiveFlag,setClientData1, setRoute} = useContext(contentContext);

    const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setPhonenumber(onlyDigits);

    };

    const handleCompanyMobile = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setCompanyMobile(onlyDigits);

    };



    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        //  appMenus();
        GetClientDataFun();
        clientActivity();
        GetCountries();
        setContentSearchFlag(false)
        setRoute("notcontent")
        }, []);

    useEffect(() => {
        if (window.site) {
            setConfig(window.site);

        }

    }, [window.site]);
    const GetCountries = async () => {
        try {
          console.log(tmdbApi);
          const response = await tmdbApi.getLookUp({
            "type":["country"],
            "sortBy":"alpha3"
          });
       
          setCountries(response.result);

        } catch {
          console.log("error");
        }
      };


    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }

    const handleBack = () => {
        history.push('/content')
    }

// console.log('local name', localStorage.getItem(""))
    const GetClientDataFun = async () => {
        try {

            const response = await tmdbApi.getClientData({});
            console.log("response", response.result);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response.result === "Client inactived"){
                    setInactiveFlag(true)
                   } else {
                    let formData = response.result.result[0]
                    
                    setName(formData.name)
                    setIdc(formData.idc)
                    setEmailId(formData.personalemail)
                    setCompany(formData.companyname)
                    setCompanyMail(formData.companyemail)
                    setCompanyMobile(formData.companymobile)
                    setCompanyType(formData.companytype)
                    setCompanyContenttype(formData.contenttype)
                    setPhonenumber(formData.personalphone)
                    setClientData(response.result.result[0]);
                    setClientData1(response.result.result[0])
                }
                // console.log('response client Data--->',response.result[0])
            }
        } catch {
            console.log("error");
        }
    };

    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        // const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": 'PROFILE',
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))
    
            };
    
        
        let urlLink;
        if(localStorage.getItem("previousid")){
            urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        }else {
            urlLink= lambda + '/activity?appname=' + appname
        }
    
        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if(response.data.statusCode === 200){
                    localStorage.setItem("previousid",response.data.result)
                }
            });
    }


// console.log('client dataaaaaa',clientData && clientData.companyid && clientData.companyid[0])

    const checkMailInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setPhonenumber(onlyDigits);

    };

    const handleSubmit = (e) => {
        let flag = true;
        if (phonenumber.trim() === "") {
            setPhoneError("Please enter phone number");
            flag = false
            setTimeout(function () { setPhoneError("") }, 3000);
        }
        if (name.trim() === "") {
            setNameError("Please enter Name");
            flag = false
            setTimeout(function () { setNameError("") }, 3000);
        }
        if ((!phonenumber.trim() && idc.trim()) ) {
           
            setPhoneError("Enter phone number");
            setTimeout(function () { setPhoneError("") }, 3000);
            flag = false
          }
      
        
          if (phonenumber.trim() && !idc.trim()) {
            setIdcError("Select country code");
            setTimeout(function () { setIdcError("") }, 3000);
            flag = false
          }

        if (flag) {
            if(activeLoad == ""){
            updateForm();
        }
        }

    }
    // console.log('companyMail',companyMail)

    const companyFormValid = ()=> {
        let flag = true;
        const regEx = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?/g;
        if (regEx.test(companyMail)) {
            console.log('companyMailcompanyMailcompanyMailcompanyMail1111111111111')
            setCompMailErr("");

        } else if (!regEx.test(companyMail) && companyMail !== "") {
            setCompMailErr("Email is Not Valid");
            console.log('companyMailcompanyMailcompanyMailcompanyMail22222222')

            // setTimeout(function () { setError("") }, 2000)
            flag = false;
        }else if (companyMail === "" || companyMail == undefined ){
            setCompMailErr("Please enter email");
            flag = false;
        }
        if (companyMobile === "") {
            setCompPhnErr("Please enter phone number");
            flag = false
            console.log('33333333333333333')

            setTimeout(function () { setCompPhnErr("") }, 3000);
        }
return flag
    }
    const handleCompanySubmit = () => {
        const k = companyFormValid()
        if(k){
            console.log('submit form')
            if(activeLoad == ""){
            updateCompanyForm()
            }
        }
    }

    function onConfirm() {
        setSuccess(false)
    };

    const updateCompanyForm = async () => {
        try {
            setActiveLoad("companyDetails")
            const response = await tmdbApi.updateCompanyData({
                "emailid": companyMail,
                "companyid": clientData && clientData.companyid && clientData.companyid[0],

                "phone": companyMobile
            });
            // console.log(response);

            if (response.statusCode == 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else if (response.result == "Client inactived") {
                    setInactiveFlag(true)
            
                  } else {
                    setSuccess(true);
                    GetClientDataFun();
                }
            }
            setActiveLoad("")
        } catch {
            setActiveLoad("")
            console.log("error");
        }
    };

    const updateForm = async () => {
        try {
            setActiveLoad("userDetails")
            const response = await tmdbApi.updateClientData({
                "personalphone": phonenumber,
                "name": name,
                "idc": idc
            });
            // console.log(response);

            if (response.statusCode == 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else if (response.result == "Client inactived") {
                    setInactiveFlag(true)
            
                  } else {
                    setSuccess(true);
                    console.log('response.result ',response?.result )
                    GetClientDataFun();
                }
            }
            setActiveLoad("")
        } catch {
            setActiveLoad("")
            console.log("error");
        }
    };

    const handlePwdGenerate = (e) => {

        if (passwordInput.password == "") {
            setError("Please Enter New Password");
            setTimeout(function () { setError("") }, 4000);

        } else if (passwordInput.confirmPassword == "") {
            setError("Please Enter Confirm Password");
            setTimeout(function () { setError("") }, 4000);

        } else if (passwordInput.oldPassword == "") {
            setError("Please Enter Old Password");
            setTimeout(function () { setError("") }, 4000);

        }
        else if (passwordInput.confirmPassword !== passwordInput.password) {
            setConfirmPasswordError("Confirm password is not matched");
            // setTimeout(function () { setConfirmPasswordError("") }, 4000);
        }
        //else if(lower === false && number === false && limit === false && lower === false && special === false){
        //     setError("Please Enter Strong Password");
        //     setTimeout(function () { setError("") }, 3000);

        // }
        else if (!limit || !upper || !special || !lower || !number){
      
            setError("Please enter the password as per the instructions");
            // setTimeout(function () { setError("") }, 3000);
        
     }
        else if (limit && upper && special && lower && number && confirmPasswordError === "") {
            setError("")
            if (passwordInput.password === passwordInput.confirmPassword) {
                setConfirmPasswordError("")
                if(activeLoad==""){
                pwdGenerate();
                }
            }
        }
    }
    const pwdGenerate = async () => {
        try {
            setActiveLoad("changePwd")
            const response = await tmdbApi.pwdGenerate({
                "emailid": emailId,
                "password": passwordInput.password,
                "oldPassword": passwordInput.oldPassword
            });
            // console.log(response.result.token);
            if (response.result == "old password is wrong") {
                setError("old password is incorrect");
                setTimeout(function () { setError("") }, 3000);
            } else if (response.result == "Invalid token or Expired") {
                setShowSessionPopupup(true)
            } else if (response.result == "Client inactived") {
                setInactiveFlag(true)

            } else {
                if (response.result.token) {

                    window.localStorage.setItem("token", response.result.token);
                    //  history.push("/category")
                    setSuccess(true);

                }
            }
            setActiveLoad("")
        } catch {
            setActiveLoad("")

            console.log("error");
        }
    };
    const handlePasswordChange = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue }
        console.log('NewPasswordInput', NewPasswordInput)
        setPasswordInput(NewPasswordInput);
        if(limit && upper && special && lower && number && confirmPasswordError === ""){
            setError("")
        }
    }
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    const handleValidation = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        //for password 
        if (passwordInputFieldName === 'password' || passwordInputFieldName === 'oldPassword') {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const passwordLength = passwordInputValue.length;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            const digitsPassword = digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
            }
            if (uppercasePassword) {
                var element = document.getElementById("err1");
                element.classList.add("vaild");
                setUpper(true);
            } else {
                var element = document.getElementById("err1");
                element.classList.remove("vaild");
                setUpper(false);
            }
            if (lowercasePassword) {
                var element = document.getElementById("err");
                element.classList.add("vaild");
                setLower(true);
            } else {
                var element = document.getElementById("err");
                element.classList.remove("vaild");
                setLower(false);
            }
            if (digitsPassword) {
                var element = document.getElementById("err2");
                element.classList.add("vaild");
                setNumber(true);
            } else {
                var element = document.getElementById("err2");
                element.classList.remove("vaild");
                setNumber(false);
            }
            if (specialCharPassword) {
                var element = document.getElementById("err3");
                element.classList.add("vaild");
                setSpecial(true)
            } else {
                var element = document.getElementById("err3");
                element.classList.remove("vaild");
                setSpecial(false)
            }
            if (minLengthPassword) {
                var element = document.getElementById("err4");
                element.classList.add("vaild");
                setLimit(true)
            } else {
                var element = document.getElementById("err4");
                element.classList.remove("vaild");
                setLimit(false)
            }
            if (passwordInputFieldName === 'password') {
                setPasswordErr(errMsg);
            } else {
                setOldPasswordError(errMsg)
            }

            if(limit && upper && special && lower && number && confirmPasswordError === ""){
                setError("")
            }
            
        }
        // for confirm password
        // if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

        //     if (passwordInput.confirmPassword !== passwordInput.password) {
        //         setConfirmPasswordError("Confirm password is not matched");
        //     } else {
        //         setConfirmPasswordError("");
        //     }

        // }
    }
    const handleAddclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.add("ins-dsp-none");
        setConfirmPasswordError("");
    }
    const handleRemoveclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.remove("ins-dsp-none");
    }
    const handleConfirm = (evnt) => {
        const passwordInputFieldName = evnt.target.name;
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {

            if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password is not matched");
            } else {
                setConfirmPasswordError("");
            }

        }
    }
    let myArray = []
    myArray = clientData && clientData.name?.split(" ");

    return (
        <>
            <Header activeClass={"9"} />
            {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

            {/* {showSessionPopupup && <SessionPopup />} */}


            <div className="inner-body profile">
                <div className="body-header">
                    <div className="md-container">
                        <div className="page-header">
                            <div className="block-title">
                                <h2>Profile</h2>
                            </div>
                            <button className="plain-btn"
                                onClick={handleBack}
                            ><span className="material-icons-outlined">chevron_left
                                </span>Back</button>

                        </div>
                    </div>
                </div>
                <div className="md-container ">
                    {/* <h1 className="sec-heading mt-4">PROFILE</h1> */}
                    <div className="card-block mt-3">
                        <div className="row profile-pic">

                            <div className="col-lg-8 col-md-8 col-xs-12">

                                <h3>User Details</h3>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="Glen Tintbell" value={name} onChange={(e) => setName(e.target.value)} />
                                    <label htmlFor="floatingInput">Name</label>
                                    {nameerror ? <span className="errormsg" style={{ fontWeight: 'bold', color: 'red', }}>{nameerror}</span> : ""}
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="floatingInput" value={emailId} disabled />
                                    <label htmlFor="floatingInput">Email Id</label>
                                </div>
                                <div className="form-floating mb-3 country-dropdown">
                              
                                        <select name="idc" value={idc} className="colorselect capitalize" onChange={(e) => setIdc(e.target.value)}>
                                            <option value="">select</option>
                                           
                                            {countries && countries.length > 0 && countries.map((task, i) => {
                                                return (
                                                    <><option key={i} value={task.alpha3}>{task.alpha3 + task.countrycode}</option></>
                                                )
                                            }
                                            )}
                                        </select>
                                        <div className="form-floating">
                                    <input type="text" className="form-control" id="floatingInput" value={phonenumber} name="phone" onChange={e => checkInput(e)} />
                                    
                                    <label htmlFor="floatingInput">Contact Number</label>
                                        <span className="errormsg" style={{
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>{IdcError}</span>
                                        {phoneerror ? <span className="errormsg" style={{
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>{phoneerror}</span> : ""
                                        }  
                             </div>
                             </div>
                                {/* <div className="form-floating mb-3 country-dropdown">
                                <select name="countryCodeAlpha2" className="colorselect capitalize">
                                <option value=" IND"> IND(+91)</option>
                                <option value=" ABW"> ABW(+297)</option>
                                <option value=" AGO"> AGO(+244)</option>
                                </select>
                                <div className="form-floating">
                                <input type="text" className="field form-control" placeholder="Enter Phone Number" maxlength="10" name="phonenumber" autocomplete="on" value=""/>
                                <label for="floatingInput">Contact Number</label></div>
                                </div>
                                */}
                               

                                <button className="fill_btn yellow-gradient" onClick={e => handleSubmit(e)} > {activeLoad === "userDetails" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null}Update</button>

                            </div>

                            <div className="col-lg-4 col-md-4 col-xs-12">
                                <div className="avatar-block">
                                    <div className="avatar-header">
                                        <div className="avatar-img-block">
                                            {/* <img src={img + Config.imgmiddle + "profile-pic.jpg?auto=compress,format"} className="avatar-img" /> */}
                                            <p>{myArray && myArray.length > 1 ? myArray && myArray.length > 0 && myArray[0]?.slice(0, 1)?.toUpperCase() + myArray[1]?.slice(0, 1)?.toUpperCase()
                                                : clientData && clientData.name?.slice(0, 2)?.toUpperCase()}</p>
                                        </div>
                                    </div>
                                    <div className="avatar-body">
                                        <h6 className="username mt-3">{clientData.name}</h6>
                                        <p className="lg-badge badge">{clientData && clientData.type ? clientData.type == 'BOTH' ? 'BUYER, SELLER' : clientData.type : 'BUYER'}</p>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    <div className="card-block my-3">
                        <div className="col-lg-8 col-md-8 col-xs-12">

                            <h3>Company Details</h3>
                            {clientData && clientData.companyname ?
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" value={companyName} disabled />
                                    <label htmlFor="floatingInput">Company Name</label>

                                </div>
                                : null}
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" value={companyMail} onChange={(e) => setCompanyMail(e.target.value)}/>
                                <label htmlFor="floatingInput" >Company Email Id</label>
                                {compMailErr ? <span className="errormsg" style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{compMailErr}</span> : ""
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingInput" value={companyMobile} name="Companyphone" onChange={e => handleCompanyMobile(e)} />
                                <label htmlFor="floatingInput">Company Phone Number</label>
                                {compPhnErr ? <span className="errormsg" style={{
                                    fontWeight: 'bold',
                                    color: 'red',
                                }}>{compPhnErr}</span> : ""
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingInput" value={companyType} name="companyType" disabled />
                                <label htmlFor="floatingInput">Company Type</label>

                            </div>

                            {clientData && clientData.contenttype ?
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput" value={companyContenttype} disabled />
                                    <label htmlFor="floatingInput">Content Type</label>

                                </div>
                                : null}

                            <button className="fill_btn yellow-gradient" onClick={e => handleCompanySubmit(e)} >{activeLoad === "companyDetails" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null}Update</button>

                        </div> 
                  </div>
                    <div className="card-block my-3">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-xs-12">
                                <h3>Change Password</h3>
                                <div className="form-floating mb-3">
                                    <input type={passwordShown ? "text" : "password"} value={passwordInput.oldPassword} onChange={(e) => handlePasswordChange(e)} name="oldPassword" placeholder="Old Password" className="form-control"
                                    />
                                    <p className="text-danger">{oldPasswordError}</p>
                                    <label className="floatingInput">Old Password<span>*</span></label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type={passwordShown ? "text" : "password"} value={passwordInput.password} onChange={(e) => handlePasswordChange(e)} onKeyUp={handleValidation} name="password" placeholder="Password" className="form-control" onBlur={(e) => { handleAddclass(e) }}
                                        onFocus={(e) => { handleRemoveclass(e) }} />
                                    <p className="text-danger">{passwordError}</p>
                                    <label className="floatingInput">New Password<span>*</span></label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type={passwordShown ? "text" : "password"} value={passwordInput.confirmPassword} onChange={(e) => handlePasswordChange(e)} onKeyUp={handleConfirm} onBlur={(e) => { handleAddclass(e) }} onFocus={(e) => { handleRemoveclass(e) }} name="confirmPassword" placeholder="Password" className="form-control" />
                                    <p className="text-danger">{confirmPasswordError}</p>
                                    <label className="floatingInput">Confirm Password<span>*</span></label>
                                </div>
                                <div className="flex-left terms-block">
                                    <input type="checkbox" id="terms-check" onChange={(e) => togglePassword(e)} />
                                    <label > Show Password.</label>
                                </div>
                                <p className="text-danger">{error}</p>
                                <div className="signin-footer mt-4">
                                    <button className="fill_btn yellow-gradient" onClick={e => handlePwdGenerate(e)}>{activeLoad === "changePwd" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null}Change Password</button>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-xs-12">
                                <div className="create-password">
                                    <div className="crpsd-cnt-blk">
                                        <div className="create-password-instruction ins-dsp-none" id="instruction">
                                            <p className="error" id="err">{lower ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a lower case letter</p>
                                            <p className="error" id="err1"> {upper ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain an upper case letter</p>
                                            <p className="error" id="err2">  {number ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a number</p>
                                            <p className="error" id="err3"> {special ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain a special character or a space</p>
                                            <p className="error" id="err4"> {limit ? <span className="material-symbols-outlined">
                                                check
                                            </span> : <span className="material-symbols-outlined">
                                                close
                                            </span>} Password must contain at least 8 characters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            <Footer menus={menus} />
            <SweetAlert show={success}
                custom
                confirmBtnText="Ok"
                confirmBtnBsStyle="primary"
                title={"Updated successfully"}
                onConfirm={e => onConfirm()}
            >
            </SweetAlert>
        </>
    );
};

export default Profile;
