/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import tmdbApi from "../api/tmdbApi";
 import { useHistory } from "react-router";
 import * as Config from "./../constants/Config";
 import Footer from "../components/footer/Footer";
 import { useParams } from 'react-router-dom';
 import $ from "jquery";
 import Modal from 'react-bootstrap/Modal';
 
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 import { useLocation } from 'react-router-dom';
 import axios from "axios";
 import { contentContext } from "../context/contentContext";
 import moment from "moment";
 import SessionPopup from "./SessionPopup"
 import FileViewer from "./docViewer";
 import Loader from "../components/loader";
 
 import {
   FacebookShareButton,
   GooglePlusShareButton,
   LinkedinShareButton,
   TwitterShareButton,
   EmailShareButton,
 } from 'react-share';
 import PlayerInfo from "./player";
 import { InputGroup } from "react-bootstrap";
 let { lambda, appname } = window.app;
 
 
 const MoreInfov1 = () => {
   let { id } = useParams();
   const [config, setConfig] = useState({});
   const [info, setInfo] = useState({});
   const [trailersData, setTrailersData] = useState([]);
   const [BTSData, setBTSData] = useState([]);
   const [clipsData, setClipsData] = useState([]);
   // const [contentImageData, setContentImageData] = useState([]);
   //const [menus, setMenus] = useState([]);
   const history = useHistory();
   const [show, setShow] = useState(false);
   const [playerSubTitles, setPlayerSubTitles] = useState(''); 
 
   const [toggle, setToggle] = useState(false);
   const [wishLoad, setWishLoad] = useState(false);
   const [ShowErrMsg, setShowErrMsg] = useState(false);
   const [showRequestPopup, setShowRequestPopup] = useState(false);
   const [isMailSentTwice, setIsMailSentTwice] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [showDoc, setshowDoc] = useState(false);
   const location = useLocation();
   const [type, setType] = useState(false);
 
 
   const [wish, setWish] = useState(false);
   const handleCloseWish = () => setWish(false);
 
   const [showSessionPopupup, setShowSessionPopupup] = useState(false);
 
 
   const [play, setPlay] = useState(false);
   const [showDescription, setShowDescription] = useState(false);
 
   const [activeCheckId, setActiveCheckId] = useState("");
 
   const [playContent, setPlayContent] = useState({});
   const [configFlag, setConfigFlag] = useState(true);
   const [fileDocName, setFileDocName] = useState("");
 
   const [load, setLoad] = useState("");
   const [blockedmsg, setBlockedMsg] = useState(false);
 
 
   const { isShowMyContent, clientData, GetClientDataFunction, wishListData, setWishListData, menus,setContentSearchFlag,contentSearchFlag , setRoute} = useContext(contentContext);
 
   useEffect(() => {
     let loadurl = id.split("&");
     console.log("loadurl", loadurl);
     if (loadurl && loadurl[1]) {
       localStorage.setItem("url", loadurl[1])
     }
     // if (loadurl[1]) {
     //   loadMainUrl(loadurl[0]);
     // }
     setContentSearchFlag(false)
     setRoute("notcontent")
    }, []);
 
   useEffect(() => {
     localStorage.removeItem("check");
 
     getWishlistFun();
     getContent();
     if (localStorage.getItem("clientid") != null) {
       GetClientDataFunction();
       clientActivity();
     }
    
   }, []);
   useEffect(() => {
     if (window.site) {
       setConfigFlag(false)
       setConfig(window.site);
     }
 
   }, [window.site]);
 
   const succesNotify = () => toast.success('Your request has been sent successfully', {
     position: "bottom-center",
     autoClose: 3000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "light",
   },)
   const errorNotify = () => toast.error('Failed to send the request, Please Try again after some time', {
     position: "bottom-center",
     autoClose: 3000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "light",
   },)
 
 
   if (config.common && config.common.resourcesUrl) {
     var img = config.common.resourcesUrl;
   }
 
   if (config.common && config.common.proxiesCloudFront) {
     var proxyUrl = config.common.proxiesCloudFront
   }
 
   const toggleClass = () => {
     setToggle(!toggle);
   };
 
   const loadMainUrl = (uri) => {
     window.location.replace("/moreinfo/" + uri);
   };
 
 
   const getContent = async (e) => {
     console.log(localStorage.getItem("url"))
     const token = localStorage.getItem("token")
     const clientId = localStorage.getItem("clientid")
     // console.log('clientIdclientId===>',clientId)
     // console.log('tokentoken===>',token)
     let url = ""
     if (clientId != null && token != null) {
       if (localStorage.getItem("url")) {
         url = lambda + '/content?appname=' + appname + '&contentid=' + id + "&clientid=" + clientId + "&token=" + token + "&showoffline=true"
       } else {
         url = lambda + '/content?appname=' + appname + '&contentid=' + id + "&clientid=" + clientId + "&token=" + token
       }
     }
     else {
       if (localStorage.getItem("url")) {
         url = lambda + '/content?appname=' + appname + '&contentid=' + id + "&showoffline=true"
       } else {
         url = lambda + '/content?appname=' + appname + '&contentid=' + id
       }
     }
     await axios({
       method: 'GET',
       url: url
     })
       .then(function (response) {
         if (response.data.result === "content blocked to this client") {
           setBlockedMsg(true);
           localStorage.setItem("blocked", response.data.result);
           setLoad(response.data.result)
           localStorage.removeItem("info")
           localStorage.removeItem("item")
           localStorage.removeItem("isAddOrDelete")
         }
 
         else if (response.data.result != "No Content Data") {
           setInfo(response.data.result[0]);
           let clipsData1 = response.data && response.data.result[0] && response.data.result[0].clips && response.data.result[0].clips ? response.data.result[0].clips : []
           setClipsData(clipsData1)
 
           if (localStorage.getItem("isAddOrDelete")) {
             onClickAddToWishList("", JSON.parse(localStorage.getItem("item")), localStorage.getItem("isAddOrDelete"))
             localStorage.removeItem("item")
             localStorage.removeItem("isAddOrDelete")
           }
           if (localStorage.getItem("info")) {
               handleRequestVideo("", JSON.parse(localStorage.getItem("info")))
               localStorage.removeItem("info")
           }
 
         }
         else {
           console.log('no content data')
           setShowErrMsg(true)
         }
       });
   }
   // console.log('clientData', clientData)
   const clientActivity = () => {
     let path = window.location.pathname.split("/");
    //  const pageName = path[path.length - 2];
     var presentTime = moment();
     let payload;
     
         payload = {
             "clientid": localStorage.getItem("clientid"),
             "pagename": 'CONTENT INFO',
             "pageurl": window.location.href,
             "starttime": presentTime,
             "useragent": JSON.parse(localStorage.getItem("loc"))
 
         };
 
     
     let urlLink;
     if(localStorage.getItem("previousid")){
         urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
     }else {
         urlLink= lambda + '/activity?appname=' + appname
     }
 
     axios({
         method: 'POST',
         url: urlLink,
         data: payload
     })
         .then(function (response) {
             if(response.data.statusCode === 200){
                 localStorage.setItem("previousid",response.data.result)
             }
         });
 }
 
   const handleRequestVideo = async (e, item) => {
     if (localStorage.getItem("clientid") != null && load !== "content blocked to this client") {
       try {
 
         const companyId = localStorage.getItem("companyid")
         console.log('handleRequestVideo---> ', item)
         console.log('handleRequestVideo---> ', companyId)
 
         const payload = { "companyid": companyId, "title": item.title, "genre": item.genre, "category": item.category, "contentid": id }
         const response = await tmdbApi.requestVideo(payload);
         console.log("response.......>", response.statusCode)
         let k = response.result == "Already sent your req"
         if (response.statusCode == 200) {
           console.log('asdfasdfasdfasdf', response)
           if (response.result == "Already sent your req") {
             setIsMailSentTwice(true)
           }
           setShowRequestPopup(true)
 
           // succesNotify()
         } else {
           errorNotify()
 
         }
       } catch {
         console.log("error");
       }
     }
     else {
       history.push("/login");
       localStorage.setItem("check", id);
       localStorage.setItem("info", JSON.stringify(info));
     }
   }
 
 
   // console.log('cliepss s data',clipsData)
   const handleClose1 = (e, item) => {
     // let k = item.category
     // k = k[0] || ""
     // history.push('/content/' + k);
     const token = localStorage.getItem("token")
 
     const clientId = localStorage.getItem("clientid")
     console.log('close')
     if (clientId != null && token != null) {
       if (localStorage.getItem("flag")) {
         history.push("/login")
         localStorage.removeItem("flag")
         localStorage.removeItem("blocked")
       } else {
         // history.go(-1)
         history.push('/contentv1')
         localStorage.removeItem("blocked")
       }
     } else {
       history.push('/')
       localStorage.removeItem("blocked")
     }
   }
   let description = info && info.synopsis || "";
   if (description && description.length > 400) {
     description = description.substring(0, 400) + "..."
   }
   // console.log('   setShowErrMsg(true)',ShowErrMsg)
   // const handleEnquire = (e) => {
   //   setEnquire(true);
   // }
 
 
   const onClickAddToWishList = (e, item, isAddOrDelete) => {
     setActiveCheckId(item.contentid)
     console.log('item', item)
     console.log('isAddOrDelete', isAddOrDelete)
     console.log('return')
     if (localStorage.getItem("clientid") != null) {
 
 
       if (isAddOrDelete == "Add") {
 
         const clientid = localStorage.getItem("clientid")
 
         let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;
 
         let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
           item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
             : "defaultImg"
 
         let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
           item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
             : defaultImg
 
         const newObj = {
           clientid: clientid,
           appname: item.appname,
           title: item.title,
           contentid: item.contentid,
           // thumbnail: item.thumbnail,
           [imgKey]: imgValue,
 
           category: item.category,
           companyid: item.companyid
         }
 
         AddToWishlistFun([newObj])
 
       }
       else {
         deleteWishlistFun(item.contentid)
       }
     }
     else {
       history.push("/login");
       localStorage.setItem("check", id);
       localStorage.setItem("isAddOrDelete", isAddOrDelete);
       localStorage.setItem("item", JSON.stringify(item));
     }
 
   }
   const deleteWishlistFun = async (contentid) => {
    // try {
    //   // setIsLoading(true)
    //   setWishLoad(true)
    //   const response = await tmdbApi.deleteWishlist({contentid: contentid});
    //   if (response.statusCode === 200) {

    //     console.log('response result', response.result)
    //     getContent();
    //     getWishlistFun()
    //     setWishLoad(false);
    //     handleCloseWish();

    //   }
    // } catch {
    //   setWishLoad(false);
    //   console.log("error");
    // }


    try {
      setWishLoad(true)

      const clientid = localStorage.getItem("clientid")

      axios({
          method: 'DELETE',
          url: lambda + "/wishlist?appname=" + appname + "&clientid=" + clientid,
          data: { "contentid": [contentid] },
      })
          .then(function (response) {
            console.log('response',response)
            if (response?.data?.statusCode === 200) {
            console.log('response result', response?.data?.result)
            getContent();
            getWishlistFun()
            setWishLoad(false);
            handleCloseWish();
            }
              
          });



  } catch {
    setWishLoad(false);
    console.log("error");
  }


  }
   const AddToWishlistFun = async (formvaluesObj) => {
 
     try {
       //setIsLoading(true)
       setWishLoad(true)
       const response = await tmdbApi.AddToWishlist({ 'cart': formvaluesObj });
       if (response.statusCode === 200) {
 
         getContent();
         console.log('response result', response.result)
         getWishlistFun()
         setWishLoad(false);
 
         setTimeout(function () { setWish(false) }, 1000)
 
 
       }
     } catch {
       setWishLoad(false);
       console.log("error");
     }
 
 
 
   }
   const handleDescription = () => {
 
     setShowDescription(!showDescription)
   }
   const getWishlistFun = async () => {
     try {
       const token = localStorage.getItem("token")
       const clientId = localStorage.getItem("clientid")
       if (clientId != null && token != null) {
         const response = await tmdbApi.getWishlist();
         if (response.statusCode === 200) {
 
           let data = response.result && response.result && response.result.result ? response.result.result : []
           setWishLoad(false);
           if (data.length > 0) {
             setWishListData(data);
 
           }
 
           else {
             setWishListData(data);
 
           }
           setActiveCheckId("")
         }
       }
     } catch {
       setActiveCheckId("")
       setWishLoad(false);
       console.log("error");
     }
 
   }
 
 
   const handlePlayer = (e, type, content) => {
     if (type === "play") {
       console.log("button clicked");
       setPlay(true);
       let allSubTitles = content?.subtitles
       const k = allSubTitles?.map(eachItem=>{
         return {...eachItem,path: window.site.common.proxiesCloudFront+"/"+eachItem.path}
     })
     if(content?.subtitles)
       setPlayerSubTitles(k)
 
       setPlayContent(content)
       console.log("playercontent----->", content);
     }
   }
   const openFileViewer = (e, content) => {
     let source = window.site.common.resourcesUrl + "/" + content.sourcepath
     setPlayContent(source);
     setFileDocName(content.name != "" && content.name != undefined ? content.name :content.filename)
     setshowDoc(true);
     setType(content.mimetype);
     console.log("playercontent==========>", content);
   }
 
 
   let picName = info.mediaType === 'video' ? 'videoclip-defaulr' : info.mediaType === 'audio' ? 'musicfile-default' : info.mediaType === 'pdf' ? 'pdf-default' : info.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
   // let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/${picName}.jpg`;
   let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;
   // let defaultImg = `${img}/orasi/common/images/${picName}.jpg`;
 
 
   // let imgUrl = info.thumbnail === "" || info.thumbnail === undefined ? defaultImg : img + info.thumbnail;
   let imgUrl = info.portraitimage != undefined && info.portraitimage != "" ?info.portraitimage.startsWith("http")?  info.portraitimage:img + info.portraitimage :
   info.landscapeimage != undefined && info.landscapeimage != "" ?info.landscapeimage.startsWith("http")?  info.landscapeimage:img +info.landscapeimage : defaultImg

 
 
 
   let isAddOrDelete = info.cart && info.cart === true ? 'Delete' : 'Add';
 
 
   // console.log("infp----------->", info);
   // console.log('trailersData',trailersData)
   // let k = Object.keys(clipsData).map((key) => {
   //   //  console.log("key",clipsData[key])
   //   let clipkey = key
   //   let clipvalue = clipsData[key]
   //   let eachClip = clipsData[key][0]
 
   //   Object.keys(eachClip).map((eachItemInclip) => {
   //     // console.log("ppppppppp eachItem", eachItemInclip)
   //     let clipItem = eachClip[eachItemInclip]
   //     // console.log(eachItemInclip, ':', clipItem)
   //   })
 
   // })
   //  console.log('Object.keys(clipsData)',Object.keys(clipsData))
   //  {Object.keys(myObjects).map((key) => (
   //   <div key={key}>
   //     <p>{myObjects[key].name}</p>
   //     {/* render other JSX for each object */}
   //   </div>
   // ))}
 
   // console.log('clipsData', clipsData)
   const handleCloseErr = () => {
     const token = localStorage.getItem("token")
     const clientId = localStorage.getItem("clientid")
     console.log('close')
     if (clientId != null && token != null) {
       history.go(-1)
     } else {
       history.push('/')
     }
     setShowErrMsg(false)
   }
   const handleCloseRequest = () => {
     setShowRequestPopup(false)
   }
   // let k = "           "
   // console.log(' info.cast  info.cast ', info.cast )
   // console.log("imgggggggggggggggggggggggg",showRequestPopup);
   const cardWidth = $(".title-thumb").width();
   return (
     <>
       <Modal className="access-denied" show={ShowErrMsg}>
 
         <div className="modal-body enquiry-form">
           <div className="container">
             <button className="close-btn" onClick={(e) => handleCloseErr(e)}><span className="material-icons">close</span></button>
             <span className="material-icons access-denied-icon">error</span>
             <h3>Content Not Found</h3>
 
             {/* <p>Please Try Again Later</p> */}
 
             {/* <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>Yes, Delete</button> */}
 
           </div>
         </div>
 
       </Modal>
       <Modal className="access-denied" show={showRequestPopup}>
 
         <div className="modal-body enquiry-form"><div className="container">
           <button className="close-btn"><span className="material-icons" onClick={(e) => handleCloseRequest(e)}>close</span></button>
           <span className="material-symbols-outlined  access-denied-icon">
             mark_email_read
           </span>
           <h3>Request Raised</h3>
 
           <p>{`${isMailSentTwice ? `Your request for this video has already been raised.` : `Your request for a Video related to title, ${info.title}, has been raised. `}`} We will load the video at the earliest possibility. <br />You will receive an email once it is available.</p>
           <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={(e) => handleCloseRequest(e)}>OK</button>
         </div>
         </div>
 
       </Modal>
       {/* {showSessionPopupup && <SessionPopup />} */}
       <>
 
 
         {!ShowErrMsg &&
           <>
             {configFlag ? <Loader /> :
               <div className="asset-container">
 
                 <div className="asset-container-header"><img src={"https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images//logoupdated.png"} className="logo" />{(localStorage.getItem("url") && info.status === "OFFLINE") ? "" : <button className="close-btn" onClick={(e) => handleClose1(e, info)}><span className="material-icons">close</span></button>}</div>
 
                 {/* <button className="close-btn" onClick={(e) => handleClose(e, info)}><span className="material-icons" >close</span></button> */}
                 {blockedmsg === false ?
                   <><div className="asset-details-wrapper">
                     <div className="row asset-data-row">
                       <div className="col-md-9">
 
                         <div className="asset-metadata">
                           {/* <h1 className="meta-title">{info.title}</h1> */}
                           {/* <ul className="meta-data-list">
 <li>{info.category}</li>
 </ul> */}
                           <div className="title-wrapper">
                             <h1 className="meta-title">{info.title}</h1>
                           </div>
                           <ul className="meta-data-list">
 
                             {/* <li>{info.category && info.category.join(', ')}</li> */}
                             <li>{info.category && typeof (info.category) != 'string' ? info.category && info.category.length > 1 ? info.category.join(', ') : info.category : ""}</li>
 
 
                             {info.duration && <li><span className="material-symbols-outlined">timelapse</span> {info.duration} </li>}
                             {info.certificate ? <li>{info.certificate ? info.certificate : null}</li> : null}
 
                             {info.releasedate ? <li><span className="material-symbols-outlined me-1">rocket_launch</span>
                               {info.releasedate && moment(info.releasedate).format('DD-MMM-YY')}</li> : null}
 
 
                           </ul>
                           <p className="title-description">
                             {description && description.substring(0, 370)+" "}
                             {description && description.length > 370 ? (<button className="more-popup-btn" data-bs-toggle="modal" data-bs-target="#descriptionPopup" onClick={handleDescription}>more</button>) : null}
 
                           </p>
                           {/* <ul className="buttons-row">
 <li><button className="fill_btn yellow-gradient" onClick={handleWishlist}>Wishlist
 {info.wishlist === true ?
 <span className="material-icons">remove</span> :
 <span className="material-icons">add</span>
 }
 </button></li>
 <li><button className="fill_btn yellow-gradient" onClick={handleEnquire}>Enquire</button></li>
 {/* <li><button className="plain_btn"><span className="material-icons"> play_arrow </span>Play</button></li> */}
                           {/* <li><button className="plain_btn" onClick={handleShow}><span className="material-icons">share</span>Share</button></li>
 </ul> */}
 
                           {Object.keys(clipsData).length <= 0 && Object.keys(info).length > 0 &&
                             <>{(localStorage.getItem("url") && info.status === "OFFLINE") ? "" :
                               <button type="button" onClick={(e) => handleRequestVideo(e, info)} className="btn btn-primary morevideos-btn" data-bs-toggle="dropdown"><span className="material-symbols-outlined  me-1">
                                 video_chat
                               </span>Request Video</button>}</>}
 
                           <div className="buttons-row">
                             {Object.keys(clipsData).map((key, id) => {
                               let clipName = key;
                               let eachClip = clipsData[key];
 
                               return (
                                 <div className="dropdown me-3 mb-2" key={id}>
                                   <button type="button " className="btn btn-primary dropdown-toggle morevideos-btn" data-bs-toggle="dropdown">
                                     <span className="material-symbols-outlined me-1">
                                       {/* {clipName} */}play_arrow
                                     </span>{clipName}
                                   </button>
                                   <ul className="dropdown-menu morevideos-dd ">
                                     {Object.keys(eachClip).map((eachItemInclip, i) => {
                                       let clipItem = eachClip[eachItemInclip];
                                       let file = clipItem && clipItem.filename && clipItem.filename.split(".");
 
                                       return (
                                         <li key={i}><button className="dropdown-item" onClick={(e) => clipItem.filetype == 'VIDEO'
                                           ? handlePlayer(e, "play", clipItem) : clipItem.filetype != 'AUDIO' ? openFileViewer(e, clipItem) : null}>{file && file[0]}</button></li>
                                       );
                                     })}
                                   </ul>
                                 </div>);
                             })}
                           </div>
                           <div className="meta-info-outer">
                             <div className="meta-info">
 
                               <ul>
                                 {info.genre && typeof (info.genre) != 'string' ?
                                   <li>
                                     <h6>Genres</h6>
                                     <p>{info && info.genre && info.genre.length > 1 ? info.genre?.join(", ") : info.genre}</p>
                                   </li> : null}
                                 {info.musicgenre && typeof (info.musicgenre) != 'string' ?
                                   <li>
                                     <h6>Music Genre</h6>
                                     <p>{info && info.musicgenre.length > 1 ? info.musicgenre?.join(", ") : info.musicgenre}</p>
                                   </li> : null}
                                 {info.director && typeof (info.director) != 'string' ?
                                   <li>
                                     <h6>Director</h6>
                                     <p>{info && info.director.length > 1 ? info.director?.join(", ") : info.director}</p>
                                   </li> : null}
                                 {/* {info && info.cast && typeof (info.cast) != 'string' ?
       <li>
         <h6>Cast</h6>
         <p>{info && info.cast.length > 1 ? info.cast?.join(", ") : info.cast}</p>
       </li> : null
     } */}
                                 {info && info.languages && typeof (info.languages) != 'string' ?
                                   <li>
                                     <h6>Languages</h6>
                                     <p>{info && info.languages.length > 1 ? info.languages?.join(", ") : info.languages}</p>
                                   </li> : null}
                                 {info.countryorigin && typeof (info.countryorigin) != 'string' ?
                                   <li>
                                     <h6>Country of Origin</h6>
                                     <p>{info && info.countryorigin.length > 1 ? info.countryorigin?.join(", ") : info.countryorigin}</p>
                                   </li> :
                                   null}
                                 {info.releaseyear && info.releaseyear.toString().trim() != "" ?
                                   <li>
                                     <h6>Release Year</h6>
                                     <p>{info && info.releaseyear}</p>
                                   </li> : null}
 
                                 {info.noofseasons && info.noofseasons.toString().trim() != "" ?
                                   <li>
                                     <h6>Seasons</h6>
                                     <p>{info && info.noofseasons}</p>
                                   </li> : null}
 
                                 {info.noofepisodes && info.noofepisodes.toString().trim() != "" ?
                                   <li>
                                     <h6>Episodes</h6>
                                     <p>{info && info.noofepisodes}</p>
                                   </li> : null}
 
                                 {info.videoquality && typeof (info.videoquality) != 'string' ?
                                   <li>
                                     <h6>Video Quality</h6>
                                     <p>{info && info.videoquality.length > 1 ? info.videoquality?.join(", ") : info.videoquality}</p>
                                   </li> : null}
 
                                 {info.subtitleslanguages && typeof (info.subtitleslanguages) != 'string' ?
                                   <li>
                                     <h6>Subtitles</h6>
                                     <p>{info.subtitleslanguages.length > 1 ? info.subtitleslanguages?.join(", ") : info.subtitleslanguages}</p></li> : null}
                                 {info.typeofrights && typeof (info.typeofrights) != 'string' ?
                                   <li>
                                     <h6>Types of Rights</h6>
                                     <p>{info.typeofrights.length > 1 ? info.typeofrights?.join(", ") : info.typeofrights}</p>
                                   </li> : null}
 
 
 
 
                                 {info.territoriesavailable && typeof (info.territoriesavailable) != 'string' ?
                                   <li>
                                     <h6>Territories Available</h6>
                                     <p>{info && info.territoriesavailable.length > 1 ? info.territoriesavailable?.join(", ") : info.territoriesavailable}</p>
                                   </li> : null}
 
 
 
                                 {info.bandname && typeof (info.bandname) === 'string' && info.bandname.trim() != "" ?
                                   <li>
                                     <h6>Band Name</h6>
                                     <p>{info && info.bandname}</p>
                                   </li> : null}
                                 {info.otherartists && typeof (info.otherartists) === 'string' && info.otherartists.trim() != "" ?
                                   <li>
                                     <h6>Other Artists</h6>
                                     <p>{info && info.otherartists}</p>
                                   </li> : null}
 
                                 {info.lyricist && typeof (info.lyricist) === 'string' && info.lyricist.trim() != "" ?
                                   <li>
                                     <h6>Lyricist</h6>
                                     <p>{info && info.lyricist}</p>
                                   </li> : null}
                                 {info.performancetype && typeof (info.performancetype) === 'string' && info.performancetype.trim() != "" ?
                                   <li>
                                     <h6>Performance Type</h6>
                                     <p>{info && info.performancetype}</p>
                                   </li> : null}
                                 {info.leadartist && typeof (info.leadartist) != 'string' ?
                                   <li>
                                     <h6>Lead Artist</h6>
                                     <p>{info && info.leadartist.length > 1 ? info.leadartist?.join(", ") : info.leadartist}</p>
                                   </li> : null}
                                 {info.sport && typeof (info.sport) != "string" ?
                                   <li>
                                     <h6>Sport</h6>
                                     <p>{info && info.sport.length > 1 ? info.sport?.join(", ") : info.sport}</p>
                                   </li> : null}
                                 {info.sportsstars && typeof (info.sportsstars) === 'string' && info.sportsstars.trim() != "" ?
                                   <li>
                                     <h6>Sports Stars</h6>
                                     <p>{info && info.sportsstars}</p>
                                   </li> : null}
 
                                 {info.league_tournament && typeof (info.league_tournament) === 'string' && info.league_tournament.trim() != "" ?
                                   <li>
                                     <h6>league / tournament</h6>
                                     <p>{info && info.league_tournament}</p>
                                   </li> : null}
                                 {info.mealtype && typeof (info.mealtype) === 'string' && info.mealtype.trim() != "" ?
                                   <li>
                                     <h6>Meal Type</h6>
                                     <p>{info && info.mealtype}</p>
                                   </li> : null}
                                 {info.anchor && typeof (info.anchor) === 'string' && info.anchor.trim() != "" ?
                                   <li>
                                     <h6>Anchor</h6>
                                     <p>{info && info.anchor}</p>
                                   </li> : null}
                                 {info.chef && typeof (info.chef) === 'string' && info.chef.trim() != "" ?
                                   <li>
                                     <h6>Chef</h6>
                                     <p>{info && info.chef}</p>
                                   </li> : null}
                                 {info.cuisine && typeof (info.cuisine) != 'string' ?
                                   <li>
                                     <h6>Cuisine</h6>
                                     <p>{info && info.cuisine.length > 1 ? info.cuisine?.join(", ") : info.cuisine}</p>
                                   </li> : null}
                                 {info.foreigntitle && typeof (info.foreigntitle) === 'string' && info.foreigntitle.trim() != "" ?
                                   <li>
                                     <h6>Foreign Title</h6>
                                     <p>{info && info.foreigntitle}</p>
                                   </li> : null}
 
 
 
                                 {/* {info.mediaType ?
 <li>
  <h6>MediaType</h6>
  <p>{info.mediaType}</p>
 </li> : null
 } */}
                               </ul>
 
 
                               {info && info.cast && typeof (info.cast) != 'string' ?
                                 <ul className="cast-li">
                                   <li>
                                     <h6>Cast</h6>
                                     <p>{info && info.cast.length > 1 ? info.cast?.join(", ") : info.cast}</p>
                                   </li>
                                 </ul> : null}
 
 
                             </div>
                           </div>
                         </div>
 
 
                       </div>
                       <div className="col-md-3">
                         {/* <div className="title-thumb">
 <div className="black-overlay thumboverlay"><span className="material-symbols-outlined">
 play_circle
 </span></div>
 <img src={imgUrl} />
 </div> */}
                         <div className="title-thumb-wrapper">
                           <div className="title-thumb">
                             {/* <div className="black-overlay thumboverlay"><span className="material-symbols-outlined">
 play_circle
 </span></div> */}
                             <img src={imgUrl.toString() + "?auto=compress,format&width=" + cardWidth} alt="thumb" />
                           </div>
                           <ul className="title-thumb-buttons mt-4">
                             {/* <button className="fill_btn yellow-gradient me-2" onClick={handleWishlist}>
 {info.cart === true ?
  <span className="material-icons">remove</span> :
  <span className="material-icons">add</span>
 }Cart
 </button> */}
                             {(localStorage.getItem("url") && info.status === "OFFLINE") ? "" :
                               <><>  {!isShowMyContent && clientData.type != "SELLER" ?
                                 <button className="fill_btn yellow-gradient me-2" onClick={(e) => onClickAddToWishList(e, info, isAddOrDelete)}>
 
                                   {info.cart && info.cart === true ? (
 
                                     activeCheckId === info.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                       <span className="material-icons done">done</span>
 
                                   ) :
                                     (
 
                                       activeCheckId === info.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                         <span className="material-icons add"> add </span>
 
                                     )}
 
                                   CART
                                 </button> : null}</><button className="fill_btn yellow-gradient me-2" onClick={handleShow}><span className="material-icons">share</span>Share</button></>}
                             {/* <button className="fill_btn yellow-gradient" onClick={handleEnquire}><span className="material-symbols-outlined">
 feed
 </span>Enquire</button> */}
                           </ul>
                         </div>
 
                       </div>
                     </div>
 
                   </div><div className="blur-bg">
                       {/* <div className="black-overlay"></div> */}
                       <img src={imgUrl.toString()} alt="thumb" />
                     </div></> : <><button className="close-btn" onClick={(e) => handleClose1(e, info)}><span className="material-icons">close</span></button><div className="error-wrapper title-not-available">
                       <span className="material-symbols-outlined">privacy</span>
                       <h5>sorry!</h5>
                       <p>This title is not available for further action,</p>
                       <p>Please contact your account manager for more details</p>
                       <button className="btn" onClick={(e) => handleClose1(e, info)}>go to market place</button>
                     </div></>}
 
                 <Footer menus={menus} moreInfoCls={"fixed-footer"} />
                 <ToastContainer
                   position="bottom-center"
                   autoClose={3000}
                   hideProgressBar={false}
                   newestOnTop={false}
                   closeOnClick
                   rtl={false}
                   pauseOnFocusLoss
                   draggable
                   pauseOnHover={false}
                   theme="light"
                 />
               </div>
             }
           </>
 
         }
       </>
 
 
       <Modal show={show} className="share-modal" onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Share</Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <div className="share-icons">
             <div className="button-wrapper">
               <EmailShareButton
                 url={String(window.location.origin + window.location.pathname)}
                 subject={info.title}
               >
 
                 <img src={img + Config.imgmiddle + "email.png?w=60"} />
                 <p className="u-thumb-cont-p">Email</p>
               </EmailShareButton>
             </div>
             <div className="button-wrapper">
               <FacebookShareButton
                 url={String(window.location.origin + window.location.pathname)}
                 subject={info.title}>
                 <img src={img + Config.imgmiddle + "facebook.png?w=60"} />
                 <p className="u-thumb-cont-p">Facebook</p>
               </FacebookShareButton>
             </div>
             <div className="button-wrapper">
               <TwitterShareButton
                 url={String(window.location.origin + window.location.pathname)}
                 title={info.title} >
                 <img src={img + Config.imgmiddle + "twitter.png?w=60"} />
                 <p className="u-thumb-cont-p">Twitter</p>
               </TwitterShareButton>
 
             </div>
             <div className="button-wrapper">
               <LinkedinShareButton
                 url={String(window.location.origin + window.location.pathname)}
                 title={info.title}>
                 <img src={img + Config.imgmiddle + "linkedin.png?w=60"} />
                 <p className="u-thumb-cont-p">Linked In</p>
               </LinkedinShareButton>
 
             </div>
           </div>
         </Modal.Body>
 
       </Modal>
 
 
       <Modal show={wish} onHide={handleCloseWish}>
         <Modal.Header closeButton>
 
         </Modal.Header>
         <Modal.Body>
           {wishListData.length > 0 ?
             <span>Added from wishlist</span> :
             <span>Removed from wishlist</span>
           }
         </Modal.Body>
 
       </Modal>
       <Modal show={showDescription} onHide={handleDescription} className="modal title-des-popup" id="descriptionPopup" >
         <Modal.Header closeButton>
           <Modal.Title>Description</Modal.Title>
         </Modal.Header>
         <Modal.Body>
 
           <p style={{ whiteSpace: "pre-line" }}>{info.synopsis}</p>
 
         </Modal.Body>
 
 
       </Modal>
 
 
       {play ?
 
         <PlayerInfo source={playContent} play={play} setPlay={setPlay} proxyUrl={proxyUrl} subtitles={playerSubTitles} />
         : null
       }
       {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName}/>}
 
     </>
   );
 };
 
 export default MoreInfov1;
 