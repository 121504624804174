/***
**Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
import axios from "axios";
import Footer from "../components/footer/Footer";
import InsideHeader from "../components/InnerHeader/Header";
import Select from 'react-select';
import { contentContext } from "../context/contentContext";
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";
import SessionPopup from "./SessionPopup"
import InactivePopup from "./inactivePopup";

let { lambda, appname } = window.app;


const Preference = () => {
  const history = useHistory();

  // const [menus, setMenus] = useState([]);
  const [countryData, setCountryData] = useState({});
  const [genreData, setGenreData] = useState({});
  const [languagesData, setlanguagesData] = useState({});
  const [lookup, setLookUp] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedOptionscountry, setSelectedOptionscountry] = useState([]);
  const [selectedOptionsgenre, setSelectedOptionsgenre] = useState([]);
  const [preferencesFormData, setPreferencesFormData] = useState({});
  const [initialPreferencesData, setinitialPreferencesData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);

  const { Categories, categoryName1 } = useContext(contentContext)


  const [errors, setErrors] = useState({});
  const [searchPayload, setSearchPayload] = useState({});
  const [preference, setPreference] = useState({});
  const [data, setData] = useState({});
  const [IsDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [editFlag, setEditFlag] = useState(false);
  const [preferenceExist, setPreferenceExist] = useState(false); 
  const [addSucess, setAddSucess] = useState(false); 
  const [updateSucess, setUpdateSucess] = useState(false); 
  const { menus,inactiveFlag,setInactiveFlag, clientData,setContentSearchFlag,contentSearchFlag, setRoute } = useContext(contentContext);
  const [activeLoad, setActiveLoad] = useState(""); 


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }

    // appMenus();
    GetLookUp();
    Categories();
    getPreferencesFun();
    clientActivity();
    setContentSearchFlag(false)
    setRoute("notcontent")
  }, []);
  if(clientData.type==="SELLER"){
    history.push("/content")
  }
  // console.log('clientData', clientData)
  // const appMenus = async () => {
  //   try {
  //     console.log(tmdbApi);
  //     const response = await tmdbApi.appMenus({ result: "micro" });
  //     setMenus(response.result)
  //     console.log(response);
  //   } catch {
  //     console.log("error");
  //   }
  // };

  const getPreferencesFun = async (payload) => {
    try {

      setIsLoading(true)
      const response = await tmdbApi.getPreferences();
      if (response.statusCode === 200) {
        if (response.result == "Invalid token or Expired") {
          setShowSessionPopupup(true)
        }else if (response.result == "Client inactived") {
          setInactiveFlag(true)
  
        }else {
          console.log(response.result);
          let data = response.result && response.result && response.result ? response.result : {}
          // setinitialPreferencesData(data)
          // setPreferencesFormData(data)
          // setFormValues(data)

          console.log('data', data)
          setData(data);
          setIsLoading(false)
          if (data <= 0) {
            setPreference({ ...preference, category: [], country: [], languages: [], genre: [] });
          }
        }
      }
    } catch {
      console.log("error");
    }
  };


  const clientActivity = () => {
    let path = window.location.pathname.split("/");
    const pageName = path[path.length - 1];
    var presentTime = moment();
    let payload;
    
        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": 'PREFERENCES',
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };

    
    let urlLink;
    if(localStorage.getItem("previousid")){
        urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
    }else {
        urlLink= lambda + '/activity?appname=' + appname
    }

    axios({
        method: 'POST',
        url: urlLink,
        data: payload
    })
        .then(function (response) {
            if(response.data.statusCode === 200){
                localStorage.setItem("previousid",response.data.result)
            }
        });
}

  function onConfirm1() {
    setPreferenceExist(false);
};

  const prepareObject = (item) => {
    const resultArr = item.map((eachItem) => { return { value: eachItem, label: eachItem }; })
    return resultArr
  }


  const submitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    // console.log('111111111111111111111111111111111111111111111111111')
    if (isValid) {
    setActiveLoad("add")
      const clientid = localStorage.getItem("clientid")
      let payload =  {
        "category": preference?.category,
        "language": preference?.languages,
        "country": preference?.country,
        "genre": preference?.genre,
        "emailid": clientData && clientData?.personalemail
      }
      let filteredPayload = Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => {
          // Exclude properties with undefined values or empty arrays
          return value !== undefined && !Array.isArray(value) || (Array.isArray(value) && value.length > 0);
        })
      );
      axios({
        method: 'post',
        url: lambda + "/preferences?appname=" + appname + "&clientid=" + clientid + "&companyid=" + clientData.companyid,
        data: filteredPayload,
      })
        .then(function (response) {
          console.log(response)
          if (response.data.result == "Invalid token or Expired") {
            setShowSessionPopupup(true)
            setActiveLoad("")
          }else if (response.data.result == "Client inactived") {
            setInactiveFlag(true)
            setActiveLoad("")
           } else if (response.data.result === "preference already exists") {
            setPreferenceExist(true);
            setActiveLoad("")
          } else {

          if (response.data.statusCode === 200) {
              setAddSucess(true)
              getPreferencesFun();
              setActiveLoad("")
              setPreference({
                country: [],
                category: [],
                genre: [],
                languages: [],
              })
            }
          }

        });
    }
  }


  const validate = (e) => {
    let formIsValid = true;
    let error = { ...errors };
    let mandatoryFileds = [{ name: 'category', key: 'category' },
      //  {name:'Genre', key:'genre'}, {name:'Video format', key:'videoformat'}, {name:'Rights', key:'rights'}, {name:'Category', key:'category'}, {name:'Duration', key:'duration'}, {name:'Title', key:'title'}, {name:'Synopsis', key:'synopsis'}, {name:'Territories Available', key:'territoriesavailable'}, {name:'Number Of Seasons', key:'totalseasons'}, {name:'Number Of Episodes', key:'numberofepisodes'}
    ];

    if (mandatoryFileds) {
      mandatoryFileds.forEach(function (item) {

        if (
          (preference[item.key] == "" ||
            preference[item.key] == undefined ||
            preference[item.key] == "undefined")
        ) {
          error[item.key] = item.name + " is required";
          formIsValid = false;
        }
      });

    }
    console.log("errors", error);
    setErrors(error);
    return formIsValid;
  };



  const deletePreferenceFun = () => {
    axios({
      method: 'DELETE',
      url: lambda + '/preferences?appname=' + appname + "&clientid=" + localStorage.getItem("clientid") + "&preferenceid=" + deleteItem,
    })
      .then(function (response) {
        if (response.data.result == "Invalid token or Expired") {
          setShowSessionPopupup(true)
          setActiveLoad("")
        }else if (response.data.result == "Client inactived") {
          setInactiveFlag(true)
          setActiveLoad("")
         } else {
          setIsDelete(false)
          getPreferencesFun();
          setActiveLoad("")
          setErrors({});
         }
       

      }).catch((err) => {
        console.log(err);
        setActiveLoad("")
        setIsDelete(false)

    });
  }

  const returnArray = (arr) => {
    let arr2 = []
    arr.map((eachItem) => {
      arr2.push(eachItem.value)
    })

    return arr2
  }






  const handleChangeMultiSelect = (selected, key) => {
    if (!!errors[key]) {
      let error = Object.assign({}, errors);
      delete error[key];
      setErrors(error);
    }
    if (key == 'category') {
      if (!!errors['numberofepisodes']) {
        let error = Object.assign({}, errors);
        delete error['numberofepisodes'];
        setErrors(error);
      }
    }
    let selectedArray = returnArray(selected);
    searchPayload[key] = selectedArray
    setSearchPayload({ ...searchPayload });
    setPreference({ ...preference, [key]: selectedArray });
  }

  // console.log("preference", data);


  const GetLookUp = async (e) => {
    try {
      let arrayType = ["country", "genre", "language"];

      const response = await tmdbApi.getLookUp({
        type: arrayType,
        "sortBy": "name"

      });
      console.log(response);
      if (response.statusCode == 200) {
        let arrayType = ["country", "genre", "language"];
        let lookupsData = response.result || []
        arrayType.map((eachItem) => {
          let arr1 = []
          lookupsData.map((item1) => {
            if (item1.type == eachItem) {
              arr1.push(item1.name)
            }
          });
          lookup[eachItem] = arr1
          setLookUp({ ...lookup });
        })

        const countryObj = prepareObject(lookup.country)
        setCountryData(countryObj)
        const genreObj = prepareObject(lookup.genre)
        setGenreData(genreObj)
        const languagesObj = prepareObject(lookup.language)
        setlanguagesData(languagesObj)

      }

    } catch {
      console.log("error");
    }
  }
// console.log('preferencess-------> formvalues',preference)
  const handleChangeDelete = (e, id) => {
    console.log("item-----------", id)
    setIsDelete(true)
    // setPreference({category: [], country: [], languages: [], genre: [] });
    setDeleteItem(id)
    setEditFlag(false);
  }

  function onConfirm() {
    setActiveLoad("delete")
    setPreference({ category: [], country: [], languages: [], genre: [] });
    deletePreferenceFun(deleteItem)
   
  };
  function onCancel() {
    setIsDelete(false)
  }
  const handleBack = () => {
    history.push('/content')
  }
  const handleChangeEdit = (e, item) => {
    if (errors.category) {
      setErrors({ ...errors, category: "" })
    }
    console.log("item", item);
    let payload =  {
      "category": item?.category,
      "languages": item?.language,
      "country": item?.country,
      "genre": item?.genre,
      "preferenceid": item?.preferenceid
    }
    let filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => {
        // Exclude properties with undefined values or empty arrays
        return value !== undefined && !Array.isArray(value) || (Array.isArray(value) && value.length > 0);
      })
    );
    setPreference(filteredPayload);
    setEditFlag(true);
  }


  const updateForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      setActiveLoad('update')
      
      const clientid = localStorage.getItem("clientid")
      let payload =  {
        "category": preference?.category,
        "language": preference?.languages,
        "country": preference?.country,
        "genre": preference?.genre,
        "emailid": clientData && clientData?.personalemail
      }
      let filteredPayload = Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => {
          // Exclude properties with undefined values or empty arrays
          return value !== undefined && !Array.isArray(value) || (Array.isArray(value) && value.length > 0);
        })
      );
      axios({
        method: 'put',
        url: lambda + "/preferences?appname=" + appname + "&clientid=" + clientid + "&preferenceid=" + preference.preferenceid + "&companyid=" + clientData.companyid,
        data:filteredPayload,
      })
        .then(function (response) {
          console.log(response)
        
          if (response.data.statusCode === 200) {
            if (response.data.result == "Invalid token or Expired") {
              setShowSessionPopupup(true)
              setActiveLoad("")
            }else if (response.data.result == "Client inactived") {
              setInactiveFlag(true)
              setActiveLoad("")
             } else if (response.data.result === "preference already exists") {
              setPreferenceExist(true);
              setActiveLoad("")
           
            } else {
            
              setUpdateSucess(true)
              getPreferencesFun();
              setActiveLoad("")
              setPreference({
                country: [],
                category: [],
                genre: [],
                languages: [],
              })
              setEditFlag(false);
            }
          }

        });
    }
  }

  const handleClear = () => {
    setPreference({ ...preference, category: [], country: [], languages: [], genre: [] });
    setEditFlag(false);
  }

  // console.log('initialPreferencesData', initialPreferencesData)
  // console.log('preferencesFormData', preference)
  // console.log('formvalues', clientData.companyid)
  return (
    <>
      {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

      {/* {showSessionPopupup && <SessionPopup />} */}

      <Modal className="access-denied" show={IsDelete}>

        <div className="modal-body enquiry-form">
          <div className="container">
            <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
            <span className="material-icons access-denied-icon">delete_outline</span>
            <h3>Delete</h3>
            <p>This action cannot be undone.</p>
            <p>Are you sure you want to delete?</p>

            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>{   activeLoad === "delete" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null}Yes, Delete</button>

          </div>
        </div>

      </Modal>

      <InsideHeader activeClass={"8"} />

      <div className="inner-body preferences">
        <div className="body-wrapper">
          <div className="left-block adv_search">
            <div className="adv_header">
              <h6>
                <span className="material-symbols-outlined">search</span>
                Add Preference{" "}
              </h6>
            </div>
            <div className="adv_body">
              <div className="adv_body-inner">
                <div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label">category<span>*</span></label>
                  <Select isMulti={true}
                    // classNamePrefix="select category"
                    placeholder='Category'
                    onChange={(e) => handleChangeMultiSelect(e, 'category')}
                    isClearable={true}
                    options={categoryName1}
                    value={preference && preference.category && preference.category ? preference.category?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}
                  />
                  {errors.category && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.category}</span>}
                </div>


                <div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label">country</label>
                  <Select isMulti={true}
                    placeholder='Country'
                    onChange={(e) => handleChangeMultiSelect(e, 'country')}
                    isClearable={true}
                    options={countryData}
                    value={preference && preference.country && preference.country? preference.country?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}
                  />

                </div>
                <div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label">Languages</label>

                  <Select isMulti={true}
                    isClearable={true}
                    placeholder='Languages'
                    onChange={(e) => handleChangeMultiSelect(e, 'languages')}
                    options={languagesData}
                    value={preference && preference.languages && preference.languages ? preference.languages?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label"> Genre</label>
                  <Select isMulti={true}
                    placeholder='Genre'
                    onChange={(e) => handleChangeMultiSelect(e, 'genre')}
                    isClearable={true}
                    options={genreData}
                    value={preference && preference.genre && preference.genre ? preference.genre?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}
                  />
                </div>


              </div>
            </div>

            <div className="adv_footer">

              {editFlag === true ? <><button type="button" className="clear-btn line-btn" onClick={handleClear}>
                BACK</button><button className="fill_btn yellow-gradient float-end" onClick={updateForm}>
                {   activeLoad === "update" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null}  UPDATE
                </button></> : <><button type="button" className="clear-btn line-btn" onClick={handleClear}>
                  <span className="material-icons me-1">autorenew</span>reset</button><button className="fill_btn yellow-gradient float-end" onClick={submitForm}>
                  {   activeLoad === "add" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/>) : null} ADD
                </button></>}

              <div className="clearfix"></div>
            </div>
          </div>

          <div className="main-block">
            <div className="block-header">
              <div className="block-title">
                <h2>Preferences</h2>

              </div>
              <div className="body-actions">
                <button className="plain-btn" onClick={handleBack}><span className="material-icons-outlined">chevron_left
                </span>Back</button>
              </div>
            </div>

            <div className="block-body">

              {data && data.length > 0 ? (<>
                <p className="note-txt">If you set your preferences, you will be automatically notified via email
                  as new content that meets your specifications.</p>
                <div className="row">


                  <div className="card-block">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Preferences</th>
                          <th>Created On</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.length > 0 && data.map(function (item, i) {
                          // console.log(item.category)
                          return (


                            <tr>
                              <td>{item.category && typeof (item.category) != 'string' ? item.category && item.category.length > 1 ? item.category.join(', ') : item.category : ""}</td>
                              <td>
                                <div className="pref-badge">Language:<span>{item.language && typeof (item.language) != 'string' ? item.language && item.language.length > 1 ? item.language.join(', ') : item.language : ""}</span></div>
                               
                                <div className="pref-badge">Country:<span>{item.country && typeof (item.country) != 'string' ? item.country && item.country.length > 1 ? item.country.join(', ') : item.country : ""}</span></div>
                                <div className="pref-badge">Genre:<span>{item.genre && typeof (item.genre) != 'string' ? item.genre && item.genre.length > 1 ? item.genre.join(', ') : item.genre : ""}</span></div>
                              </td>
                            
                              <td> {item.created && moment(item.created).format("DD-MMM-YYYY")}</td>

                              <td>
                                <div style={{ display: "flex" }}>
                                  <button className="sm-btn action-btn" onClick={(e) => handleChangeEdit(e, item)}>
                                    <span className="material-symbols-outlined">
                                      edit
                                    </span>
                                  </button>
                                  <button className="sm-btn action-btn" onClick={(e) => handleChangeDelete(e, item.preferenceid)}>
                                    <span className="material-symbols-outlined"> delete </span>
                                  </button>

                                </div>
                              </td>
                            </tr>

                          )
                        })}
                      

                      </tbody>
                    </table>
                  </div>
                </div>
              </>) : isLoading ? (
                <div className="orasi-preloader">
                  <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                </div>
              ) : (<>
                <div className="comingsoon-wrapper">
                  <div className="comingsoon-block">
                    <img src="https://orasi-dev.imgix.net/orasi/common/images/preferences-graphic.png" />
                    <h6>No Preferences set!</h6>
                    {/* <p>Adding preferences help us to provide you with best content available.<br />Click 'Add' to set preferences.</p> */}
                    <p>If you set your preferences, you will be automatically notified via email<br />
                      as new content that meets your specifications.
                      {/* <br />Add from the preferences panel. */}
                    </p>
                  </div>
                </div>
              </>)}
            </div>
            <SweetAlert show={preferenceExist}
                custom
                confirmBtnText="Ok"
                confirmBtnBsStyle="primary"
                title={"Preference already exists"}
                onConfirm={e => onConfirm1()}
            >
            </SweetAlert>
            <SweetAlert show={addSucess}
                custom
                confirmBtnText="Ok"
                confirmBtnBsStyle="primary"
                title={"Added Successfully"}
                onConfirm={e =>setAddSucess(false)}
            >
            </SweetAlert>
            <SweetAlert show={updateSucess}
                custom
                confirmBtnText="Ok"
                confirmBtnBsStyle="primary"
                title={"Updated Successfully"}
                onConfirm={e =>setUpdateSucess(false)}
            >
            </SweetAlert>
            <Footer menus={menus} />
          </div>
        </div>
      </div>


    </>
  );
};

export default Preference;
