/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../../api/tmdbApi";
import { useHistory } from "react-router";
import * as Config from "../../constants/Config";
import Footer from "../../components/footer/Footer";
import { useParams } from 'react-router-dom';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { contentContext } from "../../context/contentContext";
import moment from "moment";
import SessionPopup from "../SessionPopup"
import FileViewer from "../docViewer";
import Slider from "react-slick";
import Loader from "../../components/loader";

import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from 'react-share';
import PlayerInfo from "../player";
import { InputGroup } from "react-bootstrap";
let { lambda, appname } = window.app;


const AuctionMoreInfo = () => {
    let { id } = useParams();
    const [config, setConfig] = useState({});
    const [info, setInfo] = useState({});
    const [clipsData, setClipsData] = useState([]);
    const [image, setImg] = useState('');
    const history = useHistory();
    const [type, setType] = useState(false);
    const [show, setShow] = useState(false);
    const [playerSubTitles, setPlayerSubTitles] = useState('');

    const [toggle, setToggle] = useState(false);
    const [ShowErrMsg, setShowErrMsg] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showDoc, setshowDoc] = useState(false);

    const [showSessionPopupup, setShowSessionPopupup] = useState(false);


    const [play, setPlay] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const [playContent, setPlayContent] = useState({});
    const [configFlag, setConfigFlag] = useState(true);

    const {clientData, GetClientDataFunction, menus,setActiveTab,usePrevious,route, setRoute } = useContext(contentContext);


    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/login");
        }
        localStorage.removeItem("check");
        setRoute("title")
        // getWishlistFun();
        getAuctionMoreInfo();
        if (localStorage.getItem("clientid") != null) {
            GetClientDataFunction();
            clientActivity();
        }

    }, []);
    useEffect(() => {
        if (window.site) {
            setConfigFlag(false)
            setConfig(window.site);
        }
        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.resourcesUrl) {
                    setImg(window.site.common.resourcesUrl)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.resourcesUrl) {
            setImg(window.site.common.resourcesUrl)
        }
    }, [window.site]);

 


    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }

    if (config.common && config.common.proxiesCloudFront) {
        var proxyUrl = config.common.proxiesCloudFront
    }

    const toggleClass = () => {
        setToggle(!toggle);
    };




    const getAuctionMoreInfo = (e) => {
        console.log(localStorage.getItem("url"))
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        let url = ""
        if (clientId != null && token != null) {
            if (localStorage.getItem("url")) {
                url = lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&clientid=" + clientId + "&token=" + token
            } else {
                url = lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&clientid=" + clientId + "&token=" + token
            }
        }

        axios({
            method: 'GET',
            url: url
        })
            .then(function (response) {
                console.log("resssssssss", response);
              
                if(response.data.result == "Invalid token or Expired"){
                    setShowSessionPopupup(true)
                }

                else if (response.data.result != "No Titles Data") {
                    console.log("came")
                    setInfo(response.data.result[0]);
                    let clipsData1 = response.data && response.data.result && response.data.result[0] && response.data.result[0].clips && response.data.result[0].clips ? response.data.result[0].clips : []
                    setClipsData(clipsData1)
                }
                else {
                    console.log('no content data')
                    setShowErrMsg(true)
                }
            });
    }
    // console.log('clientData', clientData)
    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 2];
        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": "ITEM INFO",
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }



    // console.log('cliepss s data',clipsData)
 
    let description = info && info.synopsis || "";
    const truncatedDescription = description.slice(0, 370);

    const handleDescription = () => {
        setShowDescription(!showDescription)
    }
 

    const openFileViewer = (e, content) => {
        let source = window.site.common.resourcesUrl + "/" + content.sourcepath
        setPlayContent(source);
        setType(content.mimetype);
        setshowDoc(true);
        console.log("playercontent==========>", content.sourcepath);
    }


    let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;   
    let imgUrl = info && info.itemimage ? img + info.itemimage : defaultImg

    const handleCloseErr = () => {
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        console.log('close')
        if (clientId != null && token != null) {
            history.go(-1)
        } else {
            history.push('/')
        }
        setShowErrMsg(false)
    }

    const cardWidth = $(".title-thumb").width();

    const settings = {
        centerMode: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
              breakpoint: 1450,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
     
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        

    };
    const documentsettings = {
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll:10,
        responsive: [
          
          
            {
              breakpoint: 1450,
              settings: {
                slidesToShow: 9,
                slidesToScroll: 9,
     
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]

    };
    console.log("prevRoute",prevRoute);
    const handleCloseClick = (e) => {
        setActiveTab("title")
            history.push("/auction")  

    }


    const handlePlayer = (e, content) => {
        console.log("content", content)
        if (content.filetype === "VIDEO" && content?.video?.m3u8?.proxyUrl) {
            let source = window.site.common.proxiesCloudFront + content?.video?.m3u8?.proxyUrl;
            console.log("source", source)
            setPlayContent(content);
            setPlay(true);
            console.log("playercontent", content);
        }
    }
    const handleBids = (e) => {
        history.push('/bids/' + id)
    }

    console.log("info", ShowErrMsg);
    return (
        <>
            <Modal className="access-denied" show={ShowErrMsg}>

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={(e) => handleCloseErr(e)}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">error</span>
                        <h3>Content Not Found</h3>

                      
                    </div>
                </div>

            </Modal>

            {showSessionPopupup && <SessionPopup />}
            <>


                {!ShowErrMsg &&
                    <>
                        {configFlag ? <Loader /> :
                            <div className="asset-container auction_details">

                                <div className="asset-container-header"></div>

                                {/* <button className="close-btn" onClick={(e) => handleClose(e, info)}><span className="material-icons" >close</span></button> */}

                                <><div className="asset-details-wrapper">
                                    <div className="row asset-data-row">
                                        <div className="col-md-8">
                                            <div className="asset-metadata">
                                                <div className="title-wrapper">
                                                    <h1 className="meta-title">{info.title}</h1>
                                                    <button className="close-btn"><span className="material-icons" onClick={(e) => handleCloseClick(e)}>close</span></button>
                                                </div>
                                                <div className="detail_section">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <h3 className="sub_title">AVAILABlLITY</h3>
                                                            <p>{info.availability}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <h3 className="sub_title">BID START DATE </h3>
                                                            <p>{moment(info && info.bidstartdate).format("DD-MMM-YYYY")}</p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="detail_section">
                                                    <h3 className="sub_title">DETAILS</h3>
                                                    <p> {ReactHtmlParser(truncatedDescription)}
                                                        {/* {description && description.substring(0, 370) + "..."}*/}
                                                        
                                                    </p>
                                                    {description && description.length > 370 ? (<button className="more-popup-btn" data-bs-toggle="modal" data-bs-target="#descriptionPopup" onClick={handleDescription}>more</button>) : null}
                                                </div>
                                                <div className="thumbnail_section">
                                                    {clipsData && clipsData.VIDEOS && clipsData.VIDEOS.length > 0 &&

                                                        <div className="detail_section">
                                                            <h3 className="sub_title">VIDEOS</h3>

                                                            {clipsData && clipsData.VIDEOS && clipsData.VIDEOS.length > 5 ?
                                                                <div className="video_block">
                                                                    <Slider {...settings}>

                                                                        {clipsData && clipsData.VIDEOS && clipsData.VIDEOS.length > 0 && clipsData.VIDEOS?.map((eachItem, key) => {

                                                                            
                                                                            return (

                                                                                <div key={key} className="item" onClick={(e) => handlePlayer(e, eachItem)}>
                                                                                    <div className=" overlay"><span className="material-icons-outlined play">
                                                                                        play_circle_filled
                                                                                    </span></div>
                                                                                    <img src={image + info.itemimage} alt="" />
                                                                                </div>
                                                                            )

                                                                        })}


                                                                    </Slider>
                                                                </div>
                                                                : <div className="video_block">

                                                                    {clipsData && clipsData.VIDEOS && clipsData.VIDEOS.length > 0 && clipsData.VIDEOS?.map((eachItem, key) => {

                                                                        console.log("key", key);
                                                                        return (

                                                                            <div className="item" onClick={(e) => handlePlayer(e, eachItem)}>
                                                                                <div className=" overlay"><span className="material-icons-outlined play">
                                                                                    play_circle_filled
                                                                                </span></div>
                                                                                <img src={image + info.itemimage} alt="" />
                                                                            </div>
                                                                        )

                                                                    })}


                                                                </div>}




                                                        </div>
                                                    }
                                                    {clipsData && clipsData.THUMBNAILS && clipsData.THUMBNAILS.length > 0 &&
                                                        <div className="detail_section">
                                                            <h3 className="sub_title">IMAGES</h3>
                                                            {clipsData && clipsData.THUMBNAILS && clipsData.THUMBNAILS.length > 4 ?
                                                                <div className="image_block">
                                                                    <Slider {...settings}>
                                                                        {clipsData && clipsData.THUMBNAILS && clipsData.THUMBNAILS.length > 0 && clipsData.THUMBNAILS?.map((eachItem, key) => {
                                                                            console.log(key, eachItem);

                                                                            return (




                                                                                <div className="item" onClick={(e) => openFileViewer(e, eachItem)}>
                                                                                    <img src={image + eachItem.sourcepath} alt="" />
                                                                                </div>




                                                                            )

                                                                        })}
                                                                    </Slider>
                                                                </div> :
                                                                <div className="image_block">
                                                                    {clipsData && clipsData.THUMBNAILS && clipsData.THUMBNAILS.length > 0 && clipsData.THUMBNAILS?.map((eachItem, key) => {

                                                                        return (
                                                                            <div className="item" onClick={(e) => openFileViewer(e, eachItem)}>
                                                                                <img src={image + eachItem.sourcepath} alt="" />
                                                                            </div>
                                                                        )

                                                                    })}
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {clipsData && clipsData.DOCUMENTS && clipsData.DOCUMENTS.length > 0 &&
                                                        <div className="detail_section">
                                                            <h3 className="sub_title">DOCUMENTS</h3>
                                                            {clipsData && clipsData.DOCUMENTS && clipsData.DOCUMENTS.length > 10 ?
                                                                <div className="document_block">
                                                                    <Slider {...documentsettings}>
                                                                        {clipsData && clipsData.DOCUMENTS && clipsData.DOCUMENTS.length > 0 && clipsData.DOCUMENTS?.map((eachItem, key) => {

                                                                            return (
                                                                                <div className="item" onClick={(e) => openFileViewer(e, eachItem)}>
                                                                                  <div className="doc">  <img src={image + "orasi/admin/resources/orasiv1/images/pdf.png?auto=compress,format"} alt="" /></div>
                                                                                </div>
                                                                            )

                                                                        })}

                                                                    </Slider>
                                                                </div>
                                                                : <div className="document_block">

                                                                    {clipsData && clipsData.DOCUMENTS && clipsData.DOCUMENTS.length > 0 && clipsData.DOCUMENTS?.map((eachItem, key) => {

                                                                        return (
                                                                            <div className="item" onClick={(e) => openFileViewer(e, eachItem)}>
                                                                             <div className="doc">   <img src={image + "orasi/admin/resources/orasiv1/images/pdf.png?auto=compress,format"} alt="" /></div>
                                                                            </div>
                                                                        )

                                                                    })}

                                                                </div>}
                                                        </div>
                                                    }
                                                </div>

                                            </div>


                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-3">

                                            <div className="title-thumb-wrapper">
                                                <div className="title-thumb">

                                                    <img src={imgUrl.toString() + "?auto=compress,format&width=" + cardWidth} alt="thumb" />
                                                </div>
                                                {info.status == "ACTIVE" &&
                                                    <ul className="title-thumb-buttons mt-4">
                                                        <button className="fill_btn yellow-gradient me-2" onClick={handleBids}>BID NOW</button>
                                                    </ul>}

                                            </div>

                                        </div>
                                    </div>

                                </div><div className="blur-bg">
                                        {/* <div className="black-overlay"></div> */}
                                        <img src={imgUrl.toString()} alt="thumb" />
                                    </div></>

                                <Footer menus={menus} moreInfoCls={"fixed-footer"} />
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={3000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover={false}
                                    theme="light"
                                />
                            </div>
                        }
                    </>

                }
            </>

            
            <Modal show={showDescription} onHide={handleDescription} className="modal title-des-popup" id="descriptionPopup" >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p style={{ whiteSpace: "pre-line" }}>{ReactHtmlParser(description)} </p>

                </Modal.Body>


            </Modal>


            {play ?

                <PlayerInfo source={playContent} play={play} setPlay={setPlay} proxyUrl={proxyUrl} subtitles={playerSubTitles} />
                : null
            }
            {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} />}

        </>
    );
};

export default AuctionMoreInfo;
