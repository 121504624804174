/***
**Module Name: bidsummary
 **File Name :  bidsummary.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Pujitha
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: sep 01 2023
 **Last modified sep 01 2023
 **Description : contains bid details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import Footer from "../../components/footer/Footer";
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import SessionPopup from "../SessionPopup"
import { contentContext } from "../../context/contentContext";
import axios from "axios";
import Loader from "../../components/loader";
import { ToastContainer, toast } from 'react-toastify';

let { lambda, appname } = window.app;


const ViewBid = () => {
    let { id, isgroup } = useParams();
    const [config, setConfig] = useState({});
    const [info, setInfo] = useState({});
    const [grouphistroy, setGroupHistory] = useState({});
    const [heading, setHeading] = useState({});
    const [ShowErrMsg, setShowErrMsg] = useState(false);
    const [updatedbidprice, setbidprice] = useState(null);
    const [combindex, setIndex] = useState([]);
    const [total, setTotal] = useState(0);
    const [bidtotal, setBidTotal] = useState(0);
    const history = useHistory();
    const [withdrawData, setWithdrawData] = useState({});
    const [withdrawFlag, setWithdrawFlag] = useState(false);
    const [combbidpriceerror, setCombBidPriceError] = useState("");
    const [bidpriceerror, setBidPriceError] = useState("");
    const [totalMinBidPrice, setTotalMinBidPrice] = useState(0);
    const [configFlag, setConfigFlag] = useState(true);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [SubmitBtnLoader, setSubmitBtnLoader] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [nonactiveitems, setNonActiveItems] = useState([]);
    const [activeItems, setActiveItems] = useState([]);
    const [withDrawObj, setWithDrawObj] = useState([]);
    const [totalBidResult, setTotalBidResult] = useState([]);
    const [bidData, setBidData] = useState({});
    const [showFlag, setShowFlag] = useState(false);
    const [withdrawupdate, setWithdrawUpdate] = useState(false);
    const [viewtotal, setViewTotal] = useState(0);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const { menus, setActiveTab } = useContext(contentContext);

    useEffect(() => {
        setGroupHistory(JSON.parse(localStorage.getItem("groupbid")))
        getAuctionMoreInfo();
        clientActivity();
        if (isgroup === "true") {
            getGroupData();
        }
    }, []);
    useEffect(() => {
        if (window.site) {
            setConfigFlag(false)
            setConfig(window.site);
        }

    }, [window.site]);

    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }
    const AddNotify = () => toast.success('Bid Updated Successfully', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)
    const WithdrawNotify = () => toast.success('Bid Withdraw Successfully', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)
    const getAuctionMoreInfo = () => {
        setConfigFlag(true);
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        let url;
        if (isgroup === "true") {
            url = lambda + '/getbidscobmination?appname=' + appname + "&clientbidid=" + id
        } else {
            url = lambda + '/getbids?appname=' + appname + "&clientid=" + clientId + "&token=" + token + "&itemcombinationid=" + id
        }

        axios({
            method: 'GET',
            url: url
        })
            .then(function (response) {
                console.log(response)
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }
                else if (response.data.result != "No Content Data") {
                    console.log("response.data.result.data", response.data.result.data)
                    setHeading(response.data.result.data[0])
                    setTotalBidResult(response.data.result.data)

                    setConfigFlag(false);
                    let activeItems = [];
                    let nonActiveItems = [];
                    response.data.result.data.forEach(item => {
                        if (item.status === "ACTIVE" || item.status === "OUT BIDDED") {
                            activeItems.push(item);
                        } else {
                            nonActiveItems.push(item);
                        }
                    });


                    const updatedData = activeItems?.map(obj => ({
                        ...obj,
                        newbidprice: obj.bidprice,

                    }));

                    setNonActiveItems(nonActiveItems)
                    setActiveItems(activeItems)
                    const sumOfMinBidPrices = activeItems?.reduce((total, obj) => {
                        return total + (obj.minbidprice || 0); // Use 0 if minbidprice is missing or undefined
                    }, 0);
                    setTotalMinBidPrice(sumOfMinBidPrices);
                    const sumOfPrices = activeItems?.reduce((total, obj) => {
                        return total + (obj.bidprice || 0); // Use 0 if minbidprice is missing or undefined
                    }, 0);
                    setTotal(parseInt(sumOfPrices));
                    setBidTotal(sumOfPrices)

                    setInfo(updatedData);
                    setBidData(activeItems[0]?.period)
                    //handleCheckTotal();

                }
                else {
                    setShowErrMsg(true)
                }
            });
    }

    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 2];
        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": "REBID",
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }

    const getGroupData = () => {

        axios({
            method: 'GET',
            url: lambda + '/getbids?appname=' + appname + "&clientbidid=" + id
        })
            .then(function (response) {
                setGroupData(response.data.result.data[0])

            });
    }
    const handleCloseClick = (e) => {
        setActiveTab("bids")
        localStorage.removeItem("groupbid");
        history.go(-1);
    }
    console.log("infooo--->", info)
    const handleChange = (e, key, index) => {
        let updatedObject = [...info]
        if (key === "bidprice") {
            updatedObject[index]["newbidprice"] = parseInt(e.target.value);
        } else if (key === "period") {
            if (e.target.value === "" || e.target.value >= 1) {
                updatedObject[index]["period"] = parseInt(e.target.value)
            }
        } else if (key === "totalperiod") {
            if (e.target.value === "" || e.target.value >= 1) {
                setBidData(parseInt(e.target.value))
            }


            // console.log("period--->", e.target.value)

        }
        setInfo(updatedObject)

    }
    const handleKeyDown = (event) => {
        // Prevent the minus sign from being entered
        if (event.key === '-' || event.key === '–') {
            event.preventDefault();
        }
    };
    const validateCondition = () => {
        let isValid = true;
        let hasZeroOrEmptyMinBidPrice = false;
        for (let item of info) {
            if (item.newbidprice === 0) {
                hasZeroOrEmptyMinBidPrice = true;
                break;
            }
        }

        if (hasZeroOrEmptyMinBidPrice) {
            setBidPriceError("Please Enter Non zero value")
            isValid = false;
        }
        if (isgroup === "true") {
            if (isNaN(total)) {
                setBidPriceError("Please Enter bid price more than last bid price")
                isValid = false;
            }
            console.log("excuted")
            console.log("total", total, "bidtotal", bidtotal, bidtotal < total)
            if (bidtotal >= total) {
                setBidPriceError("Please Enter bid price more than last bid price")
                isValid = false;
            }
        }
        if (info.length <= 1 && total <= info[0].bidprice) {
            console.log("first if")
            setBidPriceError("Please Enter bid price more than last bid price")
            isValid = false;
        }


        return isValid;
    }

    const getAuctionMoreInfo2 = () => {
        // setConfigFlag(true);
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        let url;
        if (isgroup === "true") {
            url = lambda + '/getbidscobmination?appname=' + appname + "&clientbidid=" + id
        } else {
            url = lambda + '/getbids?appname=' + appname + "&clientid=" + clientId + "&token=" + token + "&itemcombinationid=" + id
        }

        axios({
            method: 'GET',
            url: url
        })
            .then(function (response) {
                console.log(response)
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    //setConfigFlag(false);
                }
                else if (response.data.result != "No Content Data") {
                    console.log("response.data.result.data", response.data.result.data)
                    setHeading(response.data.result.data[0])
                    setTotalBidResult(response.data.result.data)
                    let activeItems = [];
                    let nonActiveItems = [];
                    response.data.result.data.forEach(item => {
                        if (item.status === "ACTIVE" || item.status === "OUT BIDDED") {
                            activeItems.push(item);
                        } else {
                            nonActiveItems.push(item);
                        }
                    });

                    const updatedData = activeItems?.map(obj => ({
                        ...obj,
                        newbidprice: obj.bidprice,
                    }));


                    setInfo(updatedData);
                    const sumOfPrices = activeItems?.reduce((total, obj) => {
                        return total + (obj.bidprice || 0); // Use 0 if minbidprice is missing or undefined
                    }, 0);
                    setBidTotal(sumOfPrices)
                    //handleCheckTotal();
                    // setConfigFlag(false);
                    setSubmitBtnLoader(false)
                }
                else {
                    setShowErrMsg(true)
                }
            });
    }
    console.log("groupdata", groupData)
    const bidUpdate = (e) => {
        e.preventDefault();
        if (!isButtonDisabled) {
            setButtonDisabled(true);
            const isValid = validateCondition();
            console.log("isValid", isValid)
            if (isValid) {

                let payload;
                console.log("totalBidResult", totalBidResult)
                if (totalBidResult.length <= 1 && totalBidResult[0].isgroup === false) {
                    console.log("groupData", groupData)
                    payload = [{
                        "clientbidid": info[0].clientbidid,
                        "isgroup": false,
                        "bidprice": info[0].newbidprice,
                        "minbidprice": info[0].minbidprice,
                        "period": info[0].period,
                        "itemcombinationid": info[0].itemcombinationid,
                    }]
                } else {
                    var newArray = info.map(function (oldObj) {
                        // Select specific key-value pairs from the old object
                        var { clientbidid, itemcombinationid, minbidprice, period } = oldObj;

                        // Add new key-value pairs to the new object
                        var bidprice = oldObj.newbidprice;


                        return { bidprice, clientbidid, itemcombinationid, minbidprice, period };
                    });

                    payload = [{
                        "clientbidid": id,
                        "isgroup": true,
                        "bidprice": total,
                        "minbidprice": groupData.minbidprice,
                        "period": info[0].period,
                        "bids": newArray
                    }]

                }
                console.log("payload", payload);
                let checkcondition = false;
                info.forEach(async (item) => {
                    console.log("item", item)
                    if ((item.newbidprice <= item.bidprice) || item.newbidprice === undefined || isNaN(item.newbidprice)) {
                        console.log("validated")
                        checkcondition = true;
                    }

                });
                if (checkcondition) {
                    setBidPriceError("Please Enter bid price more than minimum bid price for all combinations");
                    setButtonDisabled(false);
                } else {
                    setSubmitBtnLoader(true)
                    const clientId = localStorage.getItem("clientid");
                    let urlLink = lambda + '/managebid?appname=' + appname + "&activity=update&clientid=" + clientId
                    axios({
                        method: 'POST',
                        url: urlLink,
                        data: payload
                    })
                        .then(function (response) {
                            if (response.data.statusCode === 200) {
                                if (response.data.result == "Invalid token or Expired") {
                                    setShowSessionPopupup(true)
                                } else {
                                    getAuctionMoreInfo2()
                                    AddNotify()

                                    if (isgroup === "true") {
                                        getGroupData();
                                    }

                                }
                            }
                            setBtnLoader(false)
                        });
                }
                // setTimeout(() => {
                setButtonDisabled(false);
                // }, 5000);
            }
            setButtonDisabled(false);
        }
    }
    const handleChangePrice = (e) => {
        setBidPriceError("")
        setTotal(parseInt(e.target.value))
    }
    console.log("isButtonDisabled", isButtonDisabled)
    const handleCheckBidPrice = () => {
        const updatedData = info.map(obj => ({
            ...obj,
            newbidprice: Math.round((obj.minbidprice / totalMinBidPrice) * total),
            // You can update other keys as well if needed
        }));
        console.log("updatedData", updatedData);

        const sumOfMinBidPrices = updatedData.reduce((total, obj) => {
            return total + (obj.newbidprice || 0); // Use 0 if minbidprice is missing or undefined
        }, 0);
        console.log("sumOfMinBidPrices", sumOfMinBidPrices);
        let cummulative = total - sumOfMinBidPrices;
        console.log("cummulative", cummulative, "total", total, "sum", sumOfMinBidPrices);
        if (cummulative > 0) {
            console.log("cummulative +")
            const newArray = [...updatedData];
            const lastObject = newArray[newArray.length - 1];
            console.log("lastObject", lastObject);
            lastObject["newbidprice"] += cummulative;
            console.log("newArray1", newArray)
            console.log("lastObject after", lastObject);
            setInfo(newArray);
            const sumOfMinBidPrices = newArray.reduce((total, obj) => {
                return total + (obj.newbidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);
            console.log("sumOfMinBidPrices c+", sumOfMinBidPrices)
        }
        else if (cummulative < 0) {
            console.log("cummulative -")
            const newArray = [...updatedData];
            const lastObject = newArray[newArray.length - 1];
            lastObject["newbidprice"] -= Math.abs(cummulative);
            console.log("newArray2", newArray)
            setInfo(newArray);
            const sumOfMinBidPrices = newArray.reduce((total, obj) => {
                return total + (obj.newbidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);
            console.log("sumOfMinBidPrices c-", sumOfMinBidPrices)
        } else {
            setInfo(updatedData);
            const sumOfMinBidPrices = updatedData.reduce((total, obj) => {
                return total + (obj.newbidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);
            console.log("sumOfMinBidPrices c=0", sumOfMinBidPrices)
        }
        //setInfo(updatedData);
    }
    const handleCalculateTotal = (e, index) => {
        setBidPriceError("")
        if ((info[index].minbidprice) > (info[index].newbidprice)) {
            combindex.push(index)
            setCombBidPriceError("Please Enter bid price more than min bid price")
        } else {
            if (combindex.includes(index)) {
                const newArray = combindex.filter(item => item !== index);
                setIndex(newArray)
            }

        }
    }

    const
        handleCheckTotal = (e) => {
            const sumOfMinBidPrices = info?.reduce((total, obj) => {
                return total + (obj.newbidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);
            setTotal(sumOfMinBidPrices)
            
         }
        const
        handleCheckPeriod = (e) => {
         
             let updatedData = info.map(obj => ({
                    ...obj,
                    period: bidData // Update period to bidData for each object
                    // You can update other keys as well if needed
                }));
              setInfo(updatedData)
        }
    const handleShow = (e, id, index, total) => {
        setWithDrawObj([])
        setBidPriceError("")
        setShowFlag(true)
        console.log("id", id, index)
        const updatedData = totalBidResult.map(obj => ({
            ...obj,
            newbidprice: obj.bidinfo[index]?.bidprice,
            // You can update other keys as well if needed
        }));

        const filteredArray = updatedData && updatedData?.filter((obj) => obj.newbidprice !== undefined);
        setInfo(filteredArray);
        setViewTotal(total)
    }
    const handleClear = (e) => {
        getAuctionMoreInfo();
        setShowFlag(false)
        handleCheckBidPrice();
    }
    const bidWithDraw = (e, info) => {
        setBidPriceError("")
        setWithdrawData(info);
        setWithdrawFlag(true)
    }
    const handleCloseErr = () => {
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        console.log('close')
        if (clientId != null && token != null) {
            history.go(-1)
        } else {
            history.push('/')
        }
        setShowErrMsg(false)
    }
    const handleWithdraw = () => {
        setBtnLoader(true)
        const clientId = localStorage.getItem("clientid");
        let urlLink;
        if (withDrawObj && withDrawObj.length > 0) {
            urlLink = lambda + '/withdraw?appname=' + appname + "&clientid=" + clientId + "&status=WITHDRAWN&isgroup=" + withdrawData.isgroup
        } else {
            urlLink = lambda + '/withdraw?appname=' + appname + "&clientbidid=" + withdrawData.clientbidid + "&clientid=" + clientId + "&status=WITHDRAWN&isgroup=" + withdrawData.isgroup
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: withDrawObj,
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {

                        setWithdrawFlag(false)



                        getAuctionMoreInfo();
                        getGroupData();
                        handleCheckTotal()

                        if (withDrawObj && withDrawObj.length > 0) {
                            setWithDrawObj([])
                        }
                        setWithdrawUpdate(true);
                        setBtnLoader(false)
                    }
                }
                setBtnLoader(false)
            });
    }
    const handleSelectAll = (e, item) => {

        console.log('checked', e.target.checked)

        if (e.target.checked == true) {

            let arr = []
            let dummyCategoryData = info
            // arr = arr.map((each)=>{

            dummyCategoryData && dummyCategoryData.length > 0 && dummyCategoryData.map((item, i) => {

                const newObj = {
                    "clientbidid": item.clientbidid
                }
                arr.push(newObj)
                setWithDrawObj(arr);
                //    }

            })


            // })

        }
        else {

            setWithDrawObj([])
        }

    }
    const handleChangeCheckItem = (e, item) => {

        console.log('checked', e.target.checked)
        const clientid = localStorage.getItem("clientid")

        if (e.target.checked == true) {

            let arr = [...withDrawObj]

            const newObj = {
                "clientbidid": item.clientbidid
            }
            arr.push(newObj)
            setWithDrawObj(arr)
        }
        else {
            let newArr = [...withDrawObj]
            let resultArray = newArr.filter((eachitem) => eachitem.clientbidid !== item.clientbidid)
            setWithDrawObj(resultArray)
        }
    }
    const removeError = (e) => {
        setBidPriceError("")
    }
    let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;
    let imgUrl = heading && heading.itemimage ? img + heading.itemimage : defaultImg
    console.log("activeItems", info)

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <Modal className="access-denied" show={ShowErrMsg}>

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={(e) => handleCloseErr(e)}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">error</span>
                        <h3>Content Not Found</h3>



                    </div>
                </div>

            </Modal>
            <Modal className="access-denied" show={withdrawFlag} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => (setWithdrawFlag(false), setBtnLoader(false))}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon"> input </span>
                        <h3>Withdraw Confirmation</h3>
                        <p>This action cannot be undone. Do you want to Proceed?</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={handleWithdraw}>{BtnLoader ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Withdraw</button>
                    </div>
                </div>

            </Modal>
            <Modal className="access-denied" show={withdrawupdate} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => (setWithdrawUpdate(false))}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon"> input </span>
                        <p>Bid WithDrawn Successfully</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={(e) => (setWithdrawUpdate(false))}>OKAY</button>
                    </div>
                </div>

            </Modal>
            {configFlag ? <Loader /> :
                <div className="asset-container view_bid">

                    <div className="asset-container-header">
                        <div className="view_asset">
                            <div className="img">
                                <img src={imgUrl.toString()} alt="" />
                            </div>
                            <div className="content">
                                <h2>{heading.title}</h2>
                                <div className="tag">{isgroup === "false" ? heading.status : groupData.status}</div>
                                <h3>CURRENT BID VALUE : &nbsp; &nbsp;$&nbsp;{isgroup === "false" ? heading.bidprice : groupData.bidprice}</h3>
                            </div>
                        </div>
                        <button className="close-btn" onClick={(e) => handleCloseClick(e)}><span className="material-icons">close</span></button>
                    </div>
                    <div className="asset-details-wrapper">
                        <div className="main-block">

                            <div className="row">
                                <div className="col-md-9 left">
                                    {info && info.length > 0 &&
                                        <><h3>COUNTRY LEVEL BREAKUP</h3><div className="table_block">
                                            <table className="table table_bid table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{showFlag === false && <input type="checkbox" checked={withDrawObj.length === info.length} onChange={(e) => handleSelectAll(e)} />}</th>
                                                        <th width="5%">#</th>
                                                        <th width="20%">COUNTRY</th>
                                                        <th width="10%">REGION</th>
                                                        <th width="15%">TYPES OF RIGHTS</th>
                                                        <th width="14%" className="text-center">REVENUE TYPE</th>
                                                        <th width="12%" className="text-center"> EXCLUSIVITY</th>
                                                        <th width="7%" className="text-center">Period<span className="sub">( IN YEARS )</span></th>
                                                        <th width="7%" className="text-center">Min bid price <span className="sub">( USD )</span></th>
                                                        <th width="7%" className="text-center">last Bid price <span className="sub">( USD )</span></th>
                                                        <th width="17%" className="text-center">  BID PRICE
                                                            <span className="sub">( USD )</span> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {info && info.length > 0 && info.map((item, i) => {
                                                        let filterList = withDrawObj.filter((each) => each.clientbidid === item.clientbidid)
                                                        let isCheck = filterList && filterList[0] && filterList[0].clientbidid ? true : false
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {showFlag === false && <input type="checkbox" id={item.clientbidid} checked={isCheck} onChange={(e) => handleChangeCheckItem(e, item)} />}
                                                                </td>
                                                                <td>{i + 1}</td>
                                                                <td>{item.country}</td>
                                                                <td>{item.region && typeof (item.region) != 'string' ? item.region?.join(", ") : item && item.region}</td>
                                                                <td>{item.typesofrights}</td>
                                                                <td className="text-center">{item.revenuetype}</td>
                                                                <td className="text-center">{item.exclusivity}</td>

                                                                {info.length <= 1 ? <td className={showFlag === true && "text-end"}>
                                                                    {(activeItems && activeItems.length <= 0) || (showFlag === true) ? item.newbidprice :
                                                                        <div className="bid_field">

                                                                            <input type="number" className="form-control" min="1"
                                                                                // onChange={(e) => setbidprice(e.target.value)}
                                                                                onChange={(e) => (handleChange(e, "period", i)
                                                                                    // , handleCalculateTotal(e, i)
                                                                                )}
                                                                                value={item?.period} onFocus={(e) => removeError(e)} onKeyDown={handleKeyDown} />
                                                                            {/* <button className="btn"><span className="material-icons"> done </span></button> */}
                                                                        </div>}

                                                                    {/* {combindex.includes(i) && <span className="errormsg bidvalue" style={{ fontWeight: 'bold', color: 'red' }}>{combbidpriceerror}</span>} */}

                                                                </td> : <td className="text-center">{item.period}</td>}

                                                                <td className="text-center">{item.minbidprice}</td>
                                                                <td className="text-center">{item.bidprice}</td>
                                                                <td className={showFlag === true && "text-end"}>
                                                                    {(activeItems && activeItems.length <= 0) || (showFlag === true) ? item.newbidprice :
                                                                        <div className="bid_field">

                                                                            <input type="text" className="form-control"
                                                                                // onChange={(e) => setbidprice(e.target.value)}
                                                                                onChange={(e) => (handleChange(e, "bidprice", i)
                                                                                    // , handleCalculateTotal(e, i)
                                                                                )}
                                                                                value={item.newbidprice ? item.newbidprice : 0} onBlur={(e) => handleCheckTotal(e)} onFocus={(e) => removeError(e)} onKeyDown={handleKeyDown} />
                                                                            {/* <button className="btn"><span className="material-icons"> done </span></button> */}
                                                                        </div>}

                                                                    {combindex.includes(i) && <span className="errormsg bidvalue" style={{ fontWeight: 'bold', color: 'red' }}>{combbidpriceerror}</span>}

                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div></>
                                    }
                                    {info && info.length > 0 && <> <div className="total">
                                        <div className="total_btn_group">
                                            <>{withDrawObj && withDrawObj.length > 0 ? <button className="btn fill_btn yellow-gradient" onClick={isgroup === "true" ? (e) => bidWithDraw(e, grouphistroy) : (e) => bidWithDraw(e, info[0])}><span className="material-icons icon">
                                                input
                                            </span> WITHDRAW</button> : <button className="btn fill_btn yellow-gradient" disabled><span className="material-icons icon">
                                                input
                                            </span> WITHDRAW</button>}</>
                                            <> {showFlag === false ? <button onClick={(e) => bidUpdate(e, info)} className={SubmitBtnLoader ? "fill_btn yellow-gradient float-end pe-none" : "fill_btn yellow-gradient float-end"} disabled={isButtonDisabled}>{SubmitBtnLoader ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null} SUBMIT</button> : <button type="button" className="clear-btn line-btn" onClick={handleClear}>

                                                <span className="material-icons me-1">autorenew</span>reset</button>}</>




                                        </div>
                                        {info?.length > 1 &&

                                            <div className="bid_field overall_period">

                                                <h3>OVERALL PERIOD</h3>
                                                <input type="NUMBER" className="form-control"
                                                    // onChange={(e) => setbidprice(e.target.value)}
                                                    onChange={(e) => (handleChange(e, "totalperiod")

                                                        // , handleCalculateTotal(e, i)
                                                    )}
                                                    onBlur={(e) => handleCheckPeriod(e)}
                                                    value={bidData} onFocus={(e) => removeError(e)} onKeyDown={handleKeyDown} min="1" />
                                                {/* <button className="btn"><span className="material-icons"> done </span></button> */}
                                            </div>}
                                        {(showFlag === false && isgroup === "false") ? null : (showFlag === false && isgroup === "true") ? <><div className="bid_field">
                                            <h3>OVERALL TOTAL</h3>
                                            <input type="text" className="form-control" onChange={(e) => handleChangePrice(e)} value={total ? total : 0} onBlur={handleCheckBidPrice} onKeyDown={handleKeyDown} />
                                        </div></> : <><div className="bid_field"><h3>OVERALL TOTAL</h3>&nbsp; &nbsp; {viewtotal ? viewtotal : total}
                                        </div></>}
                                        {bidpriceerror && <span className="errormsg bidvalue" style={{ fontWeight: 'bold', color: 'red', float: "right" }}>{bidpriceerror}</span>}
                                    </div>  </>}

                                    {/* <div className="table_btns">
                                     
                                    </div> */}
                                    {nonactiveitems && nonactiveitems.length > 0 &&
                                        <><h3>REDUNDANT COMBINATIONS</h3><div className="table_block redundant_table_block">
                                            <table className="table table_bid table-striped redudant_table">
                                                <thead>
                                                    <tr>
                                                        <th width="5%">#</th>
                                                        <th width="20%">COUNTRY</th>
                                                        <th width="10%">REGION</th>
                                                        <th width="15%">TYPES OF RIGHTS</th>
                                                        <th width="14%" className="text-center">REVENUE TYPE</th>
                                                        <th width="12%" className="text-center"> EXCLUSIVITY</th>
                                                        <th width="7%" className="text-center">Period</th>
                                                        <th width="7%" className="text-center">Min bid price <span className="sub">( USD )</span></th>
                                                        <th width="7%" className="text-center">last Bid price <span className="sub">( USD )</span></th>
                                                        <th width="17%" className="text-center">Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nonactiveitems && nonactiveitems.length > 0 && nonactiveitems.map((item, i) => {

                                                        return (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{item.country}</td>
                                                                <td>{item.region && typeof (item.region) != 'string' ? item.region?.join(", ") : item && item.region}</td>
                                                                <td>{item.typesofrights}</td>
                                                                <td className="text-center">{item.revenuetype}</td>
                                                                <td className="text-center">{item.exclusivity}</td>
                                                                <td className="text-center">{item.period}</td>
                                                                <td className="text-center">{item.minbidprice}</td>
                                                                <td className="text-center">{item.bidprice}</td>
                                                                <td className="text-center">{item.status}</td>

                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </div></>}
                                </div>
                                <div className="col-md-3">
                                    <div className="right">
                                        <h3>BID HISTORY</h3>
                                        <div className="table_block">
                                            <table className="table table_bid table-striped">
                                                <thead>
                                                    <tr>
                                                        <th width="70%">BID DATE</th>
                                                        <th width="30%">BID PRICE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isgroup === "false" ?
                                                        <>{heading && heading.bidinfo && heading.bidinfo.length > 0 && heading.bidinfo.map((item, i) => {

                                                            return (
                                                                <tr key={i}>
                                                                    <td>{item.bidon && moment(item.bidon).format("DD MMM YYYY")}</td>
                                                                    <td>$ {item.bidprice}</td>
                                                                </tr>
                                                            )
                                                        }).reverse()}</> : <>{groupData && groupData.bidinfo && groupData.bidinfo.length > 0 && groupData.bidinfo.map((item, i) => {

                                                            return (
                                                                <tr key={i} onClick={(e) => handleShow(e, item.id, i, item.bidprice)}>
                                                                    <td>{item.bidon && moment(item.bidon).format("DD MMM YYYY")}</td>
                                                                    <td>$ {item.bidprice}</td>
                                                                </tr>
                                                            )
                                                        }).reverse()}</>

                                                    }



                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="blur-bg">
                        <img src={imgUrl.toString()} alt="thumb" />
                    </div>
                    <Footer menus={menus} moreInfoCls={"fixed-footer"} />
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                        theme="light"
                    />
                </div>
            }
        </>



    );
};

export default ViewBid;
