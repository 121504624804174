/***
**Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
import Footer from "../components/footer/Footer";
import InsideHeader from "../components/InnerHeader/Header";
import $, { type } from 'jquery';
import { useForm, Controller } from "react-hook-form";
import { useParams, Link } from 'react-router-dom';
import AdvanceSearch from "./advanceSearch";
import Select from 'react-select';
import NotFound from "../pages/NotFound";
import EnquiryForm from "./EnquiryForm"
import SessionPopup from "./SessionPopup"
import Loader from "../components/loader"
import { Multiselect } from "multiselect-react-dropdown";
import * as Config from "../constants/Config";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import axios from "axios";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { contentContext } from "../context/contentContext";

const DynamicCategoryContent = () => {

    const history = useHistory();
    let { id } = useParams();
    const { control, handleSubmit } = useForm();
    // const [menus, setMenus] = useState([]);
    // const [grid, setGrid] = useState(true);
    const [categoryData, setCategoryData] = useState([]);
    // const [list, setList] = useState(false);
    const [config, setConfig] = useState({});
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState(false);
    const [toastSuccess, setToastSuccess] = useState(false);
    // const [isShowMyContent, setIsShowMyContent] = useState(false);

    // const [showCategoryData, setShowCategoryData] = useState(true); 
    // const [categoryName, setCategoryName] = useState(""); 
    // console.log('listlistlistlistlistlist',list)

    // const [initialCategoriesData, setInitialCategoriesData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    // const [searchTitleInput, SetSearchTitleInput] = useState('');
    // const [formValuesForContent, setFormValuesForContent] = useState([]);
    // const [wishListData, setWishListData] = useState({});
    const [wishLoad, setWishLoad] = useState(false);
    const [enquire, setEnquire] = useState(false);
    const [activeCheckId, setActiveCheckId] = useState("");
    const [activePagination, setActivePagination] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    const [searchFlag, setSearchFlag] = useState(false);
    //  console.log('activeCheckId',activeCheckId)
    let { lambda, appname } = window.app;


    const { menus, showCategoryData, setShowCategoryData, initialContent, isLoad1, setIsLoad1, initialCategoriesData1, setHideMenu, hideMenu, perPageConst, setPerPageConst, pageNumber, setPageNumber, assetTotal, setAssetTotal, perpage, setPerpage, totalPagesArray, setTotalPagesArray, totalPages, setTotalPages, setInitialCategoriesData1, isLoading, setIsLoading, clientData, wishListData, setWishListData, MenuCategoryName, setMenuCategoryName, isShowMyContent, setIsShowMyContent, searchPayload, setSearchPayload, searchFunActive, setSearchFunActive, welcomeMsg, setWelcomeMsg, paramType, setParamType, list, setList, grid, setGrid, wishlistCount, setwishlistCount, categorySearchPayload, setCategorySearchPayload, setCategoryFields, titleSearchPayload, setTitleSearchPayload, searchTitleInput, SetSearchTitleInput, activePayload, setActivePayload, setSelectedOptions, formValuesForContent, setFormValuesForContent, route, setRoute, usePrevious, contentSearchFlag, setContentSearchFlag, activeSortingPayload, setActiveSortingPayload } = useContext(contentContext);



    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            // setCurrentPage(1)
            // setRowsPerPage(15)
            setContentSearchFlag(true)
        }
    }, [prevRoute]);

    // console.log('contentSearchFlag',contentSearchFlag)
    // console.log('prevRoute',prevRoute)
    // console.log('route',route)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("categorycontent")
        getWishlistFun()
        Categories();
    }, []);


    const AddNotify = () => toast.success('Successfully added to Cart', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)
    const RemoveNotify = () => toast.success('Removed from Cart', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)

    const searchTitleErrToast = () => toast.error('Search cannot be empty', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    })

    const Categories = async () => {
        clientActivity("category");
        try {
            setIsLoading(true)
            // console.log('isLoading1', isLoading)
            const response = await tmdbApi.getCategories({});
            // console.log("Categories Categories response--------->", response.result);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setData(response.result);
                    setCategoryData(response.result)
                    setIsLoading(false)
                    // console.log('isLoading2', isLoading)
                }
            }
        } catch {
            console.log("error");
        }
    };
    // console.log('initialCategoriesData1initialCategoriesData1',initialCategoriesData1)
    const clientActivity = (pagearrival) => {
        //  console.log("pagearrival",pagearrival)
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        if (pagearrival === "category") {
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": "MARKET PLACE",
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))

            };
        } else {
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": 'MARKET PLACE',
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))

            };

        }
        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)

                }
            });
    }
    const previousClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)

        setPageNumber(k - 1)
    }
    const nextClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)
        setPageNumber(k + 1)
    }
    console.log('pageNumber', pageNumber)
    const handleCloseEnq = () => {
        setEnquire(false)
    };

    const handleEnquire = (e) => {
        setEnquire(true);
    }

    useEffect(() => {
        if (showCategoryData === false) {
            // console.log('use efffec laoddddd')

            contentCallSubmit(activePayload)
        }


    }, [perpage, pageNumber]);

    // console.log('pageNumber',pageNumber)
    // console.log('perpage',perpage)
    async function handleCategory(e, item) {
        // setPerpage(18)
        // setPageNumber(1)
        clientActivity()
        setInitialCategoriesData1([])

        setIsLoading(true)
        setShowCategoryData(false)
        setSearchFunActive(false)
        setContentSearchFlag(true)
        setTitleSearchPayload({})
        setSearchPayload({})

        SetSearchTitleInput("")
        console.log('category', item.name)
        if (item.name === "FORMATS") {
            let signed = localStorage.getItem("signed");
            console.log('signed', signed)
            if (signed === "false" || signed == undefined || signed == 'undefined' || signed == "" || signed == null) {
                console.log('signed', signed)
                setSuccess(true);
                setIsLoading(false)
                setShowCategoryData(true)
                setSelectedOptions([])
                setSearchPayload({})
                setCategoryFields({})
            }
            else {

                const payload = { 'category': [item.name] }
                setCategorySearchPayload(payload)
                setMenuCategoryName(item.name)
                // setSelectedOptions({ value: item.name, label: item.name })
                setSelectedOptions([])
                setSearchPayload({})
                setCategoryFields({})
                setParamType("searchByCategory")
                contentCallSubmit(payload);

            }
        } else {
            const payload = { 'category': [item.name] }
            setCategorySearchPayload(payload)
            setMenuCategoryName(item.name)
            // setSelectedOptions({ value: item.name, label: item.name })
            setSelectedOptions([])
            setSearchPayload({})
            setCategoryFields({})
            setParamType("searchByCategory")
            contentCallSubmit(payload);

        }
    }
    const formatEmailCall = () => {
        try {
            // setIsLoading(true)


            const clientid = localStorage.getItem("clientid")

            axios({
                method: 'POST',
                url: lambda + "/formatEmail?appname=" + appname + "&clientid=" + clientid,
            })
                .then(function (response) {
                    console.log(response)
                });

        } catch {
            console.log("format email error");
        }
    }
    function onConfirm() {
        setSuccess(false)
        formatEmailCall();
    };

    //const img = "https://orasi.imgix.net/orasi/client/resources/orasiv1/images/categories";
    const cardWidth2 = $(".col5-sm1").width();

    // console.log('formValuesForContent', formValuesForContent)
    const handleChangePages = (e) => {
        e.preventDefault()
        setActivePagination(true)
        let name = e.target.name;
        let value = e.target.value;
        console.log('perpageeee=====>', perpage)
        console.log('pageNumber======>', pageNumber)

        if (name == 'perPage') {
            setPageNumber(1)
            setPerpage(value)
        }
        if (name == 'pageNo') {
            setPageNumber(value)
        }
    }



    const contentCallSubmit = (payload, typeOfSearch) => {



        let payloadObj = {};

        payloadObj = { ...payload, pageNumber: pageNumber, perpage: perpage }

        if (payload && payload.showmycontent && payload.hasOwnProperty('showmycontent')) {
            payloadObj = { ...payload, ...payloadObj }
        }

        if ((paramType == "searchByCategory" || typeOfSearch == "searchByCategory") && payload.category != "undefined" && payload.category != "" && payload.category != null) {
            setTitleSearchPayload({})
            setSearchPayload({})
            payloadObj = { ...payload, ["category"]: payload.category, ...payloadObj }
        }
        if (Object.keys(categorySearchPayload).length > 0) {
            payloadObj = { ...categorySearchPayload, ...payload, ...payloadObj }
        }
        if (payload?.sorting) {
            payloadObj = { ...payload, ...payloadObj }
        }
        if (isShowMyContent === true) {
            payloadObj = { ...payloadObj, showmycontent: true }

        }

        payloadObj = { ...payloadObj, contentsearch: true, companyid: clientData.companyid }
        //  console.log('before submit',payloadObj)
        if (contentSearchFlag === false) {
            delete payloadObj.contentsearch; // Remove the key if contentSearchFlag is false
        } else {
            payloadObj.contentsearch = true; // Add or set the key to true if contentSearchFlag is true
        }

        setActivePayload(payloadObj)
        CommonContentCallApi(payloadObj)

    }

    const advSearchCall = async (payload) => {
        setFormValuesForContent([])
        console.log('adv search call')
        setMenuCategoryName("SEARCH RESULTS")
        let payloadObj = {};
        payloadObj = { ...payload, pageNumber: 1, perpage: 18, contentsearch: true, companyid: clientData.companyid }
        setActivePayload(payloadObj)
        CommonContentCallApi(payloadObj)
    }
    const titleSearchCall = async (payload) => {
        setFormValuesForContent([])
        console.log('title search call')
        setMenuCategoryName("SEARCH RESULTS")
        let payloadObj = { ...payload, pageNumber: 1, perpage: 18, contentsearch: true, companyid: clientData.companyid }
        setActivePayload(payloadObj)
        console.log('title search call payload', payloadObj)
        contentCallSubmit(payloadObj)
        //  CommonContentCallApi(payloadObj)
    }

    const CommonContentCallApi = async (payload) => {
        if (isShowMyContent === true) {
            payload = { ...payload, showmycontent: true }
        }
        try {
            console.log('before call happen', payload)
            setActiveSortingPayload(payload)
            const response = await tmdbApi.filterCategoryv1(payload);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setIsLoad1(true)
                    let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
                    if (data.length > 0) {
                        let assetTotal = response.result.totalContent || 0
                        setAssetTotal(assetTotal)
                        let signed = localStorage.getItem("signed");
                        let isFormatSigned = (signed === "false" || signed == undefined || signed == 'undefined' || signed == "" || signed == null) ? false : true
                        //  console.log('isFormatSigned',isFormatSigned)
                        if (!isFormatSigned) {
                            data = data.filter((eachContent) => !eachContent.category.includes('FORMATS'))
                        }


                        setInitialCategoriesData1(data)
                        setShowCategoryData(false)
                        // console.log('setIsLoading(false)1', isLoading)
                        if (assetTotal > 0 && perpage > 0) {
                            let totalPages = assetTotal / perpage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                            setIsLoading(false)
                        }
                    } else {
                        setAssetTotal(0)
                        console.log('setIsLoading(false)2', isLoading)
                        setHideMenu(false)
                        setInitialCategoriesData1([])
                        setShowCategoryData(false)
                        setIsLoading(false)
                    }
                }

            }
        } catch {
            setIsLoading(false)
            console.log("error");
        }
    }



    useEffect(() => {
        if (window.site) {
            setConfig(window.site);
        }

    }, [window.site]);

    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }


    const AddSelectedItemsToWishlist = (formvaluesObj, formid) => {
        setActiveCheckId(formid)
        AddToWishlistFun(formvaluesObj)
    }
    const deleteSelectWishList = (formvaluesObj, formid) => {
        setActiveCheckId(formid)
        const payload = formvaluesObj.map((eachItem) => {
            return eachItem.contentid
        })
        deleteWishlistFun({ "contentid": payload })

    }
    const onClickAddToWishList = (e, item, isAddOrDelete) => {
        setActiveCheckId(item.contentid)
        setWishLoad(true)
        if (isAddOrDelete == "Add") {


            const clientid = localStorage.getItem("clientid")
            let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

            let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
                item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
                    : "defaultImg"

            let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
                item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
                    : defaultImg

            const newObj = {
                clientid: clientid,
                appname: item.appname,
                title: item.title,
                contentid: item.contentid,
                // thumbnail: item.thumbnail,
                [imgKey]: imgValue,

                category: item.category,
                companyid: item.companyid
            }

            AddToWishlistFun([newObj])
        }
        else {

            deleteWishlistFun({ "contentid": [item.contentid] })
        }

    }
    const deleteWishlistFun = async (payload) => {
        try {
            // setIsLoading(true)


            const clientid = localStorage.getItem("clientid")

            axios({
                method: 'DELETE',
                url: lambda + "/wishlist?appname=" + appname + "&clientid=" + clientid,
                data: payload,
            })
                .then(function (response) {
                    getWishlistFun()
                    RemoveNotify()
                    setWishLoad(false)
                    setFormValuesForContent([])
                });

        } catch {
            console.log("error");
        }

    }
    // console.log('search payloadddd--->',searchPayload)
    const AddToWishlistFun = async (formvaluesObj, formid) => {
        try {
            // setIsLoading(true)
            setWishLoad(true)
            const response = await tmdbApi.AddToWishlist({ 'cart': formvaluesObj });
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    console.log('response result', response.result)
                    getWishlistFun()
                    setWishLoad(false)
                    setFormValuesForContent([])
                    AddNotify()
                    if (config?.client?.clientBasedSorting) {
                        if (formvaluesObj.length > 1) {
                            let length = formvaluesObj.length
                            let contentCompanyId = formvaluesObj[length - 1]?.companyid
                            const payload = { ...activeSortingPayload, 'sorting': contentCompanyId[0] }
                            contentCallSubmit(payload)
                        } else {
                            let contentCompanyId = formvaluesObj[0]?.companyid
                            const payload = { ...activeSortingPayload, 'sorting': contentCompanyId[0] }
                            contentCallSubmit(payload)
                        }
                    }
                    // setIsLoading(false)
                }

            }
        } catch {
            // setIsLoading(false)
            console.log("error");
        }

    }
    const getWishlistFun = async () => {
        try {

            const response = await tmdbApi.getWishlist();
            if (response.statusCode === 200) {
                setIsLoad1(true)
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    let data = response.result && response.result && response.result.result ? response.result.result : []

                    if (data.length > 0) {
                        let assetTotal = response.result.count || 0
                        setwishlistCount(assetTotal)
                        localStorage.setItem('wishlistCount', assetTotal)
                        setWishListData(data)
                        // console.log('getWishlist result', response.result)
                    }
                    else {
                        setwishlistCount(0)
                        localStorage.setItem('wishlistCount', 0)
                        setWishListData(data)
                        setIsLoading(false)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setActiveCheckId("")
            console.log("error");
        }

    }

    function handleGrid() {
        setGrid(true);
        setList(false);

    }

    function handleList() {
        setList(true);
        setGrid(false);
    }


    const cardWidth = $(".portfolio-item").width() != undefined ? $(".portfolio-item").width() : 169;

    const handleMoreInfo = (e, id) => {
        setGrid(true);
        setList(false);
        history.push("/moreinfov1/" + id);
    }
    const handleMoreInfoList = (e, id) => {
        setList(true);
        setGrid(false);
        history.push("/moreinfov1/" + id);
    }
    const handleBackButton = () => {
        // history.push("/category");
        // history.go(-1)
        // console.log('ShowCategoryData',showCategoryData)
        setInitialCategoriesData1([])
        setShowCategoryData(true)
        setSearchFunActive(true)
        setMenuCategoryName("")
        setPerpage(18)
        setPageNumber(1)
        setFormValuesForContent([])
        console.log('ShowCategoryData', showCategoryData)

    }

    const handleChangeCheckItem = (e, item) => {

        console.log('checked', e.target.checked)
        const clientid = localStorage.getItem("clientid")

        if (e.target.checked == true) {

            let arr = [...formValuesForContent]
            let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

            let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
                item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
                    : "defaultImg"

            let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
                item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
                    : defaultImg

            const newObj = {
                clientid: clientid,
                appname: item.appname,
                title: item.title,
                contentid: item.contentid,
                // thumbnail: item.thumbnail,
                [imgKey]: imgValue,

                category: item.category,
                companyid: item.companyid
            }
            arr.push(newObj)
            setFormValuesForContent(arr)
        }
        else {
            let newArr = [...formValuesForContent]
            let resultArray = newArr.filter((eachitem) => eachitem.contentid !== item.contentid)
            setFormValuesForContent(resultArray)
        }
        console.log('forrrmmvaluess', formValuesForContent)
    }
    // console.log('formvaluesss-->',formValuesForContent)
    const handleSelectAll = (e, item) => {

        console.log('checked', e.target.checked)
        const clientid = localStorage.getItem("clientid")

        if (e.target.checked == true) {

            let arr = []
            let dummyCategoryData = initialCategoriesData1
            // arr = arr.map((each)=>{

            dummyCategoryData && dummyCategoryData.length > 0 && dummyCategoryData.map((item, i) => {


                let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

                let imgKey = item.portraitimage != undefined && item.portraitimage != "" ? "portraitimage" :
                    item.landscapeimage != undefined && item.landscapeimage != "" ? "landscapeimage"
                        : "defaultImg"

                let imgValue = item.portraitimage != undefined && item.portraitimage != "" ? item.portraitimage :
                    item.landscapeimage != undefined && item.landscapeimage != "" ? item.landscapeimage
                        : defaultImg

                const newObj = {
                    clientid: clientid,
                    appname: item.appname,
                    title: item.title,
                    contentid: item.contentid,
                    // thumbnail: item.thumbnail,
                    [imgKey]: imgValue,

                    category: item.category,
                    companyid: item.companyid
                }
                arr.push(newObj)
                setFormValuesForContent(arr)
                //    }

            })


            // })

        }
        else {

            setFormValuesForContent([])
        }
        console.log('forrrmmvaluess', formValuesForContent)
    }

    // console.log('grid--------->',grid)
    // console.log('list--------->',list)
    // console.log('param typee--------->',paramType)
    const onClickSetCategory = () => {
        clientActivity("category")
        // setGrid(true)
        // setList(false)
        setShowCategoryData(true)
        setMenuCategoryName("")
        setSelectedOptions([])
        setSearchPayload({})
        setCategoryFields({})
        setParamType("")
        setPerpage(18)
        setPageNumber(1)
        setFormValuesForContent([])
    }

    const toggleContent = (e) => {
        // e.preventDefault()
        let isCheck = e.target.checked
        // console.log('slider slider slider slider slider slider ',isCheck)
        if (e.target.checked == true) {
            setIsShowMyContent(true)
            setPerpage(18)
            setPageNumber(1)
            setFormValuesForContent([])
            setSelectedOptions([])
            setSearchPayload({})
            setCategoryFields({})
            const payload = { 'showmycontent': isCheck }
            contentCallSubmit(payload)
        }
        else {
            setIsShowMyContent(false)
            setShowCategoryData(true)
            setMenuCategoryName("")
            setCategorySearchPayload({})
            setTitleSearchPayload({})
            setSearchPayload({})
            setSelectedOptions([])
            setSearchPayload({})
            setCategoryFields({})
            setFormValuesForContent([])

            setPerpage(18)
            setPageNumber(1)

        }

    }

    const handleSearchInput = (e) => {
        // e.preventDefault()
        let value = e.target.value
        setSearchFlag(true)
        SetSearchTitleInput(value)
        if (value === "") {
            setSearchFlag(false)
        }
        // setParamType("searchByTitle")
    }
    const ClearSearch = (e) => {
        console.log('showCategoryData--', showCategoryData)
        // if (initialCategoriesData1.length > 0 ) {
        //     SetSearchTitleInput("");
        // }
        // else{
        SetSearchTitleInput("");
        setShowCategoryData(true)
        setMenuCategoryName("")
        setSelectedOptions([])
        setSearchPayload({})
        setCategoryFields({})
        setPerpage(18)
        setPageNumber(1)
        setFormValuesForContent([])
        setSearchFlag(false)
        // }
    }
    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                onClickTitleSearch();
            }, 1000);
        }
    };

    const onClickTitleSearch = (e) => {

        // e.preventDefault()
        if (searchTitleInput.length <= 0) {
            searchTitleErrToast()
        }
        else {

            let titleInput = searchTitleInput
            setPerpage(18)
            setPageNumber(1)
            setContentSearchFlag(true)

            let payload = { "title": titleInput, "pageNumber": 1, "perpage": 18 }
            setIsLoading(true)
            setSearchFunActive(true)
            setMenuCategoryName("")
            setSelectedOptions([])
            setSearchPayload({})
            setCategoryFields({})
            setSearchPayload({})

            setCategorySearchPayload({})
            setTitleSearchPayload(payload)
            titleSearchCall(payload)

            setParamType("searchByTitle")

        }

    }
    //  console.log('showCategoryData',showCategoryData)
    //  console.log('hidemenu',hideMenu) 
    //  console.log('assetTotal',assetTotal) 
    //  console.log('perPageConst',perPageConst) 
    const handleWelcomeMsg = () => {
        setWelcomeMsg(false)
    }
    // console.log('showSessionPopupup',showSessionPopupup)
    return (
        <>
            {/* {toastSuccess && <div className="toast show animate__animated animate__bounce">
     <div className="toast-header">
       <p className="me-auto"><span className="toast-icon"><span className="material-icons">check_circle</span>
         </span>Reset password link sent to your email id</p>
         <button type="button" className="btn-close" data-bs-dismiss="toast" onClick={e => setToastSuccess(false)}></button>
     </div>
     </div>} */}
            {showSessionPopupup && <SessionPopup />}

            {welcomeMsg &&
                (<div className="welcomepopup">
                    <div className="welcome-wrapper">
                        <div className="welcome-header">
                            <img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images//welcome-graphic.svg" className="welcome-graphic animate__animated animate__tada" />
                            <button className="modal-close" onClick={handleWelcomeMsg}><span className="material-icons">close</span></button>
                        </div>
                        <div className="welcome-body">
                            <h1>Welcome to the<br />Orasi Media Platform</h1>
                            <p>The content displayed currently is only a part of our <b>200K+</b> hours of library. </p>
                            <p> We are continuously adding more content online on a daily basis. Kindly reach out to us in case you do not find something that you need and we will shortly share the same with you.</p>
                        </div>
                    </div>
                </div>)
            }


            <Modal show={enquire} onHide={handleCloseEnq}>
                <EnquiryForm img={img} handleCloseEnq={handleCloseEnq} />
            </Modal>
            <Modal className="access-denied" show={success} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>
                        <h3>Access Denied</h3>
                        <p>You are not eligible to view this content.</p>
                        <p>Please contact administrator</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>close</button>
                    </div>
                </div>

            </Modal>
            <InsideHeader />



            <div className="inner-body">

                <div className="body-wrapper">
                    <AdvanceSearch advSearchCall={advSearchCall} categoryData={categoryData} />

                    <div className="main-block">
                        <div className="block-header">

                            {/* {hideMenu === true ? ( */}

                            <div className="title-wrapper">
                                <div className="block-title">
                                    <h2>{`${showCategoryData ? 'Categories' : MenuCategoryName}`}</h2>
                                    <ul className="bread-crumbs">
                                        <button onClick={onClickSetCategory}><li key='key'>Categories</li></button>
                                        {showCategoryData ? null : MenuCategoryName != "" ? (<li key={MenuCategoryName}> {MenuCategoryName}</li>) : null}
                                    </ul>
                                </div>
                                {formValuesForContent && formValuesForContent.length > 0 && list ?
                                    (<div className="title-action">
                                        <button className="plain-btn color-btn" onClick={(e) => AddSelectedItemsToWishlist(formValuesForContent, "formvaluesId")}>
                                            {activeCheckId === "formvaluesId" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                <span className="material-icons plus">add</span>}

                                            Cart</button>





                                    </div>) : null}
                                {formValuesForContent && formValuesForContent.length > 1 && wishListData.length > 0 && list ?
                                    (<div className="title-action">

                                        <button className="plain-btn color-btn delete-button" onClick={(e) => deleteSelectWishList(formValuesForContent, "deleteAllId")}>
                                            {activeCheckId === "deleteAllId" ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                <span className="material-icons-outlined plus">delete_outline</span>}

                                            Remove Selected</button>





                                    </div>) : null}

                            </div>
                            {/* ) :
                                  (<div className="title-wrapper"><div className="block-title">
                                      <h2></h2>
                                      <ul className="bread-crumbs">
                                      </ul>
                                  </div>
                                  </div>)
                              } */}










                            <div className="body-actions">
                                {/* {(clientData && clientData.type == "BOTH") ?
                                     (
 
                                         <div className="title-wrapper">
                                             <div className="switch-buttons">
                                                 <label className="switch">
                                                     <input type="checkbox" checked={isShowMyContent} onChange={(e) => toggleContent(e)} />
                                                     <span className="slider round"></span>
                                                 </label>
                                                 <span className="switch-title">Show My Content</span>
                                             </div></div>
 
                                     ) : null} */}

                                {paramType != "advancedSearch" &&
                                    <div className="search-block">
                                        <input
                                            type="text"
                                            className="searchinput"
                                            placeholder="Search Content"
                                            value={searchTitleInput} onChange={(e) => handleSearchInput(e)}
                                            onKeyPress={handleKeypress}
                                        />
                                        <button className={searchFlag ? "clear-btn show" : "clear-btn"} onClick={ClearSearch}>
                                            <span className="material-symbols-outlined">close</span>
                                        </button>
                                        <button className="fill_btn float-end" type='submit' onClick={onClickTitleSearch}><span className="material-symbols-outlined">search</span></button>
                                    </div>

                                }
                                {/* {!showCategoryData ? (
                                     <div className="btn-group view-btns ms-2">
                                         <button type="button" className={`btn tablinks ${grid ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Grid View" onClick={handleGrid}>
                                             <span className="material-symbols-outlined"> grid_view </span>
                                         </button>
                                         <button type="button" className={`btn tablinks ${list ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="List View" onClick={handleList}>
                                             <span className="material-symbols-outlined">
                                                 list
                                             </span>
                                         </button>
                                     </div>
                                 ) : null} */}
                            </div>
                        </div>


                        <div className="block-body">
                            <div className="tab-header">
                                {(clientData && clientData.type == "BOTH") ?
                                    (

                                        <div className="title-wrapper">
                                            <div className="switch-buttons">
                                                <label className="switch">
                                                    <input type="checkbox" checked={isShowMyContent} onChange={(e) => toggleContent(e)} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span className="switch-title">Show My Content</span>
                                            </div>
                                        </div>
                                    ) : null}

                                {!showCategoryData ? (
                                    <div className="btn-group view-btns ms-2">
                                        <button type="button" className={`btn tablinks ${grid ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Grid View" onClick={handleGrid}>
                                            <span className="material-symbols-outlined"> grid_view </span>
                                        </button>
                                        <button type="button" className={`btn tablinks ${list ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="bottom" title="List View" onClick={handleList}>
                                            <span className="material-symbols-outlined">
                                                list
                                            </span>
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                            {!isLoading ? <>
                                {assetTotal > 0 && <>
                                    {!showCategoryData && MenuCategoryName == "SEARCH RESULTS" &&
                                        <h6>{assetTotal}{assetTotal == 1 ? ' Title' : ' Titles'}  Found</h6>}</>}
                            </> : null}
                            {showCategoryData ? (<> <div className="row">

                                {data && data.length > 0 ? (<>
                                    {data && data.length > 0 && data.map(function (item, i) {
                                        let signed = localStorage.getItem("signed");
                                        let isFormatSigned = item.name === "FORMATS" && (signed === "false" || signed == undefined || signed == 'undefined' || signed == "" || signed == null) ? true : false

                                        return (
                                            <div className="col-md-2 col-sm-3 col-xs-6" key={i} onClick={(e) => handleCategory(e, item)} >
                                                <div className="category-thumb">
                                                    {isFormatSigned ? (<span className="material-icons lock-icon" onClick={(e) => handleCategory(e, item)} >
                                                        lock
                                                    </span>) : null}
                                                    <div className="black-gradient thumb-titlewrapper">
                                                        {/* <h6>{item.name}</h6> */}
                                                        <h6>{item.name}
                                                            {/* <br /><span>{item.assetCount}</span> */}
                                                        </h6>
                                                        {/* <h6>{item.assetCount}</h6> */}
                                                    </div>

                                                    <img src={item.image + "?auto=compress,format&width=95"} />

                                                </div>
                                            </div>
                                        );
                                    }

                                    )


                                    }</>) : isLoading ? (
                                        <div className="orasi-preloader">
                                            <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                                        </div>
                                    ) : null}


                            </div></>) : (<>


                                {grid ?

                                    initialCategoriesData1 && initialCategoriesData1.length > 0 ? (<>

                                        <div className="grid-view tabcontent" id="grid-view">

                                            <div className="row portfolio">
                                                {initialCategoriesData1 && Object.keys(initialCategoriesData1).length > 0 && initialCategoriesData1.map((item, i) => {
                                                    // var startdate = moment().subtract(1, "days").format("DD-MM-YYYY");
                                                    var convertDate = item.releasedate && item.releasedate.toString() || ""
                                                    var res = convertDate.split("-") || []
                                                    let day = res[0] || ""
                                                    let mon = res[1] || ""
                                                    let year = res[2] || ""

                                                    let releasedateFormat = mon + "-" + day + "-" + year
                                                    // console.log("subtract date------",moment(item.releasedate).subtract(1, "days").format("DD-MMM-YY"))
                                                    let picName = item.mediaType === 'video' ? 'videoclip-defaulr' : item.mediaType === 'audio' ? 'musicfile-default' : item.mediaType === 'pdf' ? 'pdf-default' : item.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
                                                    // let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/${picName}.jpg`;
                                                    let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

                                                    // let imgUrl = item.thumbnail != "" ? img + item.thumbnail : defaultImg

                                                    let imgUrl =
                                                        item.portraitimage !== "" && item.portraitimage !== undefined
                                                            ? (item.portraitimage.startsWith("orasidev") || item.portraitimage.startsWith("orasistaging")
                                                                ? img + item.portraitimage
                                                                : item.portraitimage)
                                                            : item.landscapeimage !== "" && item.landscapeimage !== undefined
                                                                ? (item.landscapeimage.startsWith("orasidev") || item.landscapeimage.startsWith("orasistaging")
                                                                    ? img + item.landscapeimage
                                                                    : item.landscapeimage)
                                                                : defaultImg;


                                                    let wishListActive = wishListData && wishListData.length > 0 ? wishListData.filter((eachitem) => eachitem.contentid == item.contentid) : {}
                                                    let isAddOrDelete = wishListActive && wishListActive.length > 0 ? 'Delete' : 'Add'

                                                    // let categoryItem = item.category || ""
                                                    // categoryItem = categoryItem && categoryItem.join(', ')

                                                    let categoryItem = item.category && typeof (item.category) != 'string' ? item.category && item.category.length > 1 ? item.category.join(', ') : item.category : ""

                                                    return (
                                                        <div className="col6-sm1 col-md-6 portfolio-item" key={i} >
                                                            <div className="portfolio-wrap" style={{ cursor: "pointer" }} >

                                                                <img src={imgUrl + "?auto=compress,format&width=" + cardWidth + "&q=95"} className="asset-portrait-thumb" alt="" onClick={e => handleMoreInfo(e, item.contentid)} />

                                                                <div className="portfolio-info black-gradient" >
                                                                    <div className="thumb-info-block black-gradient" >
                                                                        <div className="thumb-info">

                                                                            <h6 className="thumb-title" style={{ cursor: "pointer" }} onClick={e => handleMoreInfo(e, item.contentid)} >{item.title}</h6>
                                                                            {item.category ? <h6 className="sub-title" style={{ cursor: "pointer" }} onClick={e => handleMoreInfo(e, item.contentid)}>{categoryItem}</h6> : null}
                                                                            <ul className="thumb-meta" style={{ cursor: "pointer" }} onClick={e => handleMoreInfo(e, item.contentid)} >
                                                                                {/* {item.category ? <li>{categoryItem}</li>:null} */}
                                                                                {item.duration ? <li>{item.duration}</li> : null}

                                                                                {item.releasedate ? <li> {item.releasedate && moment(item.releasedate).format('DD-MMM-YY')}</li> :
                                                                                    item.releaseyear ? <li>  {item.releaseyear} </li> : null}
                                                                                {item.keywords ? <li>{item.keywords}</li> : null}

                                                                            </ul>
                                                                            <p className="thumb-description" style={{ cursor: "pointer" }} onClick={e => handleMoreInfo(e, item.contentid)} >{item.synopsis}</p>

                                                                            {!isShowMyContent && clientData && clientData.type != "SELLER" ?
                                                                                <button style={{ cursor: "pointer", "z-index": 1 }} className={`table-actions-btn border-btn_sm ${wishListActive && wishListActive.length > 0 ? 'active' : ''}`} onClick={(e) => onClickAddToWishList(e, item, isAddOrDelete)}>

                                                                                    {wishListActive && wishListActive.length > 0 ? (

                                                                                        activeCheckId === item.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon " />) :
                                                                                            <span className="material-icons">check_circle </span>


                                                                                    ) :
                                                                                        (

                                                                                            activeCheckId === item.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                                                                <span className="material-symbols-outlined done">shopping_cart</span>

                                                                                        )}

                                                                                    CART
                                                                                </button> : null}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div style={{position:"absolute",top:0,left:0,bottom:0,right:0,"z-index":0,"background-color":"blue"}} onClick={e => handleMoreInfo(e, item.contentid)} >
 
                                                                 </div> */}
                                                            </div>

                                                        </div>
                                                    )
                                                })}


                                            </div>

                                            {/* {initialCategoriesData1 && initialCategoriesData1.length < 18  ? null : (<> */}
                                            {assetTotal > perPageConst ?
                                                <div className="row pagenation pgn1">
                                                    <div className="pagenation_prv"><button disabled={pageNumber == 1} onClick={e => previousClick(e)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                                    <div className="pagenation_page"> <label>Page :</label>
                                                        {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                                        <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                            {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                                return (
                                                                    <option key={i} value={item}>{item}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <label><strong> of {totalPages}</strong></label>
                                                    </div>
                                                    <div className="pagenation_count">
                                                        <label>{(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                                    </div>
                                                    <div className="pagenation_perpage">
                                                        <label>Per Page:</label>

                                                        <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={perpage} onChange={e => handleChangePages(e)} > <span className="material-icons-outlined">arrow_drop_down</span>
                                                            {/* <option value={6}>6</option>
                                                             <option value={12}>12</option>
                                                             <option value={18}>18</option> */}

                                                            <option value={18}>18</option>
                                                            <option value={36}>36</option>
                                                            <option value={54}>54</option>
                                                            <option value={72}>72</option>
                                                            <option value={90}>90</option>
                                                            <option value={108}>108</option>
                                                        </select>
                                                    </div>
                                                    <div className="pagenation_next"><button disabled={pageNumber == totalPages} onClick={e => nextClick(e)} className="jr-btn btn-white btn btn-default btn-block">Next</button></div>
                                                </div> : null
                                                // <p>Total Assets : {assetTotal}</p>
                                            }
                                            {/* </>) } */}
                                        </div>

                                    </>) : isLoading ? (
                                        <div className="orasi-preloader">
                                            <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                                        </div>
                                    ) :
                                        <div className="searchfail-block">
                                            <img src="https://orasi-dev.imgix.net/orasi/common/images/search-fail.png" className="searchfail-graphic mt-5" />
                                            <h6>Sorry, We couldn't find what you are looking for</h6>
                                            <p>Please try another way</p>
                                            <button className="fill_btn yellow-gradient" onClick={handleBackButton}>Go Back</button>
                                        </div>

                                    : null}




                                {list ?

                                    initialCategoriesData1 && initialCategoriesData1.length > 0 ? (<>

                                        <div className={`${clientData.type === "SELLER" ? "seller-list-view" : ""} list-view tabcontent`} id="list-view">
                                            <div className="table-responsive">
                                                <table className={`table table-bordered asset-list-table ${isShowMyContent ? 'show-my-content' : ''}`}>
                                                    <thead>
                                                        <tr >
                                                            {!isShowMyContent && clientData && clientData.type != "SELLER" ? (
                                                                <th><input type="checkbox" checked={formValuesForContent.length === initialCategoriesData1.length} onChange={(e) => handleSelectAll(e)} /></th>
                                                            ) : null}
                                                            {/* <input type="checkbox" onChange={(e) => handleChangeCheckItem(e)} /> */}

                                                            {/* <th><input type="checkbox" /></th> */}
                                                            <th>Title</th>
                                                            <th>Release Year</th>

                                                            {/* <th>Language</th> */}
                                                            <th>Genre</th>
                                                            <th>Duration</th>
                                                            <th>Director</th>
                                                            {/* <th>Producer</th> */}

                                                            <th>Description</th>
                                                            <th>Cast</th>
                                                            <th>Category</th>
                                                            {!isShowMyContent && clientData && clientData.type != "SELLER" ? (<th>Add to Cart</th>) : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {initialCategoriesData1 && initialCategoriesData1.length > 0 && initialCategoriesData1.map((item, i) => {

                                                            let isCartAdd = item.cart == true ? true : false
                                                            // console.log('item genreeee----->', item.genre)

                                                            let wishListActive = wishListData && wishListData.length > 0 ? wishListData.filter((eachitem) => eachitem.contentid == item.contentid) : {}
                                                            let isAddOrDelete = wishListActive && wishListActive.length > 0 ? 'Delete' : 'Add'
                                                            let director = item.director && typeof (item.director) != 'string' ? item.director && item.director.length > 1 ? item.director.join(', ') : item.director : ""

                                                            let genre = item.genre && typeof (item.genre) != 'string' ? item.genre && item.genre.length > 1 ? item.genre.join(', ') : item.genre : ""

                                                            let cast = item.cast && typeof (item.cast) != 'string' ? item.cast && item.cast.length > 1 ? item.cast.join(', ') : item.cast : ""

                                                            let categoryItem = item.category && typeof (item.category) != 'string' ? item.category && item.category.length > 1 ? item.category.join(', ') : item.category : ""


                                                            let picName = item.mediaType === 'video' ? 'videoclip-defaulr' : item.mediaType === 'audio' ? 'musicfile-default' : item.mediaType === 'pdf' ? 'pdf-default' : item.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
                                                            let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/${picName}.jpg`;

                                                            // let imgUrl = item.thumbnail != "" ? img + item.thumbnail : defaultImg

                                                            let imgUrl =
                                                                (item.portraitimage !== "" && item.portraitimage !== undefined)
                                                                    ? (item.portraitimage.startsWith("http")
                                                                        ? item.portraitimage
                                                                        : img + item.portraitimage)
                                                                    : (item.landscapeimage !== "" && item.landscapeimage !== undefined)
                                                                        ? (item.landscapeimage.startsWith("http")
                                                                            ? item.landscapeimage
                                                                            : img + item.landscapeimage)
                                                                        : defaultImg;

                                                            let filterList = formValuesForContent.filter((each) => each.contentid === item.contentid)
                                                            let isCheck = filterList && filterList[0] && filterList[0].title ? true : false
                                                            // console.log('filterList isCheck',filterList && filterList[0] && filterList[0].title)

                                                            return (
                                                                <tr className={`${isCheck ? 'active' : ''}`}>
                                                                    {!isShowMyContent && clientData && clientData.type != "SELLER" ? (
                                                                        <td>
                                                                            <input type="checkbox" id={item.contentid} checked={isCheck} onChange={(e) => handleChangeCheckItem(e, item)} />
                                                                        </td>
                                                                    ) : null}

                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}>
                                                                        <div className="title-name">
                                                                            <div className="table-thumb me-1">
                                                                                <img src={imgUrl + "?auto=compress,format&width=" + cardWidth} alt="" />
                                                                                {/* <img src={img + Config.imgmiddle + "portfolio1.jpg"} /> */}
                                                                            </div> <p>{item.title}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}>

                                                                        {/* {moment(releasedateFormat).format("DD-MMM-YY") || item.releaseyear} */}
                                                                        {item?.releaseyear ? item?.releaseyear : item?.releasedate ? moment(item?.releasedate).format('DD-MMM-YY') : ""}
                                                                        {/* {item.releaseyear} */}
                                                                    </td>
                                                                    {/* <td>
                                                       <div className="title-name">
                                                           <img src="https://orasi-resources.imgix.net/orasi/client/theme/images/portfolio1.jpg" className="asset-portrait-thumb" alt="" /> Welcome Group
                                                       </div>
                                                   </td> */}
                                                                    {/* <td>{item.languages}</td> */}
                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}><div className="cast">{genre}</div></td>
                                                                    {/* <td><span className="sm-badge">DRAMA</span><span className="sm-badge">Romance</span></td> */}
                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}>{item.duration}</td>
                                                                    <td>{director}</td>
                                                                    {/* <td>Jonthan Swift</td> */}

                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}><div className="cast">{item.synopsis}</div></td>
                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}><div className="cast">{cast}</div></td>
                                                                    <td onClick={e => handleMoreInfoList(e, item.contentid)}><span className="sm-badge cast">{categoryItem}</span></td>
                                                                    {!isShowMyContent && clientData && clientData.type != "SELLER" ? (
                                                                        <td>
                                                                            {/* <div className="table-actions-group add-btn">
                                                               <button className="table-actions-btn" onClick={(e)=>onClickAddToWishList(e,item)}>
                                                                   <span className="material-icons"> add </span>
                                                                   Wishlist
                                                               </button>
                                                               <button className="table-actions-btn view-btn" onClick={e => handleMoreInfo(e,item.contentid)}>
                                                                   <span className="material-icons">visibility</span>
                                                                   INFO
                                                               </button>
                                                           </div> */}
                                                                            <div className="table-actions-group add-btn">
                                                                                <button className={`table-actions-btn ${wishListActive && wishListActive.length > 0 ? 'active' : ''}`} onClick={(e) => onClickAddToWishList(e, item, isAddOrDelete)}>

                                                                                    {wishListActive && wishListActive.length > 0 ? (

                                                                                        activeCheckId === item.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                                                            // <span className="material-icons done">done</span>
                                                                                            // <span className="material-icons">check_circle</span>

                                                                                            <>
                                                                                                <span className="material-icons">shopping_cart</span>
                                                                                                <span className="material-icons done">done</span>
                                                                                            </>
                                                                                    ) :
                                                                                        (

                                                                                            activeCheckId === item.contentid ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) :
                                                                                                // <span className="material-icons add"> add </span>
                                                                                                <span className="material-symbols-outlined">shopping_cart</span>

                                                                                        )}


                                                                                </button>
                                                                                {/* <button className="table-actions-btn view-btn"
                                                                                  //  onClick={e => handleMoreInfo(e, item.contentid)}
                                                                                  onClick={handleEnquire}
  
                                                                              >
                                                                                  <span className="material-icons-outlined">feed</span>
                                                                                  ENQUIRE
                                                                              </button> */}
                                                                            </div>
                                                                        </td>
                                                                    ) : null}
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {initialCategoriesData1 && initialCategoriesData1.length < 18  ? null : (<> */}



                                            {assetTotal > perPageConst ?
                                                <div className="row pagenation pgn1">
                                                    <div className="pagenation_prv"><button disabled={pageNumber == 1} onClick={e => previousClick(e)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                                    <div className="pagenation_page"> <label>Page :</label>
                                                        {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                                        <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                            {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                                return (
                                                                    <option key={i} value={item}>{item}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <label><strong> of {totalPages}</strong></label>
                                                    </div>
                                                    <div className="pagenation_count">
                                                        <label>{(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                                    </div>
                                                    <div className="pagenation_perpage">
                                                        <label>Per Page:</label>
                                                        {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                                        <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={perpage} onChange={e => handleChangePages(e)} >

                                                            <option value={18}>18</option>
                                                            <option value={36}>36</option>
                                                            <option value={54}>54</option>
                                                            <option value={72}>72</option>
                                                            <option value={90}>90</option>
                                                            <option value={108}>108</option>
                                                        </select>
                                                    </div>
                                                    <div className="pagenation_next"><button disabled={pageNumber == totalPages} onClick={e => nextClick(e)} className="jr-btn btn-white btn btn-default btn-block">Next</button></div>
                                                </div> : null
                                                // <p>Total Assets : {assetTotal}</p>
                                            }
                                            {/* </>) } */}
                                        </div>

                                    </>) : isLoading ? (
                                        <div className="orasi-preloader">
                                            <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                                        </div>
                                    ) :
                                        <div className="searchfail-block">
                                            <img src="https://orasi-dev.imgix.net/orasi/common/images/search-fail.png" className="searchfail-graphic mt-5" />
                                            <h6>Sorry, We couldn't find what you are looking for</h6>
                                            <p>Please try another way</p>
                                            <button className="fill_btn yellow-gradient" onClick={handleBackButton}>Go Back</button>
                                        </div>


                                    : null}
                            </>)}
                        </div>

                        <Footer menus={menus} id={"/content/" + id} />
                    </div>


                </div>

            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

        </>
    );
};

export default DynamicCategoryContent;
