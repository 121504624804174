/***
**Module Name: About Us
 **File Name :  AboutUS.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains About us details.
 ***/
import React, { useState, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../api/tmdbApi";
import * as Config from "./../constants/Config";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PureCounter from "./purecounter";
import $ from 'jquery';
import InsideHeader from "../components/InnerHeader/Header";
import { contentContext } from "../context/contentContext";

import { useEffect } from "react";





const AboutUs = () => {
  const pure = new PureCounter;
  // const [menus, setMenus] = useState([]);
  const [config, setConfig] = useState({});

  const [sec1, setSec1] = useState({});
  const [sec2, setSec2] = useState({});
  const [sec3, setSec3] = useState({});

  const { menus,setContentSearchFlag,contentSearchFlag , setRoute} = useContext(contentContext);

  useEffect(() => {
    if (window.site) {
      setConfig(window.site);

    }

  }, [window.site]);

  if (config.common && config.common.resourcesUrl) {
    var img = config.common.resourcesUrl;
  }
useEffect(()=>{
  setContentSearchFlag(false)
  setRoute("notcontent")
},[])

  useEffect(() => {
    {
      menus && menus.length > 0 && menus.map(function (item, i) {
        if (item.menuid === 100002) {
           setSec1(item.team[0]);
           setSec2(item.team[1]);
           setSec3(item.team[2]);
        }
      })
    }

  }, [menus]);

  const cardWidth = $(".about-features").width();
  const aboutWidth = $("#about").width();

console.log('sec2.linearChannels',sec2.linearChannels)

  return (
    <>
      {/* <Header menus={menus} activeClass={"100002"} /> */}
      {localStorage.getItem("token") ? <InsideHeader activeClass={"4"} /> : <Header menus={menus} activeMenu={"aboutus"} activeClass={"100002"}/>}

      <main id="main">
        <div className="section mt-5" >
          <div className="container about-features-container about-first-container">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <h1 className="sec-heading mt-5 mb-5">{sec1.title}<span> {sec1.subtitle}</span></h1>
                <p className="heading-tag mt-5 text-center mb-4 max600" >{sec1.description}</p>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12" id="about">
                <img src={img + Config.imgmiddle + "about/about.png?auto=compress,format&width=" + aboutWidth} className="about-img mt-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="section map-section" >
          <h1 className="sec-heading text-shadow" data-aos="fade-up" data-aos-delay="150">{sec2.title}<br /><span>{sec2.subtitle}</span></h1>
          <p className="heading-tag mt-4 text-center mb-4 max600" data-aos="fade-up" data-aos-delay="200">{sec2.description}</p>
        {menus.length > 1  ?  <div className="about-count-wrapper" >
            <div className="count-block" >
              <div className="count-circle" >
                <h2 data-purecounter-start="0" data-purecounter-end={sec2?.linearChannels} data-purecounter-duration="1" className="purecounter"></h2>

                <p><sup>+</sup></p>
              </div>
              <h4>Linear<br />Channels</h4>
            </div>

            <div className="count-block" >
              <div className="count-circle" >
                <h2 data-purecounter-start="0" data-purecounter-end={sec2?.internationalVODContent} data-purecounter-duration="1" className="purecounter"></h2>
                <p>k<sup>+</sup></p>
                <h5>Hours</h5>
              </div>
              <h4>Of International<br />VOD Content</h4>
            </div>

            <div className="count-block" >
              <div className="count-circle" >
                <h2 data-purecounter-start="0" data-purecounter-end={sec2?.mediaCategories} data-purecounter-duration="1" className="purecounter"></h2>
              </div>
              <h4>Media<br />Categories</h4>
            </div>
          </div> : null}

        </div>

        <div className="section inner-container cloud-base" >
          <div className="container about-features-container">
            <h1 className="sec-heading mb-5">{sec3.title}</h1>
            <p className="heading-tag mt-5 text-center mb-4 max600" >{sec3.description}</p>
            <div className="row">

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/playlist.png?auto=compress,format&width=" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block1}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/showcase.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block2}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/sales.png?auto=compress,format&width" + cardWidth}
                      className="features-icon" />
                  </div>
                  <h6>{sec3.block3}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/multiscreen.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block4}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/monetization.png?auto=compress,format&width" + cardWidth}
                      className="features-icon" />
                  </div>
                  <h6>{sec3.block5}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/rights.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block6}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/smartsearch.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block7}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/intell.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block8}</h6>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="about-features">
                  <div className="icon-block">
                    <img src={img + Config.imgmiddle + "about/mediamanagement.png?auto=compress,format&width" + cardWidth} className="features-icon" />
                  </div>
                  <h6>{sec3.block9}</h6>
                </div>
              </div>

            </div>

          </div>
        </div>

      </main>

      <Footer menus={menus} />


    </>
  );
};

export default AboutUs;