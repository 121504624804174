/***
**Module Name: team
 **File Name :  team.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 27 2022
 **Last modified on: Dec 27 2022
 **Description : contains team page details.
 ***/
import React, { useEffect, useState, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../api/tmdbApi";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import * as Config from "../constants/Config";
import InsideHeader from "../components/InnerHeader/Header";
import { contentContext } from "../context/contentContext";
import Modal from 'react-bootstrap/Modal';

const Team = () => {

  const [config, setConfig] = useState({});
  const [teamBoard, setTeamBoard] = useState({});
  const [teamMember, setTeamMember] = useState({});
  const [about, setAbout] = useState('');
  const { menus ,setContentSearchFlag,contentSearchFlag, setRoute} = useContext(contentContext);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (window.site) {
      setConfig(window.site);

    }
    setContentSearchFlag(false)
    setRoute("notcontent")
  }, [window.site]);

  if (config.common && config.common.resourcesUrl) {
    var img = config.common.resourcesUrl;
  }

  useEffect(() => {
    {
      menus && menus.length > 0 && menus.map(function (item, i) {
        if (item.menuid === 100003) {
          setTeamBoard(item.team[0].members);
          setTeamMember(item.team[1].members)
        }
      })
    }

  }, [menus]);


  const handleDescription = (e,item) => {
    setAbout(item)
    setShowDescription(!showDescription)
  }

  //  const img= "https://orasi.imgix.net/orasi/client/resources/orasiv1/images";
  const cardWidth = $(".team-card-img-block").width();
  const cardWidth1 = $(".team-card-thumb").width();
  return (
    <>
     <Modal show={showDescription} onHide={handleDescription} className="modal title-des-popup" id="descriptionPopup" >
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p style={{ whiteSpace: "pre-line" }}>{about}</p>

        </Modal.Body>


      </Modal>

      {/* <Header menus={menus} activeClass={"100003"} /> */}
      {localStorage.getItem("token") ? <InsideHeader activeClass={"5"} /> : <Header menus={menus} activeMenu={"aboutus"} activeClass={"100003"}/>}
      <main id="main">
        <div className="section inner-container team" >
          {/* <!-- <h1 className="heading mt-5 text-center" >OUR  TEAM</h1> --> */}
          <h1 className="sec-heading mt-5">THE <span>BOARD</span></h1>

          <div className="container">
            <div className="team-lg-wrapper">
              <div className="row team-section">
                {teamBoard && teamBoard.length > 0 && teamBoard.map((item, i) => {

                  return (
                    <div className="col-md-6 board-members">
                      {/* <div className="team-card lg" >
                    <div className="team-card-header yellow-gradient" >
                      <div className="team-profile-block" >
                        <div className="team-card-img-block"><img src={"https://orasi-dev.imgix.net/orasi/team/ASIT.png?auto=compress,format&width=" + cardWidth} /></div>
                        <div className="team-title-block">
                          <h4>ASIT SHAH</h4>
                          <p className="profile-badge">Founder & C.E.O</p>
                        </div>
                      </div>
                    </div>
                    <div className="team-card-body">
                      <ul>
                        <li>25+ years of experience with diversified skills in Technology, finance and governance</li>
                        <li>Skills in Strategy & Business Development</li>
                        <li>MS Computer Eng. & Sciences from Case Western Reserve University, Cleveland, Ohio
                          and BE Computer Engineering from Bombay University</li>
                      </ul>
                    </div>
                  </div> */}

                      <div className="team-card lg" >
                        <div className="team-card-header yellow-gradient" >
                          <div className="team-profile-block" >
                            <div className="team-card-img-block">
                              {item.profileimage === undefined ? <img src={"https://orasi-dev.imgix.net/orasi/team/default-team.png?auto=compress,format&width=" + cardWidth} /> : <img src={item.profileimage + "?auto=compress,format&width=" + cardWidth} />}
                              
                              </div>
                            <div className="team-title-block">
                              <h4>{item.title}</h4>
                              <p className="profile-badge">{item.designation}</p>
                              {item?.email && item?.email.trim() != "" &&  <p className="team-contact"><span className="material-icons-outlined">email</span>{item.email}</p>}
                              {item?.phone && item?.phone.trim() != "" && <p className="team-contact mb-0"><span className="material-icons">phone</span>{item.phone}</p>}
                              
                            </div>
                          </div>
                        </div>
                        <div className="team-card-body">

                          {ReactHtmlParser(item.descriptioncontent)}
                          {/* <ul>
                            <li>25+ years of experience with diversified skills in Technology, finance and governance</li>
                            <li>Skills in Strategy & Business Development</li>
                            <li>MS Computer Eng. & Sciences from Case Western Reserve University, Cleveland, Ohio
                              and BE Computer Engineering from Bombay University</li>
                          </ul> */}
                        </div>
                      </div>

                    </div>
                  )
                })}
                {/* <div className="col-md-6">
                  <div className="team-card lg" >
                    <div className="team-card-header yellow-gradient" >
                      <div className="team-profile-block" >
                        <div className="team-card-img-block"><img src={"https://orasi-dev.imgix.net/orasi/team/KIRAN.png?auto=compress,format&width=" + cardWidth} /></div>
                        <div className="team-title-block">
                          <h4>KIRAN SANGANI</h4>
                          <p className="profile-badge">Co-Founder & C.O.O</p>
                        </div>
                      </div>
                    </div>
                    <div className="team-card-body">
                      <ul>
                        <li>Practicing businessman in a highly competitive IT industry successfully managing
                          system integration for more than 30+ years</li>
                        <li>Skills in various enterprise areas such as Distribution, Channel Management,
                          Market Research, Finance, Digital Marketing & Operations</li>
                        <li>Computer Engineering from Bombay University</li>
                      </ul>
                    </div>
                  </div>
                </div> */}








              </div>
            </div>
          </div>


          <h1 className="sec-heading mt-5">SALES <span>TEAM</span></h1>

          <div className="container">
            <div className="row team-section">
            {teamMember && teamMember.length > 0 && teamMember.map((item, i) => {

return (
              <div className="col-md-3 col-sm-6 col-xs-6 sales-team">
                <div className="team-card" >
                  <div className="team-card-thumb">
                  {item.profileimage === undefined ? <img src={"https://orasi-dev.imgix.net/orasi/team/default-team.png?auto=compress,format&width=" + cardWidth1} /> : <img src={item.profileimage + "?auto=compress,format&width=" + cardWidth1} />}
                    {/* <img src={item.profileimage + "?auto=compress,format&width=" + cardWidth1} className="team-img" /> */}
                  </div>
                  <div className="team-card-footer">
                    <h3 className="team-title">{item.title}</h3>
                    <p className="profile-badge-sm mb-3">{item.designation}</p>
                    <p className="team-para">{item?.about} </p>
                    <p className="team-mobile-para">{item?.about}</p>
                    {item?.about.length > 125 ? ( 
                    <a className="full-details mobile" onClick={(e)=>handleDescription(e,item?.about)}>full details<span className="material-icons-outlined">chevron_right</span></a> 
                    
                    ) : null}
                          
                  {item?.phone && item?.phone.trim() != "" &&  <p className="sales-contact"><span className="material-icons">phone</span>{item.phone}</p>}
                  {item?.email && item?.email.trim() != "" &&   <p className="slaes-email-contact"><span className="material-icons">email</span>{item.email}</p>}
                  </div>
                </div>
              </div>
              )
            })}
              {/* <div className="col-md-3 col-sm-6 col-xs-6">
                <div className="team-card" >
                  <div className="team-card-thumb">
                    <img src={"https://orasi-dev.imgix.net/orasi/team/MARTHA.png?auto=compress,format&width=" + cardWidth1} className="team-img" />
                  </div>
                  <div className="team-card-footer">
                    <h3 className="team-title">MARTHA L. CONTRERAS</h3>
                    <p className="profile-badge-sm mb-3">Business Development <br /> The Americas</p>
                    <p className="team-para">Over 20 years of experience in the media industry.</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col5-sm2">
                <div className="team-card" >
                  <div className="team-card-thumb">
                    <img src={"https://orasi-dev.imgix.net/orasi/team/EFE.png?auto=compress,format&width=" + cardWidth1} className="team-img" />
                  </div>
                  <div className="team-card-footer">
                    <h3 className="team-title">EFE ATIYIO</h3>
                    <p className="profile-badge mb-3">Business Development <br/> Africa</p>
                    <p className="team-para">Over 15 years of experience in the media industry.</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-3 col-sm-6 col-xs-6">
                <div className="team-card" >
                  <div className="team-card-thumb">
                    <img src={"https://orasi-dev.imgix.net/orasi/team/DAKSHA.png?auto=compress,format&width=" + cardWidth1} className="team-img" />
                  </div>
                  <div className="team-card-footer">
                    <h3 className="team-title">DAKSHA POPTANI</h3>
                    <p className="profile-badge-sm mb-3">Business Development<br />Africa and SE Asia</p>
                    <p className="team-para">Customer relationship, business development, ad sales with 5 years
                      of experience in the media industry.</p>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-3 col-sm-6 col-xs-6">
                <div className="team-card" >
                  <div className="team-card-thumb">
                    <img src={"https://orasi-dev.imgix.net/orasi/team/YOULIA.png?auto=compress,format&width=" + cardWidth1} className="team-img" />
                  </div>
                  <div className="team-card-footer">
                    <h3 className="team-title">YOULIA SILVERSTOV</h3>
                    <p className="profile-badge-sm mb-3"> Business Development <br /> Europe</p>
                    <p className="team-para">Distribution Manager with 12 years experience in channels' and content distribution across EMEA and Asia.</p>
                  </div>
                </div>
              </div> */}

            </div>
          </div>


















        </div>

      </main>

      <Footer menus={menus} />
    </>
  );
};

export default Team;