/***
**Module Name: Contact Us
 **File Name :  ContactUs.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains Contact Us details.
 ***/
import React, { useState, useEffect ,useContext} from "react";
import tmdbApi from "../api/tmdbApi";
// import "../../src/assets/css/style.css"
import $ from "jquery";
import * as Config from "./../constants/Config";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import InsideHeader from "../components/InnerHeader/Header";
import SessionPopup from "./SessionPopup";
import axios from "axios";
import moment from "moment";
import { contentContext } from "../context/contentContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InactivePopup from "./inactivePopup";
let { lambda, appname } = window.app;


const ContactUs = () => {
  // const [menus, setMenus] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameerror, setNameError] = useState('');
  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');
  const [errmsg, setErrmsg] = useState('');
  const [suberror, setSubError] = useState('');
  const [success, setSuccess] = useState('');
  // const [clientData, setClientData] = useState([]);
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);
  const [activeCheckId, setActiveCheckId] = useState("");

  const { menus,setContentSearchFlag,inactiveFlag, setInactiveFlag,contentSearchFlag , clientData, setClientData,setRoute} = useContext(contentContext);

  useEffect(() => {
    // appMenus();
    if (localStorage.getItem("token")) {
      // GetClientDataFun();
      clientActivity()
    }
    setContentSearchFlag(false)
    setRoute("notcontent")
    }, []);
  // const appMenus = async () => {
  //   try {
  //     console.log(tmdbApi);
  //     const response = await tmdbApi.appMenus({ result: "micro" });
  //     setMenus(response.result)
  //     console.log(response);
  //   } catch {
  //     console.log("error");
  //   }
  // };

  const GetClientDataFun = async () => {
    try {

      const response = await tmdbApi.getClientData({});
      if (response.statusCode === 200) {
        setClientData(response.result.result[0]);
      }
      console.log(response);
    } catch {
      console.log("error");
    }
  };

  const clientActivity = () => {
    let path = window.location.pathname.split("/");
    // const pageName = path[path.length - 1];
    var presentTime = moment();
    let payload;
    
        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": 'CONTACT US',
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };

    
    let urlLink;
    if(localStorage.getItem("previousid")){
        urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
    }else {
        urlLink= lambda + '/activity?appname=' + appname
    }

    axios({
        method: 'POST',
        url: urlLink,
        data: payload
    })
        .then(function (response) {
            if(response.data.statusCode === 200){
                localStorage.setItem("previousid",response.data.result)
            }
        });
}

  const validateEmail = (e) => {

    setEmail(e.target.value);

  }

  function formvalidation() {
    
    let flag = true;
    if (localStorage.getItem("clientid")) {
      if (msg === "") {
        setErrmsg("Please enter Message");
        setTimeout(function () { setErrmsg("") }, 3000);
        flag = false;
       
        return;
      }
    } else {
      const regEx = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?/g;
      if (regEx.test(email)) {
        setEmailError("");
        setTimeout(function () { setEmailError("") }, 2000)
      } else if (!regEx.test(email) && email !== "") {
        setEmailError("Email is Not Valid");
        flag = false;
       
      }

      if (name == "") {
        setNameError("Please Enter Name");
        flag = false;
       
        return;
      }

      if (email == "") {
        setEmailError("Please Enter Email");       
        flag = false;
        
        return;
      }
      if (subject == "") {
        setSubError("Please Enter Subject");       
        flag = false;
       
        return;
      }
      if (msg === "") {
        setErrmsg("Please Enter Message");
        flag = false;
        
        return;
      }
    }
// console.log('subject',subject)
    if (flag){
      if(activeCheckId == ""){
      handleContactus();}
    }else{
      setActiveCheckId("")
    }

    return;
  }
  const AddNotify = () => toast.success('Your message has been sent. Thank you!', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true, 
    progress: undefined,
    theme: "light",
},)
const ShowError = () => toast.error('Sorry something Went Wrong, Please try again.', {
  position: "bottom-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
},)
  const handleContactus = async () => {
    try {
      setActiveCheckId("isload")
      if(clientData && clientData.name){
        const response = await tmdbApi.ClientContactus({
          "name": clientData && clientData.name ? clientData.name : name,
          "emailid": clientData && clientData.personalemail ? clientData.personalemail : email,
          "subject": subject,
          "message": msg
        });
        console.log(response);
        if (response.statusCode == 200) {
          if(response.result == "Invalid token or Expired"){
            setShowSessionPopupup(true)
          }else if (response.result == "Client inactived") {
            setInactiveFlag(true)
    
          }else{
          AddNotify()
          setActiveCheckId("")
          // setSuccess("Your message has been sent. Thank you!");
          setName("");
          setEmail("");
          setSubject("");
          setMsg("");
          setTimeout(function () {
            setSuccess("");
          }, 3000);
        }
        }
      }else{
        const response = await tmdbApi.contactus({
          "name":  name,
          "emailid":email,
          "subject": subject,
          "message": msg
        });
        console.log(response);
        if (response.statusCode == 200) {
          setActiveCheckId("")
          AddNotify()
          // setSuccess("Your message has been sent. Thank you!");
          setName("");
          setEmail("");
          setSubject("");
          setMsg("");
          setTimeout(function () {
            setSuccess("");
          }, 3000);
        }
      }
     
    } catch {
      ShowError()
      
      setActiveCheckId("")
      console.log("error");
    }
  };


  const handleSubmit = (e) => {
    formvalidation();
  }
  const handleName = (e) => {
    setNameError("");  
}

const handleEmail = (e) => {
  setEmailError("");  
}

const handleSubject = (e) => {
  setSubError("");  
}

const handleMessage = (e) => {
  setErrmsg("");  
}
  // console.log('data', clientData);
  return (
    <>{localStorage.getItem("token") ? <InsideHeader activeClass={"7"} /> : <Header menus={menus} activeMenu={"contactus"} activeClass={"100004"}/>}
   {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

      <main id="main">
        <div className="section inner-container" >
          {/* <!-- <h1 className="heading mt-5 text-center" >How to Get in Touch</h1> --> */}
          <h1 className="sec-heading mt-5">CONTACT <span>US</span></h1>
          <p className="heading-tag mt-2 text-center mb-4 max600" >If you would like to learn more about the platform or have other enquiries, here are the different ways we can be contacted.</p>
          <section id="contact" className="contact section-bg">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-6">
                  <div className="info-box mb-4">
                    <i className="material-symbols-outlined"> place </i>
                    <h3>Our Address</h3>
                    {/* <p>PG/17, Ground Floor, Rotunda Blding, Bombay Stock Exchange, Mumbai Samachar Marg, Fort, Mumbai 400 001.</p> */}
                    <p>425 WASHINGTON BLVD, #1210, JERSEY CITY, NJ 07310, USA.</p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="info-box  mb-4">
                    <i className="material-symbols-outlined"> email </i>
                    <h3>Email Us</h3>
                    <p><a href="mailto:info@orasimedia.com">info@orasimedia.com</a> </p>
                    
                  </div>
                </div>

                {/* <div className="col-lg-3 col-md-6">
                  <div className="info-box  mb-4">
                    <i className="material-symbols-outlined">
                      wifi_calling_3
                    </i>
                    <h3>Call Us</h3>
                    <a href="tel:+912266391059">(+91) 22 6639 1059</a><br/>
                    <a href="tel:+912266105958">(+91) 22 6610 5958</a>
                  </div>
                </div> */}

              </div>

              <div className="row mt-3">

                <div className="col-lg-6 ">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2107.4722462368004!2d-74.03725316933944!3d40.723146845306815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c250acba980dbf%3A0xf4e70bb7691cc13b!2sMarbella%20apartments%2C%20425%20Washington%20Blvd%20%231210%2C%20Jersey%20City%2C%20NJ%2007310%2C%20USA!5e0!3m2!1sen!2sin!4v1680504076457!5m2!1sen!2sin"  frameBorder="0"
                    style={{ border: "0", width: "100%", height: "384px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className="col-lg-6">
                  <div className="php-email-form">
                    <div className="row">
                      {localStorage.getItem("clientid") ?
                        <><div className="col-md-6 form-group">
                          <div className="form-floating">
                            <input type="text" className="form-control" name="name" id="floatingInput" placeholder="Your Name"
                              value={clientData.name}
                              required disabled />

                            <label htmlFor="floatingInput">Your Name</label>
                          </div>
                        </div><div className="col-md-6 form-group mt-3 mt-md-0">
                            <div className="form-floating">
                              <input type="text" className="form-control" id="floatingInput" name="emailid" placeholder="Email ID"
                                value={clientData.personalemail}
                                disabled />

                              <label htmlFor="floatingInput">Email ID</label>
                            </div>
                          </div></> :
                        <><div className="col-md-6 form-group">
                          <div className="form-floating">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} autoComplete="on" onFocus={(e)=>handleName(e)} required /> {nameerror != "" ?
                              <span className="errormsg" style={{
                                fontWeight: 'bold',
                                color: 'red',
                              }}>{nameerror}</span> : ""}
                            <label htmlFor="floatingInput">Your Name</label>
                          </div>
                        </div><div className="col-md-6 form-group mt-3 mt-md-0">
                            <div className="form-floating">
                              <input type="text" className="form-control" id="floatingInput" placeholder="Email ID" value={email} onChange={(e) => validateEmail(e)} onFocus={(e)=>handleEmail(e)} autoComplete="on" />
                              <span className="errormsg" style={{
                                fontWeight: 'bold',
                                color: 'red',
                              }}>{emailError}</span>
                              <label htmlFor="floatingInput">Email ID</label>
                            </div>
                          </div></>
                      }
                    </div>
                    <div className="form-group mt-3">

                      <div className="form-floating">
                        <input type="text" name="subject" className="form-control" id="floatingInput" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} onFocus={(e)=>handleSubject(e)} />
                        <label htmlFor="floatingInput">Subject</label>
                        <span className="errormsg" style={{
                                fontWeight: 'bold',
                                color: 'red',
                              }}>{suberror}</span>
                      </div>

                    </div>
                    <div className="form-group mt-3">
                      <textarea className="form-control" name="message" rows="5" placeholder="Message" value={msg} onChange={(e) => setMsg(e.target.value)} onFocus={(e)=>handleMessage(e)} required></textarea>
                      {errmsg != "" ?
                        <span className="errormsg" style={{
                          fontWeight: 'bold',
                          color: 'red',
                        }}>{errmsg}</span> : ""
                      }
                    </div>
                    {/* <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-
                      message">Your message has been sent. Thank you!</div>
                    </div> */}
                    {success != "" ?
                      <span className="errormsg" style={{
                        fontWeight: 'bold',
                        color: 'green',
                      }}>{success}</span> : ""
                    }
                    <div className="text-center"><button className="fill_btn yellow-gradient" onClick={e => handleSubmit(e)}>
                    {activeCheckId === "isload" ? (
                                             <img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon"/> ):
                                         null}
                      SUBMIT</button></div>
                  </div>
                </div>

              </div>

            </div>
          </section>
        </div>

      </main>
      <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />
      <Footer menus={menus} /></>

  );
};

export default ContactUs;
