/***
**Module Name: About Us
 **File Name :  AboutUS.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains About us details.
 ***/
import React, { useState, useEffect, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../../api/tmdbApi";
import InsideHeader from "../../components/InnerHeader/Header";
import Footer from "../../components/footer/Footer";
import Modal from 'react-bootstrap/Modal';
import TableLoader from "../../components/tableLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery';
import axios from "axios";

import * as Config from "../../constants/Config";
import { useHistory } from "react-router";
import { contentContext } from "../../context/contentContext";
import SessionPopup from "../SessionPopup"
import moment from "moment";
import DataTable from 'react-data-table-component';
let { lambda, appname } = window.app;


const AuctionTitle = () => {
    const history = useHistory();
    // const [menus, setMenus] = useState([]);
    const [isLoadCart, setIsLoadCart] = useState(false);
    const [config, setConfig] = useState({});
    const [initialFlag, setInitialFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [pageNumber, setPageNumber] = useState(1);
    //const [perPageConst, setPerPageConst] = useState(10);
    // const [perpage, setPerpage] = useState(12);
    const [assetTotal, setAssetTotal] = useState(0);
    const [activeCheckId, setActiveCheckId] = useState("");



    const [totalPages, setTotalPages] = useState(0);
    const [totalPagesArray, setTotalPagesArray] = useState(0);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    // const [sortBy, setSortBy] = useState("");
    // const [search, setSearch] = useState("");
    const [titleData, setTitleData] = useState([]);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [mybids, setMybids] = useState([]);

    const [withdrawData, setWithdrawData] = useState({});
    const [withdrawFlag, setWithdrawFlag] = useState(false);
    const [dataFlag, setdataFlag] = useState(false);
    const [checkGroup, setCheckGroup] = useState(false);
    const [clearFlag, setClearFlag] = useState(false);
    //const [search, setSearch] = useState("");
    const { clientData, biddingperPageConst, isShowMyContent, setShowCategoryData, menus, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, activeTab, setActiveTab, titlePerPage, setTitlePerPage, pageNumber, setPageNumber, sortBy, setSortBy, route, setRoute, usePrevious, titlePageNumber, setTitlePageNumber, search, setSearch, contentSearchFlag, setContentSearchFlag } = useContext(contentContext)
    let flag= false;
    const prevRoute = usePrevious(route);
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setSearch("");
            setContentSearchFlag(false)
        }
    }, [prevRoute]);
    console.log("route",prevRoute)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("title");
        getClientBids();
        clientActivity();
    }, []);

    // useEffect(() => {
    //     if(contentSearchFlag === false){
    //     getAuctionFun2()
    //     }else{
    //         getAuctionFun() 
    //     }
    // }, [titlePerPage, titlePageNumber]);

  
    // useEffect(() => {
    //     if(contentSearchFlag === false)
    //     getAuctionFun2()
    // }, [contentSearchFlag]);

    useEffect(() => {
        if(search.length <= 0){
            getAuctionFun();
        }
    }, [titlePerPage, titlePageNumber,contentSearchFlag]);

    useEffect(() => {
            getAuctionFun();
    }, [contentSearchFlag === true]);
//   useEffect(() => {   
//             if(search.length >= 0 && route ==="title") 
//             getAuctionFun();
//     }, [contentSearchFlag,setContentSearchFlag,route ==="title"]);

  //  console.log("searchhhhhhhhhhhhhhhhhhhhhh",contentSearchFlag,search)
    useEffect(() => {
        if (window.site) {
            setConfig(window.site);
        }

    }, [window.site]);
    if (config.common && config.common.resourcesUrl) {
        var img = config.common.resourcesUrl;
    }


    const AddNotify = () => toast.success('Bid Withdraw Successfully', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    },)
    const getAuctionFun = async (e) => {
        try {
            // console.log("Serch1",search,contentSearchFlag)
            setIsLoadCart(true)
            
            let payload;
            
            if(contentSearchFlag){
                payload = { titlePerPage, titlePageNumber:1, sortBy ,search}
            }else{
                payload = { titlePerPage, titlePageNumber, sortBy}
            }
            const response = await tmdbApi.getAuctionTitle(payload);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    let data = response.result && response.result && response.result.data ? response.result.data : []
                    if (data.length > 0) {
                        setTitleData(data)
                        setIsLoadCart(false)
                        let assetTotal = response.result.totalcount || 0
                        setAssetTotal(assetTotal)
                        if (assetTotal > 0) {
                            let totalPages = assetTotal / titlePerPage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                        }
                    }
                    else {
                        setTitleData([])
                        setIsLoadCart(false)
                        setdataFlag(true)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setIsLoadCart(false)
            setActiveCheckId("")
            console.log("error");
        }

    }
    const handleSearch = async (e, click) => {
        try {
            // console.log("Serch1",search,contentSearchFlag)
            setIsLoadCart(true)
            if (click) {
                setContentSearchFlag(true)
            }
            let payload;
            console.log("search in back",search);
                payload = { titlePerPage, titlePageNumber:1, sortBy ,search}
            const response = await tmdbApi.getAuctionTitle(payload);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    let data = response.result && response.result && response.result.data ? response.result.data : []
                    if (data.length > 0) {
                        setTitleData(data)
                        setIsLoadCart(false)
                        let assetTotal = response.result.totalcount || 0
                        setAssetTotal(assetTotal)
                        if (assetTotal > 0) {
                            let totalPages = assetTotal / titlePerPage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                        }
                    }
                    else {
                        setTitleData([])
                        setIsLoadCart(false)
                        setdataFlag(true)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setIsLoadCart(false)
            setActiveCheckId("")
            console.log("error");
        }

    }
    const getAllTitles = async () => {
        if (contentSearchFlag) {
            getAuctionFun()
        } else {
            handleSortBy(sortBy)
        }

    }
    const getAllBids = async () => {
        getClientBids()
    }

    const getClientBids = async () => {
        try {
            setIsLoading(true)

            const response = await tmdbApi.getClientBids();
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response.result === "No Content Data") {
                    console.log("came")
                    setMybids([])
                    setIsLoading(false)
                } else {
                    let data = response.result && response.result && response.result.data ? response.result.data : []
                    if (data.length > 0) {
                        setMybids(data)
                        setIsLoading(false)
                    }
                    else {
                        setMybids([])
                        setIsLoading(false)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setIsLoadCart(false)
            setActiveCheckId("")
            console.log("error");
        }

    }
    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        let pageName;
        if (activeTab === "title") {
            pageName = "AUCTION ITEMS"
        } else {
            pageName = "MY BIDS"
        }

        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }



    const handleChangePages = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'perPage') {
            setTitlePageNumber(1)
            setTitlePerPage(value)
        }
        if (name == 'pageNo') {
            setTitlePageNumber(value)
        }
    }
    const previousClick = (e) => {
        e.preventDefault()
        let k = parseInt(titlePageNumber)

        setTitlePageNumber(k - 1)
    }
    const nextClick = (e) => {
        e.preventDefault()
        let k = parseInt(titlePageNumber)
        setTitlePageNumber(k + 1)
    }
    const handleViewBid = (e, item) => {
        if (item.isgroup) {
            localStorage.setItem("groupbid", JSON.stringify(item));
            history.push("/viewbid/" + item.clientbidid + '/' + item.isgroup);
        } else {
            history.push("/viewbid/" + item.itemcombinationid + '/' + item.isgroup);
        }

    }

    const bidWithDraw = (e, info) => {
        setWithdrawData(info);
        setWithdrawFlag(true);
        setCheckGroup(info.isgroup);


    }
    const handleWithdraw = () => {
        setBtnLoader(true)
        const clientId = localStorage.getItem("clientid");
        let urlLink = lambda + '/withdraw?appname=' + appname + "&clientbidid=" + withdrawData.clientbidid + "&clientid=" + clientId + "&status=WITHDRAWN&isgroup=" + withdrawData.isgroup
        axios({
            method: 'POST',
            url: urlLink
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    if (response.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        setWithdrawFlag(false)
                        AddNotify()
                        getClientBids()
                        setBtnLoader(false)
                    }
                }
                setBtnLoader(false)
            });
    }




    const handleExplore = () => {
        setShowCategoryData(true)
        history.push('/content')
    }

    const handleExplore1 = () => {
        ClearSearch();
    }

    const handleMoreInfo = (e, id) => {
        history.push('/auctionmoreinfo/' + id)
    }
    const handleBids = (e, id) => {
        history.push('/bids/' + id)
    }

    const sort = (e) => {
        // setTitlePerPage(12);
        setTitlePageNumber(1);
        setSortBy(e.target.value)
        handleSortBy(e.target.value)

    }
    const handleSortBy = (sort) => {
        if (search && search.length > 0) {
            setTitlePageNumber(1);
        }
        setIsLoadCart(true)
        const clientid = localStorage.getItem("clientid");
        let urlLink;
        // if (search && search.length > 0 && contentSearchFlag) {
        //    urlLink = lambda + '/biddingItems?appname=' + appname + "&clientid=" + clientid + "&sortBy=" + sort + "&pageNumber=" + titlePageNumber + "&assetcount=" + titlePerPage + "&search=" + search
           
        // } else {
            urlLink = lambda + '/biddingItems?appname=' + appname + "&clientid=" + clientid + "&sortBy=" + sort + "&pageNumber=" + titlePageNumber + "&assetcount=" + titlePerPage
            console.log("else")
        
        // }
        axios({
            method: 'POST',
            url: urlLink,
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    if (response.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                        console.log("count@@@@@", response.data.result.totalcount);
                        setTitleData(response.data.result.data)
                        let assetTotal = response.data.result.totalcount || 0
                        setAssetTotal(assetTotal)
                        if (assetTotal > 0) {
                            let totalPages = assetTotal / titlePerPage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                        }
                    }
                    setIsLoadCart(false)
                }
            });
    }


    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                getAuctionFun();
            }, 1000);
        }
    };


    const ClearSearch = async () => {
        setSearch("");
        // setTitlePerPage(12)
        setTitlePageNumber(1);
        setSortBy("newest")
        try {
            setIsLoadCart(true)

            let payload = { titlePerPage, titlePageNumber: 1, sortBy: "newest" }

            const response = await tmdbApi.getAuctionTitle(payload);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    let data = response.result && response.result && response.result.data ? response.result.data : []
                    if (data.length > 0) {
                        setIsLoadCart(false)
                        setTitleData(data)

                        let assetTotal = response.result.totalcount || 0
                        setAssetTotal(assetTotal)
                        if (assetTotal > 0) {
                            let totalPages = assetTotal / titlePerPage;
                            totalPages = Math.ceil(totalPages)
                            let totalArray = [];
                            for (var i = 0; i < totalPages; i++) {
                                totalArray.push(i + 1)
                            }
                            setTotalPagesArray(totalArray)
                            setTotalPages(totalPages)
                        }
                    }
                    else {
                        setTitleData([])
                        setIsLoadCart(false)
                    }
                }
                setActiveCheckId("")

            }
        } catch {
            setIsLoadCart(false)
            setActiveCheckId("")
            console.log("error");
        }
    }
    // console.log(assetTotal , biddingperPageConst)
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
    };

    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No content was found for the searched keyword</p>
                </div> </div>
        )
    }


    const columns = [
        // {
        //     name: '#',
        //     cell: (props,index) => index+1,
        //     sortable: false,
        // },
        {
            name: 'Image',
            cell: (props) => <img src={props.itemimage ?
                img + props.itemimage + '?auto=compress,format&width=80' :
                `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg?auto=compress,format&width=80`} alt='Image' />,
            sortable: false,
        },

        {
            name: 'title',
            selector: row => row.title,
            sortable: false,
            width: "100"

        },
        {
            name: 'country/region',
            selector: row => row.bidname,
            sortable: false,
        },
        // {
        //     name: 'region',
        //     selector: row => row.region,
        //     sortable: !initialFlag,
        // },
        {
            name: 'types of rights',
            selector: row => row.typesofrights,
            sortable: false,
        },
        {
            name: 'revenue',
            // cell: (props) => <img src={props.image} width={60} alt='Image' />,
            selector: row => row.revenuetype,
            sortable: false,

        },

        {
            name: 'exclusivity',
            selector: row => row.exclusivity,
            // cell: (props) => <div className="text-elipsis">{props.comments}</div>,
            sortable: false,
        },
        {
            name: 'period',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.period === 1 ? row.period + " year" : row.period + " years",

        },
        {
            name: 'min bid price (USD)',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.minbidprice,

        },

        {
            name: 'my bid (USD)',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.bidprice,

        },

        {
            name: 'bid on',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.bidon ? new Date(row.bidon).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }) : null,

        },
        {
            name: 'max bid price (USD)',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.maxbidprice,

        },
        {
            name: 'status',
            // selector: row => moment(row.updated).utc().format("DD-MMM-YYYY HH:mm"),
            selector: row => row.status === "OUT BIDDED" ? "ACTIVE" : row.status,

        },
        {
            name: 'Actions',
            cell: (props) =>


                <div className="btn-group">
                    <button type="button" title="Edit Details" className="btn btn-outline-primary" onClick={(e) => handleViewBid(e, props)}> <span className="material-icons"> edit </span></button>

                    {(((props.status != "ACTIVE") && (props.status != "OUT BIDDED"))) ? <button type="button" title="Withdraw Bid" className="btn btn-outline-primary" disabled> <span className="material-icons"> input </span></button> : <button type="button" title="Withdraw Bid" className="btn btn-outline-primary" onClick={(e) => bidWithDraw(e, props)}> <span className="material-icons"> input </span></button>}
                </div>

            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    console.log("search", search.length)

    return (
        <>
            {showSessionPopupup && <SessionPopup />}

            <InsideHeader activeClass={"8"} />
            <Modal className="access-denied" show={withdrawFlag} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => (setWithdrawFlag(false), setBtnLoader(false))}><span className="material-icons">close</span></button>
                        {/* <span className="material-icons access-denied-icon">https</span> */}
                        <span class="material-icons access-denied-icon"> input </span>
                        <h3>Withdraw Confirmation</h3>
                        {checkGroup === true && <p>All Combinations will be Withdrawn </p>}
                        <p>This action cannot be undone. Do you want to Proceed?</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={handleWithdraw}>{BtnLoader ? (<img src="https://orasi-dev.imgix.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Withdraw</button>
                    </div>
                </div>

            </Modal>

            <div className="inner-body cart auction">
                <div className="body-header">
                    <div className="md-container">
                        <div className="page-header">
                            <div className="title-wrapper">
                                <div className="block-title">

                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button onClick={getAllTitles} className={`nav-link ${activeTab == 'title' ? 'active' : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Titles</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button onClick={getAllBids} className={`nav-link ${activeTab == 'bids' ? 'active' : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">My Bids</button>
                                        </li>

                                    </ul>

                                </div>
                                {/* <div className="title-action">
                             
  
                             </div> */}

                            </div>
                            {/* <button className="plain-btn" onClick={handleBack}><span className="material-icons-outlined">chevron_left
                            </span>Back</button> */}

                        </div>
                    </div>
                </div>


                <div className="body-content">
                    <div className="md-container">

                        <div className="tab-content" id="myTabContent">

                            <div className={`tab-pane fade ${activeTab == 'title' ? 'active show' : ''}`}
                                //className="tab-pane fade show active"
                                id="home" role="tabpanel" aria-labelledby="home-tab">
                                {titleData && titleData.length > 0 && isLoadCart === false ? (<>
                                    <div className="title_block">
                                        <div className="title_hed">
                                            <h3></h3>
                                            <div className="right">
                                                <div className="search-block form-group">
                                                    <input
                                                        type="text"
                                                        className="searchinput"
                                                        placeholder="Search Title"
                                                        value={search} onChange={(e) => setSearch(e.target.value)}
                                                        onKeyPress={handleKeypress}
                                                    />
                                                    <button class={search ? "clear-btn show" : "clear-btn"} onClick={ClearSearch}>
                                                        <span class="material-symbols-outlined">close</span>
                                                    </button>
                                                    <button className="fill_btn float-end" type='submit' onClick={(e) => handleSearch(e, "cilck")}><span className="material-symbols-outlined">search</span></button>
                                                </div>
                                                {/* <div className="form-group">
                                                    <input type="text" placeholder="Search Titles" className="form-control"  value={search} onChange={(e)=>setSearch(e.target.value)} />
                                                    <span className="material-icons-outlined search_ico" onClick={handleSearch}>search</span>
                                                </div> */}
                                                <div className="form-group d-flex">
                                                    <select className="form-control select form-select" onChange={(e) => sort(e)} placeholder="sortBy" value={sortBy}>
                                                        <option value="" disabled selected>Sort By</option>
                                                        <option value="alphabeticalAtoZ">Alphabetical(A-Z)</option>
                                                        <option value="alphabeticalZtoA">Alphabetical(Z-A)</option>
                                                        <option value="newest">Newest First</option>
                                                        <option value="oldest">Oldest First</option>
                                                    </select>
                                                    {/* <button class="auction-reset-btn clear-btn" onClick={handleReset}>
                                                        <span class="material-symbols-outlined">sync</span>
                                                    </button> */}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row portfolio">
                                            {titleData && titleData.length > 0 && titleData.map((item, i) => {

                                                let defaultImg = `https://orasi-dev.imgix.net/orasi/common/images/img-default.jpg`;

                                                // let imgUrl = item.thumbnail != "" ? img + item.thumbnail : defaultImg

                                                let imgUrl = (item.itemimage != undefined) ? img + item.itemimage : defaultImg
                                                return (
                                                    <div className="col6-sm1 col-md-6 portfolio-item">
                                                        <div className="portfolio-wrap" onClick={(e) => handleMoreInfo(e, item.itemid)}>
                                                            {item.status === "COMING SOON" && <span className="comingsoon"><span className="material-icons-outlined icon"> timer </span>Coming Soon</span>}
                                                            <img src={imgUrl + "?auto=compress,format&width=169.863&q=95"} className="asset-portrait-thumb" alt="" />
                                                            <div className="portfolio-info black-gradient">
                                                                <div className="thumb-info-block black-gradient">
                                                                    <div className="thumb-info">
                                                                        <h6 className="thumb-title" >{item.title}</h6>
                                                                        <h6 className="sub-title">Start Date : {moment(item && item.bidstartdate).format("DD-MMM-YYYY")}</h6>

                                                                        <div className="dsp_flx">
                                                                            <button className="table-actions-btn border-btn_sm "
                                                                            //  onClick={(e) => handleMoreInfo(e, item.itemid)}
                                                                            >DETAILS</button>
                                                                            {/* {item.status === "ACTIVE" && <button className="table-actions-btn border-btn_sm " onClick={(e) => handleBids(e, item.itemid)}>BID NOW</button>} */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                        {assetTotal > biddingperPageConst ?
                                            <div className="row pagenation pgn1">
                                                <div className="pagenation_prv"><button disabled={titlePageNumber == 1} onClick={e => previousClick(e)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                                <div className="pagenation_page"> <label>Page :</label>
                                                    {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                                    <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={titlePageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                        {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                            return (
                                                                <option key={i} value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <label><strong> of {totalPages}</strong></label>
                                                </div>
                                                <div className="pagenation_count">
                                                    <label>{(titlePageNumber * titlePerPage) >= assetTotal ? assetTotal : (titlePageNumber * titlePerPage)} of {assetTotal}</label>
                                                </div>
                                                <div className="pagenation_perpage">
                                                    <label>Per Page:</label>
                                                    {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                                    <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={titlePerPage} onChange={e => handleChangePages(e)} >

                                                        <option value={12}>12</option>
                                                        <option value={24}>24</option>
                                                        <option value={36}>36</option>
                                                        <option value={48}>48</option>
                                                        <option value={60}>60</option>
                                                        <option value={72}>72</option>
                                                    </select>
                                                </div>
                                                <div className="pagenation_next"><button disabled={titlePageNumber == totalPages} onClick={e => nextClick(e)} className="jr-btn btn-white btn btn-default btn-block">Next</button></div>
                                            </div> : null
                                            // <p>Total Assets : {assetTotal}</p>
                                        }
                                        {/* <div className="row pagenation pgn1">
                                            <div className="pagenation_prv"><button disabled={pageNumber == 1} onClick={e => previousClick(e)} className="jr-btn btn-white btn btn-default btn-block">Previous</button></div>
                                            <div className="pagenation_page"> <label>Page :</label>
                                                {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                        {/* <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                    {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                        return (
                                                            <option key={i} value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                                <label><strong> of {totalPages}</strong></label>
                                            </div>
                                            <div className="pagenation_count">
                                                <label>{(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                            </div>
                                            <div className="pagenation_perpage">
                                                <label>Per Page:</label>

                                                <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={perpage} onChange={e => handleChangePages(e)} > <span className="material-icons-outlined">arrow_drop_down</span>
                                                    {/* <option value={6}>6</option>
                                                             <option value={12}>12</option>
                                                             <option value={18}>18</option> */}

                                        {/*  <option value={18}>18</option>
                                                    <option value={36}>36</option>
                                                    <option value={54}>54</option>
                                                    <option value={72}>72</option>
                                                    <option value={90}>90</option>
                                                    <option value={108}>108</option>
                                                </select>
                                            </div>
                                            <div className="pagenation_next"><button className="jr-btn btn-white btn btn-default btn-block">Next</button></div>
                                        </div> */}
                                    </div>
                                </>) : isLoadCart ? (<>
                                    <div className="orasi-preloader">
                                        <img src="https://orasi-dev.imgix.net/orasi/common/images/preloader.png" />
                                    </div>
                                </>) : dataFlag && (<>
                                    <div className="comingsoon-wrapper">
                                        <div className="comingsoon-block">
                                            <h6>No Titles Available!</h6>

                                            {(titleData && titleData?.length <= 0) ? <button className="fill_btn yellow-gradient mt-2" onClick={handleExplore1}>
                                                Back to Auction
                                            </button> : titleData && titleData?.length <= 0 ? <button className="fill_btn yellow-gradient mt-2" onClick={handleExplore}>
                                                Back to Market Place
                                            </button> : null}
                                        </div>
                                    </div>
                                </>)}
                            </div>

                            <div className={`tab-pane fade ${activeTab == 'bids' ? 'active show' : ''}`}
                                //  className="tab-pane fade"
                                id="profile" role="tabpanel" aria-labelledby="profile-tab">

                                <div className="table-responsive my_bid">



                                    <DataTable
                                        // title=""

                                        columns={columns}
                                        // className="table align-middle table-nowrap table-check"
                                        keyField='_id'
                                        data={mybids}
                                        direction="auto"
                                        highlightOnHover
                                        fixedHeaderScrollHeight="300px"
                                        pagination
                                        responsive
                                        persistTableHead
                                        // selectableRowsVisibleOnly
                                        striped
                                        // selectableRowsHighlight
                                        // selectableRows
                                        subHeaderAlign="right"
                                        defaultSortField="name"
                                        subHeaderWra
                                        noDataComponent={customNoRecords()}
                                        paginationTotalRows={mybids && mybids.length}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={rowsPerPage}
                                        paginationDefaultPage={currentPageNew}
                                        paginationRowsPerPageOptions={[12, 24, 36, 48, 60]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Per page:',
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: false,
                                            selectAllRowsItem: false,
                                            selectAllRowsItemText: 'All',
                                        }}

                                        progressPending={isLoading}
                                        progressComponent={<TableLoader />}

                                    />




                                </div>

                            </div>
                        </div>
                    </div>

                </div>



            </div>
            <Footer menus={menus} />

            <Modal className="modal title-des-popup" id="descriptionPopup" >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>xada</p>
                </Modal.Body>
            </Modal>

            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

        </>
    );
};

export default AuctionTitle;